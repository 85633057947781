import instance from 'api/instance';
import { GetAddress, GetFileTypes, GetLanguages, GetPlaceAutocomplete, GetWebFile, GetWebSpecialities } from './types';

export const getFileTypes: GetFileTypes = () => {
  return instance.get('/exec?action=get_fileTypes');
};

export const getWebSpecialities: GetWebSpecialities = () => {
  return instance.get('/exec?action=get_webSpecialities');
};

export const getWebFile: GetWebFile = (unique) => {
  return instance.post(
    'exec?action=get_webFile',
    { unique },
    {
      responseType: 'blob',
    }
  );
};

export const getLanguages: GetLanguages = () => {
  return instance.get('/exec?action=get_languages');
};

export const getPlaceAutocomplete: GetPlaceAutocomplete = (body) => {
  return instance.post('/exec?action=place_autocomplete', body);
};

export const getAddress: GetAddress = ({ latitude, longitude }) => {
  return instance.post('exec?action=get_addressDescription', { latitude, longitude });
};
