import { Pagination, PaginationItem } from '@mui/material';
import React from 'react';
import { UIPaginationProps } from './types';
import './UIPagination.sass';

const UIPagination: React.FC<UIPaginationProps> = ({ ...rest }) => {
  return (
    <Pagination
      renderItem={(params) => (
        <PaginationItem
          {...params}
          classes={{
            selected: 'ui-pagination__item--selected',
            ellipsis: 'ui-pagination__item--ellipsis',
            root: 'ui-pagination__item',
            previousNext: 'ui-pagination__item--arrows',
          }}
        />
      )}
      {...rest}
    />
  );
};

export default UIPagination;
