import { Stack } from '@mui/material';
import { utilsService } from 'api';
import { FILE_SIZE, FILE_TYPE_ALL } from 'app/constants/FILE_CONFIG';
import { getFileShortName } from 'app/helpers/getFileShortName';
import { CompanyFileModel } from 'app/models/CompanyModel';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DocumnentIcon } from 'assets/icons/document.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import './AddFileModal.sass';
import { AddFileModalProps, AddFileModalRef } from './types';

const AddFileModal: React.ForwardRefRenderFunction<AddFileModalRef, AddFileModalProps> = (
  { children, onSubmit, loading, maxSize },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<CompanyFileModel & { isFileUpdated?: boolean }>();

  useImperativeHandle(
    ref,
    () => ({
      show: (file) => {
        setData(file as CompanyFileModel);
        setOpen(true);
      },
      hide: () => {
        setOpen(false);
        setFiles([]);
      },
      handleSubmit: () => {
        return {
          files,
          data,
        };
      },
      clearFiles: () => {
        setFiles([]);
      },
      validate: () => {
        setError(!files.length ? 'Files empty' : '');
      },
    }),
    [files, data]
  );

  useEffect(() => {
    if (data && !data.isFileUpdated) {
      getFile(data);
    }
  }, [data]);

  const getFile = async (data: CompanyFileModel) => {
    const file = await utilsService.getWebFile(data.unique);

    //@ts-ignore
    setFiles([new File([file], data.attachmentName ?? `file.${data.extension}`, { type: file.data.type })]);
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0].size > FILE_SIZE) {
        return setError('Max accessible size is 5Mb');
      }

      setFiles([...files, e.target.files[0]]);
      setData((prev) => {
        if (prev) {
          return {
            ...prev,
            isFileUpdated: true,
          };
        }
      });

      setError(null);
    }
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'add-file-modal',
        paper: 'add-file-modal__paper',
      }}
    >
      <SubmitModalLayout
        title={data ? 'Update File' : 'Add File'}
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
        loading={loading}
      >
        <div className='add-file-modal__inputs'>
          {children}
          <div className='upload-file'>
            <Stack direction='row' justifyContent='space-between'>
              <div className='upload-file__title'>Attachments</div>
              {files.length < 1 && (
                <label className='upload-file__label'>
                  <div className='upload-file__button'>
                    <FolderIcon />
                    Attach file
                  </div>
                  <input type='file' accept={FILE_TYPE_ALL} onChange={onChangeFile} />
                </label>
              )}
            </Stack>
            {!!files.length && (
              <div className='upload-file__files'>
                {files.map((file, index) => (
                  <div className='file' key={index}>
                    <DocumnentIcon className='file__icon' />
                    <span className='file__name'>{getFileShortName(file.name)}</span>
                    <CloseIcon
                      className='file__delete'
                      onClick={() => {
                        setFiles(files.filter((f) => f.name !== file.name));
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
            {error && <p className='upload-file__error'>{error}</p>}
          </div>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(AddFileModal);
