import { projectsService } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddSupervisorModal from 'shared/Modals/AddSupervisorModal/AddSupervisorModal';
import { AddSupervisorModalRef } from 'shared/Modals/AddSupervisorModal/types';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import { ModalBaseRef } from 'shared/Modals/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import { getProject } from 'store/slices/projects';

import { ReactComponent as PlaceholderPeopleIcon } from 'assets/icons/table/profile-2user.svg';

const SupervisorsDetails: React.FC = () => {
  const { id } = useParams() as { id: string };
  const dispatch = useAppDispatch();
  const { currentProject } = useAppSelector((state) => state.projects);

  const addSupervisorModalRef = useRef<AddSupervisorModalRef>(null);
  const confirmModalRef = useRef<ModalBaseRef>(null);

  const [loading, setLoading] = useState(false);

  const deleteSupervisor = (unique: number) => {
    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);

        await projectsService.deleteSupervisor(unique);
        dispatch(getProject(+id));
      } finally {
        setLoading(false);
        confirmModalRef.current?.hide();
      }
    });
  };

  return (
    <>
      <UITable
        withTabs
        title={{
          title: 'List of Supervisors',
          button: {
            text: 'Add Supervisor',
            icon: 'plus',
            onClick: () =>
              currentProject &&
              addSupervisorModalRef.current?.show(
                { uniqueC: currentProject.uniqueC, name: currentProject.company },
                currentProject.supervisors.map((s) => +s.uniqueS)
              ),
          },
        }}
        data={currentProject?.supervisors}
        headers={[{ label: 'Name' }, { label: 'Position' }, { label: 'Actions', noWrap: true }]}
        columns={[
          { columnName: 'nameEmployee' },
          { columnName: 'position' },
          {
            renderCol: ({ unique }) => {
              return <UIActionButton type='delete' onClick={() => deleteSupervisor(unique)} />;
            },
          },
        ]}
        placeholder={{
          icon: <PlaceholderPeopleIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of supervisors is empty.You can add supervisors now.',
        }}
      />
      <AddSupervisorModal ref={addSupervisorModalRef} />
      <ConfirmModal ref={confirmModalRef} title='Delete' submitBtnText='Confirm' loading={loading}>
        Do you really want to delete this supervisor? You will not be able to undo this.
      </ConfirmModal>
    </>
  );
};

export default SupervisorsDetails;
