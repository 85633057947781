export const getShortCompanyName = (companyName: string) => {
  if (!companyName) return '';

  const split = companyName.split(' ');

  if (split.length === 1) {
    return `${split?.[0]?.[0]}`.toUpperCase();
  }

  return `${split?.[0]?.[0]}${split?.[1]?.[0]}`.toUpperCase();
};
