import { FormControl, FormLabel } from '@mui/material';
import { authService } from 'api';
import { validator } from 'app/helpers/validator';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LanguageModel } from 'app/models/AuthModel';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import { setUser } from 'store/slices/auth';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import './EditProfileModal.sass';
import { EditProfileModalProps, EditProfileModalRef } from './types';

interface SubmitData {
  name: string;
  email: string;
  locale: LanguageModel;
}

const EditProfileModal: React.ForwardRefRenderFunction<EditProfileModalRef, EditProfileModalProps> = ({}, ref) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { languages } = useAppSelector((state) => state.utils);
  const { control, handleSubmit, setValue, reset } = useForm<SubmitData>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        setOpen(true);
      },
      hide: () => {
        reset();
        setOpen(false);
      },
    }),
    []
  );

  useEffect(() => {
    if (open && user) {
      setValue('name', user.name);
      setValue('email', user.email);
      setValue('locale', languages.find((l) => l.locale === (user.locale ?? 'en'))!);
    }
  }, [open, languages]);

  const submit: SubmitHandler<SubmitData> = async (data) => {
    try {
      setLoading(false);
      const userData = {
        name: data.name,
        email: data.email,
        locale: data.locale.locale,
      };

      await authService.editProfile(userData);
      dispatch(setUser(userData));
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'edit-profile-modal',
        paper: 'edit-profile-modal__paper',
      }}
      PaperProps={{
        sx: {
          width: '585px',
        },
      }}
    >
      <SubmitModalLayout
        onSubmit={handleSubmit(submit)}
        title='Save changes'
        onClose={() => setOpen(false)}
        submitBtnText='Save Changes'
        loading={loading}
      >
        <div className='edit-profile-modal__inputs'>
          <FormControl className='edit-profile-modal__row'>
            <FormLabel required>Full Name</FormLabel>
            <FormTextInput name='name' control={control} rules={{ required: 'Name required' }} />
          </FormControl>
          <FormControl className='edit-profile-modal__row'>
            <FormLabel required>Email</FormLabel>
            <FormTextInput
              name='email'
              control={control}
              rules={{
                required: 'Email required',
                pattern: {
                  value: validator.email,
                  message: 'Invalid Email',
                },
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Translator Tool</FormLabel>
            <FormSelectInput
              name='locale'
              control={control}
              options={languages}
              getOptionLabel={(option) => option.name}
              disableClearable
              rules={{
                required: 'Required field',
              }}
            />
            <p className='edit-profile-modal__locale-warning'>
              Note: selected language will be used for the chat translator tool
            </p>
          </FormControl>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(EditProfileModal);
