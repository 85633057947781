import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { utilsService } from 'api';
import { UtilsState } from './types';

const initialState: UtilsState = {
  fileTypes: [],
  webSpicialities: [],
  languages: [],
};

export const getFileTypesAction = createAsyncThunk('getFileTypes', async () => {
  const { data } = await utilsService.getFileTypes().then((res) => res.data);
  return data;
});

export const getWebSpecialitiesAction = createAsyncThunk('getWebSpecielities', async () => {
  const { data } = await utilsService.getWebSpecialities().then((res) => res.data);
  return data;
});

export const getLanguages = createAsyncThunk('getLanguages', async () => {
  const { languages } = await utilsService.getLanguages().then((res) => res.data);
  return languages;
});

const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFileTypesAction.fulfilled, (state, action) => {
        state.fileTypes = action.payload;
      })
      .addCase(getWebSpecialitiesAction.fulfilled, (state, action) => {
        state.webSpicialities = action.payload;
      })
      .addCase(getLanguages.fulfilled, (state, action) => {
        state.languages = action.payload;
      });
  },
});

export const {} = utilsSlice.actions;

export const utilsReducer = utilsSlice.reducer;
