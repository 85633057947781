import { Controller } from 'react-hook-form';
import UISelect from 'shared/ui/UISelect/UISelect';
import BaseFormInputProps from '../types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import UICheckbox from 'shared/ui/UICheckbox/UICheckbox';
import React, { useEffect, useState } from 'react';
import { getFCompanies } from 'store/slices/filters';
import _ from 'lodash';
import { companiesService } from 'api';
import { UISelectProps } from 'shared/ui/UISelect/types';
import { CompanyModel } from 'app/models/CompanyModel';

let searchTimeout: NodeJS.Timeout | null = null;

function FormCompaniesSelect<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>({
  control,
  name,
  rules,
  multiple,
  onChange: onChangeSelect,
  ...rest
}: BaseFormInputProps<T> & Omit<UISelectProps<CompanyModel, Multiple, DisableClearable>, 'options'>) {
  const dispatch = useAppDispatch();
  const { companies } = useAppSelector((state) => state.filters);

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(companies.data);

  useEffect(() => {
    setUniqueOptions(companies.data);
  }, [companies.data]);

  const setUniqueOptions = (data: typeof companies.data) => {
    setOptions(
      _.uniqBy(
        data.filter((e) => !!e.name),
        'unique'
      )
    );
  };

  const getMoreCompanies = (reset?: boolean) => {
    dispatch(getFCompanies({ reset }));
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const searchValue = e.target.value;
    setInputValue(searchValue);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(async () => {
      const response = await companiesService.getCompanies({ search: searchValue, page: 1, pageSize: 10 });
      setUniqueOptions([...response.data.data, ...companies.data]);
    }, 600);
  };

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, formState }) => {
        const { errors } = formState;
        return (
          <UISelect
            {...rest}
            options={options}
            onChange={(_, data, reason, details) => {
              onChange(data);
              onChangeSelect?.(_, data, reason, details);

              if (multiple) {
                setInputValue('');
              } else {
                setInputValue(details?.option.name ?? '');
              }
            }}
            onClose={() => {
              setUniqueOptions(companies.data);
            }}
            multiple={multiple}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.unique === value.unique}
            disableCloseOnSelect={multiple}
            loading={companies.loading}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} style={{ paddingLeft: 7 }} key={option.unique} data-value={option.unique}>
                  {multiple && <UICheckbox style={{ marginRight: 8 }} checked={selected} />}
                  {option.name}
                </li>
              );
            }}
            InputProps={{
              onChange: handleSearch,
            }}
            inputValue={inputValue}
            onScrollAtBottom={() => getMoreCompanies()}
            //@ts-ignore
            value={multiple ? value ?? [] : value ?? null}
            onBlur={onBlur}
            invalid={!!errors?.[name]?.message}
            helperText={<>{errors[name]?.message ?? ''}</>}
          />
        );
      }}
    />
  );
}

export default FormCompaniesSelect;
