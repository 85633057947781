import { ScheduleAttendanceAccidentReport } from 'app/models/SchedulesModel';
import React, { useImperativeHandle, useState } from 'react';
import SingleDetail from 'shared/ui/SingleDetail/SingleDetail';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { ReportOnAccidentModalProps, ReportOnAccidentModalRef } from './types';

import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as DirectboxIcon } from 'assets/icons/primary/directbox.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/primary/document-filled.svg';
import { ReactComponent as HealthIcon } from 'assets/icons/primary/health.svg';
import { ReactComponent as ProfileFilledIcon } from 'assets/icons/primary/profile-filled.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';

import './ReportOnAccidentModal.sass';

const ReportOnAccidentModal: React.ForwardRefRenderFunction<ReportOnAccidentModalRef, ReportOnAccidentModalProps> = (
  {},
  ref
) => {
  const [accident, setAccident] = useState<ScheduleAttendanceAccidentReport | null>(null);
  const [open, setOpen] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      show: (data) => {
        setAccident(data);
        setOpen(true);
      },
      hide: () => {
        setOpen(false);
        setAccident(null);
      },
    }),
    []
  );

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'accident-modal',
        paper: 'accident-modal__paper',
      }}
      PaperProps={{
        sx: { maxWidth: '760px', width: '100%' },
      }}
    >
      <SubmitModalLayout
        title='Report on Accident'
        onClose={() => setOpen(false)}
        onSubmit={() => setOpen(false)}
        submitBtnText='Save'
      >
        <div>
          <div className='accident-modal__cards'>
            <div className='accident-modal__card'>
              <div className='accident-modal__card-title'>
                <DocumentIcon className='accident-modal__card-icon' />
                Accident description
              </div>
              <p className='accident-modal__card-description'>{accident?.accidentDescription}</p>
            </div>
            <div className='accident-modal__card'>
              <div className='accident-modal__card-title'>
                <ProfileFilledIcon className='accident-modal__card-icon' />
                What was employee doing when he/she was injured?
              </div>
              <p className='accident-modal__card-description'>{accident?.whatWasEmployeeDoing}</p>
            </div>
            <div className='accident-modal__card'>
              <div className='accident-modal__card-title'>
                <HealthIcon className='accident-modal__card-icon' />
                Body part(s) injured
              </div>
              <p className='accident-modal__card-description'>{accident?.injuredBodyPart}</p>
            </div>
          </div>
          {accident?.witnesses.map((witness, index) => (
            <div className='accident-modal__witness'>
              <h4 className='accident-modal__witness-title'>Witness #{index + 1}</h4>
              <div className='accident-modal__witness-wrapper'>
                <SingleDetail icon={ProfileIcon} title='Name' label={`${witness.firstName} ${witness.lastName}`} />
                <SingleDetail icon={PhoneIcon} title='Phone Number' label={witness.phone} />
                <SingleDetail icon={LocationIcon} title='Address' label={witness.address} />
                <SingleDetail icon={DirectboxIcon} title='SSN' label={witness.ssn} />
              </div>
            </div>
          ))}
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(ReportOnAccidentModal);
