import React, { useEffect, useImperativeHandle, useState } from 'react';
import SubmitModalLayout from 'shared/Modals/SubmitModalLayout/SubmitModalLayout';
import UIModal from 'shared/ui/UIModal/UIModal';
import './ProjectsFilterModal.sass';
import { ProjectsFilterModalProps, ProjectsFilterModalRef } from './types';

import { FormControl, FormLabel, Stack } from '@mui/material';
import { GetProjectsBody } from 'api/projects/types';
import { formatDate } from 'app/helpers/formatDate';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useFilters from 'app/hooks/useFilters';
import { CompanyModel } from 'app/models/CompanyModel';
import dayjs from 'dayjs';
import _ from 'lodash';
import { colorizeStatusProject } from 'pages/ProjectsPage/helpers';
import ProjectsFilterParams from 'pages/ProjectsPage/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormDateInput from 'shared/Inputs/FormDateInput/FormDateInput';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import Status from 'shared/ui/Status/Status';
import { IStatus } from 'shared/ui/Status/types';
import UICheckbox from 'shared/ui/UICheckbox/UICheckbox';
import { getProjects } from 'store/slices/projects';
import { getFCompanies } from 'store/slices/filters';
import FormCompaniesSelect from 'shared/Inputs/Filters/FormCompaniesSelect';

const initialFilterParams: ProjectsFilterParams = {
  page: 1,
  companies: [],
  statuses: [],
  from: '',
  to: '',
  search: '',
};

interface SubmitData {
  page?: number;
  statuses?: { label: string; value: string; color?: IStatus }[];
  companies?: CompanyModel[];
  startDate?: string;
  endDate?: string;
  search?: string;
}

const ProjectsFilterModal: React.ForwardRefRenderFunction<ProjectsFilterModalRef, ProjectsFilterModalProps> = (
  { limit },
  ref
) => {
  const dayjsFormat = 'DD/MM/YYYY';
  const { control, handleSubmit, setValue, reset, getValues } = useForm<SubmitData>();
  const { statuses: statusesData } = useAppSelector((state) => state.projects);
  const { companies } = useAppSelector((state) => state.filters);

  const [open, setOpen] = useState(false);
  const [statuses, setStatuses] = useState<{ label: string; value: string; color?: IStatus }[]>([]);

  const dispatch = useAppDispatch();

  const { replaceUrl, onChangeFilter, getNonEmptyFilters, resetFilters, resetAndOnChangeFilter } = useFilters({
    initialFilterParams,
    limit,
    initialCallback: (params) => dispatch(getProjects({ force: true, params: { ...params, pageSize: limit } })),
    replaceState: {
      deps: [companies.data.length, statuses.length],
      page: (value) => {
        setValue('page', value ? +value : 1);
      },
      companies: (value) => {
        let valueToSet: CompanyModel[] = [];

        if (typeof value === 'object') {
          valueToSet = value
            ?.map((el) => companies.data?.find((com) => !!el && +el === com.unique))
            .filter((el) => !!el) as CompanyModel[];
        } else {
          const company = companies.data.find((el) => el.unique === +value);
          if (company) valueToSet.push(company);
        }

        setValue('companies', valueToSet);
      },
      statuses: (value) => {
        const valueToSet =
          typeof value === 'object'
            ? value?.map((el) => statuses?.find((status) => el === status.value)).filter((el) => !!el) ?? []
            : [statuses.find((el) => el.value === value)];

        setValue('statuses', valueToSet as typeof statuses);
      },
      from: (value) => {
        setValue(
          'startDate',
          dayjs(value as string, dayjsFormat).isValid() ? `${dayjs(formatDate({ date: value as string }))}` : ''
        );
      },
      to: (value) => {
        setValue(
          'endDate',
          dayjs(value as string, dayjsFormat).isValid() ? `${dayjs(formatDate({ date: value as string }))}` : ''
        );
      },
      search: (value) => {
        setValue('search', value as string);
      },
    },
  });

  useEffect(() => {
    setStatuses(
      statusesData?.map((s) => ({
        label: s.name.toLowerCase(),
        value: s.id,
        color: colorizeStatusProject(s.name).color,
      })) ?? []
    );
  }, [statusesData]);

  useImperativeHandle(ref, () => ({
    show: () => {
      dispatch(getFCompanies({ reset: true }));
      setOpen(true);
    },
    hide: () => setOpen(false),
    onChange: (params: GetProjectsBody) => {
      const filters = onChangeFilter(params);
      replaceUrl(filters);

      dispatch(getProjects({ force: true, params: { ...getNonEmptyFilters(filters), pageSize: limit } }));
    },
    refreshPage: () => {
      dispatch(getProjects({ force: true, params: { ...getNonEmptyFilters(), pageSize: limit } }));
    },
  }));

  const applyFilters: SubmitHandler<SubmitData> = (data) => {
    const submitData = {
      statuses: data.statuses?.map((el) => el.value),
      companies: data.companies?.map((c) => +c.unique),
      from: data.startDate ? dayjs(data.startDate).format(dayjsFormat) : '',
      to: data.endDate ? dayjs(data.endDate).format(dayjsFormat) : '',
    };

    const filters = resetAndOnChangeFilter({
      page: 1,
      ..._.omitBy(submitData, _.isEmpty),
    });
    replaceUrl(filters);

    dispatch(getProjects({ force: true, params: { ...getNonEmptyFilters(filters), pageSize: limit } }));
    setOpen(false);
  };

  const resetAppliedFilter = () => {
    const filters = resetFilters();
    replaceUrl(filters);
    reset({ page: 1, companies: [], statuses: [], startDate: '', endDate: '' });

    dispatch(getProjects({ force: true, params: { ...getNonEmptyFilters(filters), pageSize: limit } }));
    setOpen(false);
  };

  const handleChangeStartDate = (date: Date | null) => {
    if (date) {
      const endDate = getValues('endDate');

      if (dayjs(date).isAfter(endDate)) {
        setValue('endDate', dayjs(date).format('DD MMM, YYYY'));
      }
    }
  };

  const handleChangeEndDate = (date: Date | null) => {
    if (date) {
      const startDate = getValues('startDate');

      if (dayjs(date).isBefore(startDate)) {
        setValue('startDate', dayjs(date).format('DD MMM, YYYY'));
      }
    }
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'filter-modal',
        paper: 'filter-modal__paper',
      }}
    >
      <SubmitModalLayout
        title='Filter'
        onClose={() => setOpen(false)}
        submitBtnText='Apply'
        clearFilter={resetAppliedFilter}
        onSubmit={handleSubmit(applyFilters)}
      >
        <div className='filter-modal__inputs'>
          {statuses && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Status</FormLabel>
              <FormSelectInput
                control={control}
                name='statuses'
                allCheckbox
                isOptionEqualToValue={(option, value) => option.value === value.value}
                multiple
                options={statuses}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} style={{ paddingLeft: 7 }}>
                      <UICheckbox style={{ marginRight: 8 }} checked={selected} />
                      {option.color ? <Status text={option.label} color={option.color} /> : option.label}
                    </li>
                  );
                }}
              />
            </FormControl>
          )}

          <FormControl className='filter-modal__row'>
            <FormLabel>Company</FormLabel>
            <FormCompaniesSelect name='companies' control={control} multiple />
          </FormControl>

          <Stack direction='row' spacing={2}>
            <FormControl className='filter-modal__row'>
              <FormLabel>Start Date</FormLabel>
              <FormDateInput name='startDate' control={control} onChange={handleChangeStartDate} />
            </FormControl>
            <FormControl className='filter-modal__row'>
              <FormLabel>End Date</FormLabel>
              <FormDateInput name='endDate' control={control} onChange={handleChangeEndDate} />
            </FormControl>
          </Stack>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(ProjectsFilterModal);
