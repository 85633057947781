import { Controller } from 'react-hook-form';
import { UISelectProps } from 'shared/ui/UISelect/types';
import UISelect from 'shared/ui/UISelect/UISelect';
import BaseFormInputProps from '../types';

function FormSelectInput<
  T,
  V,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  control,
  name,
  rules,
  multiple,
  onChange: onChangeSelect,
  ...rest
}: BaseFormInputProps<T> & UISelectProps<V, Multiple, DisableClearable, FreeSolo>) {
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, formState }) => {
        const { errors } = formState;
        return (
          <UISelect
            {...rest}
            onChange={(_, data, reason, details) => {
              onChange(data);
              onChangeSelect?.(_, data, reason, details);
              // return data;
            }}
            //@ts-ignore
            value={multiple ? value ?? [] : value ?? null}
            onBlur={onBlur}
            multiple={multiple}
            invalid={!!errors?.[name]?.message}
            helperText={<>{errors[name]?.message ?? ''}</>}
          />
        );
      }}
    />
  );
}

export default FormSelectInput;
