import { Dialog, PaperProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UIModalProps } from './types';

const UIModal: React.FC<UIModalProps> = (props) => {
  const { open, PaperProps, ...rest } = props;
  const { sx, ...paperProps } = PaperProps ?? ({} as PaperProps);
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    setVisibility(open);
  }, [open]);

  return (
    <Dialog
      onClose={() => setVisibility(false)}
      open={visibility}
      PaperProps={{
        sx: {
          borderRadius: '24px',
          width: '590px',
          ...sx,
        },
        ...paperProps,
      }}
      {...rest}
    />
  );
};

export default UIModal;
