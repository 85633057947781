import { formatDate } from 'app/helpers/formatDate';
import { encodeBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ReactComponent as CalendarIcon } from 'assets/icons/primary/calendar.svg';
import { ReactComponent as StickyNoteIcon } from 'assets/icons/primary/stickynote.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';
import BackButton from 'shared/ui/BackButton/BackButton';
import SingleDetail from 'shared/ui/SingleDetail/SingleDetail';
import UITabs from 'shared/ui/UITabs/UITabs';
import { getLicense } from 'store/slices/staff';
import Renewals from './components/Renewals/Renewals';
import { getLicenseColor } from './helpers';
import './LicenseDetailsPage.sass';
import { Stack } from '@mui/material';

const LicenseDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { currentLicense } = useAppSelector((state) => state.staff);

  const previewImageModalRef = useRef<PreviewImageModalRef>(null);

  useEffect(() => {
    if (!id) return;

    dispatch(getLicense(+id));
  }, [dispatch, id]);

  return (
    <div className='license-details-page'>
      <BackButton label='View License Details' />
      {currentLicense && (
        <div className='license-details-page__details'>
          <SingleDetail
            title=''
            className='license-details-page__details--single-detail license-details-page__details--single-detail--title'
            render={
              <div className='license-details-page__number'>
                <div>
                  <span className='license-details-page__number-title'>License #</span>
                  {currentLicense.number}
                </div>
                <Stack direction='row' spacing='8px'>
                  {currentLicense.images.map(({ image, extension, unique }) => {
                    const url = encodeBase64(image, extension);

                    return url ? (
                      <img
                        src={url}
                        alt=''
                        className='license-details-page__number-img'
                        onClick={(e) => {
                          e.stopPropagation();
                          previewImageModalRef.current?.show({
                            file: { unique, name: currentLicense.number, extension },
                          });
                        }}
                      />
                    ) : (
                      <div className='license-details-page__number-img-placeholder'></div>
                    );
                  })}
                </Stack>
              </div>
            }
          />
          <SingleDetail
            title='License'
            className='license-details-page__details--single-detail'
            icon={StickyNoteIcon}
            status={{ color: getLicenseColor(currentLicense), text: currentLicense.type.shortName }}
          />
          <SingleDetail
            title='Date'
            className='license-details-page__details--single-detail'
            icon={CalendarIcon}
            label={formatDate({ date: currentLicense.expirationDate })}
          />
          <SingleDetail
            title='Issued by'
            className='license-details-page__details--single-detail'
            icon={ProfileIcon}
            label={currentLicense.issuedBy}
          />
        </div>
      )}
      {currentLicense && <UITabs tabs={[{ tab: 'renewals', label: 'Renewals', component: <Renewals /> }]} />}
      <PreviewImageModal ref={previewImageModalRef} />
    </div>
  );
};

export default LicenseDetailsPage;
