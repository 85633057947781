import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { schedulesService } from 'api';
import { GetSchedulesBody } from 'api/schedules/types';
import getCachedData from 'app/helpers/getCachedData';
import { CachedDataPayload } from 'app/models/CompanyModel';
import { CrewDetailsModel } from 'app/models/StaffModel';
import { RootState } from 'store/store';
import { SchedulesData, SchedulesState } from './types';
import { ScheduleDetailedModel } from 'app/models/SchedulesModel';

const initialState: SchedulesState = {
  workerAttendanceStatusesValueKey: {},
};

export const getSchedulesActions = createAsyncThunk(
  'schedules/getSchedules',
  async (payload: { params?: GetSchedulesBody } | undefined, ThunkApi) => {
    const state = ThunkApi.getState() as RootState;
    const { schedules } = state.schedules;
    return await getCachedData(
      schedules,
      () => schedulesService.getSchedules(payload?.params).then((res) => res.data.data),
      true
    );
  }
);

export const getSchedule = createAsyncThunk(
  'schedules/getSchedule',
  async (payload: { id: number; callback?: Function }) => {
    return await schedulesService
      .getSchedule(payload.id)
      .then((response) => {
        const schedule = response.data.data;
        schedule.totalHrs = schedule.attendance.map((item) => item.hoursWorked).reduce((a, b) => a + b);
        return schedule;
      })
      .finally(() => {
        if (payload.callback) {
          payload.callback();
        }
      });
  }
);

export const getSupervisors = createAsyncThunk('schedules/getSupervisors', async (payload, ThunkApi) => {
  const { supervisors } = (ThunkApi.getState() as RootState).schedules;
  if (supervisors?.length) return supervisors;

  return await schedulesService.getSupervisors().then((response) => response.data.data);
});

export const getScheduleStages = createAsyncThunk(
  'schedules/getScheduleStages',
  async (payload: { force?: boolean } | undefined, ThunkApi) => {
    const { stages } = (ThunkApi.getState() as RootState).schedules;
    if (stages?.length && !payload?.force) return stages;

    return await schedulesService.getScheduleStages().then((response) => response.data.data);
  }
);

export const getWorkerAttendanceStatuses = createAsyncThunk(
  'schedules/getWorkerAttendanceStatuses',
  async (payload: { force?: boolean } | undefined, ThunkApi) => {
    const { workerAttendanceStatuses } = (ThunkApi.getState() as RootState).schedules;
    if (workerAttendanceStatuses?.length && !payload?.force) return workerAttendanceStatuses;

    return await schedulesService.getWorkerAttendanceStatuses().then((response) => response.data.data);
  }
);

const schedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    setSchedulesData: (state, payload: PayloadAction<CachedDataPayload<SchedulesData>>) => {
      state.schedules = { ...state.schedules, ...payload };
    },
    removeSingleSchedule: (state, payload: PayloadAction<number>) => {
      state.schedules?.data?.splice(
        state.schedules.data.findIndex((el) => el.unique === payload.payload),
        1
      );
    },
    setCreateScheduleCrew: (state, action: PayloadAction<CrewDetailsModel | undefined>) => {
      state.createScheduleSelectedCrew = action.payload;
    },
    setCurrentSchedule: (state, action: PayloadAction<ScheduleDetailedModel | undefined>) => {
      state.currentSchedule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSchedulesActions.fulfilled, (state, action) => {
        state.schedules = action.payload;
        state.schedulesLoading = false;
      })
      .addCase(getSchedulesActions.pending, (state) => {
        state.schedulesLoading = true;
      })
      .addCase(getSchedule.pending, (state) => {
        state.currentScheduleLoading = true;
        state.currentSchedule = undefined;
      })
      .addCase(getSchedule.fulfilled, (state, action) => {
        state.currentSchedule = action.payload;
        state.currentScheduleLoading = false;
      })
      .addCase(getSupervisors.pending, (state) => {
        state.supervisorsLoading = true;
      })
      .addCase(getSupervisors.fulfilled, (state, action) => {
        state.supervisors = action.payload;
        state.supervisorsLoading = false;
      })
      .addCase(getScheduleStages.fulfilled, (state, action) => {
        state.stages = action.payload;
      })
      .addCase(getWorkerAttendanceStatuses.fulfilled, (state, action) => {
        action.payload.forEach((item) => {
          state.workerAttendanceStatusesValueKey[item.name] = item.id;
        });

        state.workerAttendanceStatuses = action.payload;
      });
  },
});

export const { setSchedulesData, removeSingleSchedule, setCurrentSchedule, setCreateScheduleCrew } =
  schedulesSlice.actions;

export const schedulesReducer = schedulesSlice.reducer;
