import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notificationsService } from 'api';
import { GetNotificationsBody } from 'api/notifications/types';
import { RootState } from 'store/store';
import { NotificationsState } from './types';

const initialState: NotificationsState = {
  notifications: {
    data: [],
  },
  notificationCounter: 0,
};

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async (payload: { force?: boolean; concat?: boolean; params?: GetNotificationsBody } | undefined, ThunkApi) => {
    const { notifications } = (ThunkApi.getState() as RootState).notifications as NotificationsState;
    if (notifications.data.length && !payload?.force) return notifications;

    localStorage.setItem('notificationCount', '0');

    return await notificationsService.getNotifications(payload?.params).then((res) => {
      if (payload?.concat) {
        return { ...res.data, data: [...notifications.data, ...res.data.data] };
      } else {
        return res.data;
      }
    });
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    incrementNotificationCounter: (state) => {
      state.notificationCounter += 1;
      localStorage.setItem('notificationCount', String(state.notificationCounter));
    },
    setNotificationCounter: (state, action: PayloadAction<number>) => {
      state.notificationCounter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.notificationsLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.notificationCounter = 0;
        state.notificationsLoading = false;
      });
  },
});

export const { incrementNotificationCounter, setNotificationCounter } = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;
