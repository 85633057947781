import instance from 'api/instance';
import {
  AddCrew,
  AddCrewMembers,
  AddFile,
  AddFileData,
  AddLicense,
  AddLicenseFile,
  DeleteCrew,
  DeleteCrewMember,
  DeleteEmployee,
  DeleteEmployeeFile,
  DeleteLicenseFile,
  EditCrew,
  EditEmployee,
  EditFileData,
  EditLicense,
  GetCrew,
  GetCrews,
  GetEmployee,
  GetEmployeeFiles,
  GetEmployees,
  GetEmployeeStatuses,
  GetLicense,
  GetMaritalStatuses,
  GetWebLicenseStatuses,
  GetWebLicenseTypes,
  GetWebQualifications,
  GetWebStates,
  GetWorkerTypes,
  InviteEmployee,
  PostProfilePhone,
} from './types';

export const getEmployeeStatuses: GetEmployeeStatuses = () => {
  return instance.get('/exec?action=get_employeeStatuses');
};

export const getWebLicenseTypes: GetWebLicenseTypes = () => {
  return instance.get('/exec?action=get_webLicenseTypes');
};

export const getWorkerTypes: GetWorkerTypes = () => {
  return instance.get('/exec?action=get_workerTypes');
};

export const getWebQualifications: GetWebQualifications = () => {
  return instance.get('/exec?action=get_webQualifications');
};

export const getWebLicenseStatuses: GetWebLicenseStatuses = () => {
  return instance.get('/exec?action=get_webLicenseStatuses');
};

export const getEmployees: GetEmployees = (body) => {
  return instance.post('/exec?action=get_employees', body);
};

export const getEmployee: GetEmployee = (unique) => {
  return instance.post('/exec?action=get_employee', { unique });
};

export const editEmployee: EditEmployee = (body) => {
  return instance.post('/exec?action=edit_employee', body);
};

export const deleteEmployee: DeleteEmployee = (unique: number) => {
  return instance.post('/exec?action=delete_employee', {
    unique,
  });
};

export const inviteEmployee: InviteEmployee = (body) => {
  return instance.post('/exec?action=invite_employee', body);
};

export const getCrews: GetCrews = (body) => {
  return instance.post('/exec?action=get_crews', body);
};

export const addCrew: AddCrew = (body) => {
  return instance.post('/exec?action=add_crew', body);
};

export const editCrew: EditCrew = (body) => {
  return instance.post('/exec?action=edit_crew', body);
};

export const getCrew: GetCrew = (unique) => {
  return instance.post('/exec?action=get_crew', {
    unique,
  });
};

export const deleteCrew: DeleteCrew = (unique) => {
  return instance.post('/exec?action=delete_crew', {
    unique,
  });
};

export const deleteCrewMember: DeleteCrewMember = (unique) => {
  return instance.post('/exec?action=delete_crewMember', {
    unique,
  });
};

export const addCrewMembers: AddCrewMembers = (body) => {
  return instance.post('/exec?action=add_crewMembers', body);
};

export const getMaritalStatuses: GetMaritalStatuses = () => {
  return instance.get('/exec?action=get_maritalStatuses');
};

export const getWebStates: GetWebStates = () => {
  return instance.get('/exec?action=get_webStates');
};

export const postProfilePhoto: PostProfilePhone = (body) => {
  return instance.post('/exec?action=post_profilePhotoWeb', body, {
    responseType: 'blob',
  });
};

export const addLicense: AddLicense = (body) => {
  return instance.post('/exec?action=add_license', body);
};

export const editLicense: EditLicense = (body) => {
  return instance.post('/exec?action=edit_license', body);
};

export const addLicenseFile: AddLicenseFile = (body) => {
  return instance.post('/exec?action=add_licenseFile', body);
};

export const deleteLicenseFile: DeleteLicenseFile = (unique) => {
  return instance.post('/exec?action=delete_licenseFileWeb', {
    unique,
  });
};

export const deleteLicense: DeleteLicenseFile = (unique) => {
  return instance.post('/exec?action=delete_license', {
    unique,
  });
};

export const getEmployeeFiles: GetEmployeeFiles = (body) => {
  return instance.post('/exec?action=getEmployee_files', body);
};

export const deleteEmployeeFile: DeleteEmployeeFile = (unique) => {
  return instance.post('/exec?action=delete_employeeFile', {
    unique,
  });
};

export const addFileData: AddFileData = (body) => {
  return instance.post('/exec?action=add_employeeFileData', body);
};

export const addFile: AddFile = (body) => {
  return instance.post('/exec?action=post_employeeFile', body);
};

export const editFileData: EditFileData = (body) => {
  return instance.post('/exec?action=edit_employeeFileData', body);
};

export const getLicense: GetLicense = (unique) => {
  return instance.post('/exec?action=get_license', {
    unique,
  });
};
