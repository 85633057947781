import { Controller } from 'react-hook-form';
import UISelect from 'shared/ui/UISelect/UISelect';
import BaseFormInputProps from '../types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import UICheckbox from 'shared/ui/UICheckbox/UICheckbox';
import React, { useEffect, useState } from 'react';
import { getFEmployeesWithoutCrew } from 'store/slices/filters';
import _ from 'lodash';
import { companiesService, StaffService } from 'api';
import { UISelectProps } from 'shared/ui/UISelect/types';
import { EmployeesModel } from 'app/models/StaffModel';

let searchTimeout: NodeJS.Timeout | null = null;

function FormEmployeesWithoutCrewSelect<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>({
  control,
  name,
  rules,
  multiple,
  onChange: onChangeSelect,
  ...rest
}: BaseFormInputProps<T> & Omit<UISelectProps<EmployeesModel, Multiple, DisableClearable>, 'options'>) {
  const dispatch = useAppDispatch();
  const { employeesWithoutCrew } = useAppSelector((state) => state.filters);

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(employeesWithoutCrew.data);

  useEffect(() => {
    setUniqueOptions(employeesWithoutCrew.data);
  }, [employeesWithoutCrew.data]);

  const setUniqueOptions = (data: typeof employeesWithoutCrew.data) => {
    setOptions(_.uniqBy(data, 'unique'));
  };

  const getMoreProjects = (reset?: boolean) => {
    dispatch(getFEmployeesWithoutCrew({ reset }));
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const searchValue = e.target.value;
    setInputValue(searchValue);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(async () => {
      const response = await StaffService.getEmployees({ search: searchValue, page: 1, pageSize: 10 });
      setUniqueOptions([...response.data.data, ...employeesWithoutCrew.data]);
    }, 600);
  };

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, formState }) => {
        const { errors } = formState;
        return (
          <UISelect
            {...rest}
            options={options}
            onChange={(_, data, reason, details) => {
              onChange(data);
              onChangeSelect?.(_, data, reason, details);

              if (multiple) {
                setInputValue('');
              } else {
                setInputValue(details?.option.fullName ?? '');
              }
            }}
            onClose={() => {
              setUniqueOptions(employeesWithoutCrew.data);
            }}
            multiple={multiple}
            getOptionLabel={(option) => option.fullName}
            isOptionEqualToValue={(option, value) => option.unique === value.unique}
            disableCloseOnSelect={multiple}
            loading={employeesWithoutCrew.loading}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} style={{ paddingLeft: 7 }} key={option.unique} data-value={option.unique}>
                  {multiple && <UICheckbox style={{ marginRight: 8 }} checked={selected} />}
                  {option.fullName}
                </li>
              );
            }}
            InputProps={{
              onChange: handleSearch,
            }}
            inputValue={inputValue}
            onScrollAtBottom={() => getMoreProjects()}
            //@ts-ignore
            value={multiple ? value ?? [] : value ?? null}
            onBlur={onBlur}
            invalid={!!errors?.[name]?.message}
            helperText={<>{errors[name]?.message ?? ''}</>}
          />
        );
      }}
    />
  );
}

export default FormEmployeesWithoutCrewSelect;
