import { Stack } from '@mui/material';
import { StaffService } from 'api';
import { formatDate } from 'app/helpers/formatDate';
import { encodeBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import history from 'app/routes/history';
import { ReactComponent as PlaceholderLicensesIcon } from 'assets/icons/table/document.svg';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddLicenseModal from 'shared/Modals/AddLicenseModal/AddLicenseModal';
import { AddLicenseModalRef } from 'shared/Modals/AddLicenseModal/types';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';
import { ModalBaseRef } from 'shared/Modals/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import { getEmployee } from 'store/slices/staff';
import './Licences.sass';
import Status from '../../../../shared/ui/Status/Status';
import { getLicenseColor } from '../../../LicenseDetailsPage/helpers';

const Licences = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams() as { id: string };

  const addLicenseModalRef = useRef<AddLicenseModalRef>(null);
  const confirmModalRef = useRef<ModalBaseRef>(null);
  const previewImageModalRef = useRef<PreviewImageModalRef>(null);

  const { currentEmployee } = useAppSelector((state) => state.staff);
  const [loading, setLoading] = useState(false);

  const deleteLicense = (unique: number) => {
    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);
        await StaffService.deleteLicense(unique);

        dispatch(getEmployee(+id));
        confirmModalRef.current?.hide();
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <UITable
        onRowClick={(i) => {
          history.push(`/licenses/${i.unique}`);
        }}
        withTabs
        title={{
          title: 'List of Licences',
          button: {
            icon: 'plus',
            text: 'Add License',
            onClick: () => addLicenseModalRef.current?.show(),
          },
        }}
        data={currentEmployee?.licenses}
        headers={[
          { label: 'Number' },
          { label: 'Type' },
          { label: 'Expiration Date' },
          { label: 'Issued by' },
          { label: 'Actions' },
        ]}
        columns={[
          {
            renderCol: (i) => {
              return (
                <div className='license__number'>
                  <Stack direction='row' spacing='8px'>
                    {i.licenseImages.length ? (
                      i.licenseImages.map(({ base64, extension, unique }) => {
                        const url = encodeBase64(base64, extension);
                        return (
                          <img
                            onClick={async (e) => {
                              e.stopPropagation();
                              previewImageModalRef.current?.show({
                                file: { name: i.number, unique, extension },
                              });
                            }}
                            src={url}
                            className='license__number-img'
                            alt=''
                          />
                        );
                      })
                    ) : (
                      <div className='license__number-img-placeholder' />
                    )}
                  </Stack>
                  {i.number}
                </div>
              );
            },
          },
          { renderCol: (i) => <Status color={getLicenseColor(i)} text={i.type.shortName} /> },
          { renderCol: (i) => formatDate({ date: i.expirationDate }) },
          { renderCol: (i) => i.issuedBy },
          {
            renderCol: (i) => {
              return (
                <Stack direction='row' spacing={2}>
                  <UIActionButton type='edit' onClick={() => addLicenseModalRef.current?.show(i)} />
                  <UIActionButton type='delete' onClick={() => deleteLicense(i.unique)} />
                </Stack>
              );
            },
          },
        ]}
        placeholder={{
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of licenses is empty. It will be displayed here.',
          icon: <PlaceholderLicensesIcon />,
        }}
      />
      <AddLicenseModal ref={addLicenseModalRef} />
      <ConfirmModal ref={confirmModalRef} title='Delete' loading={loading}>
        Do you really want to delete this license? You will not be able to undo this.
      </ConfirmModal>
      <PreviewImageModal ref={previewImageModalRef} />
    </>
  );
};

export default Licences;
