import { FormControl, FormLabel } from '@mui/material';
import { companiesService } from 'api';
import { AddCompanyData, AddCompanyError, EditCompanyData } from 'api/companies/types';
import { validator } from 'app/helpers/validator';
import PredictionModel from 'app/models/PredictionModel';
import { AxiosError } from 'axios';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormPhoneInput from 'shared/Inputs/FormPhoneInput/FormPhoneInput';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import './AddCompanyModal.sass';
import { AddCompanyModalProps, AddCompanyModalRef } from './types';
import MapModal from '../MapModal/MapModal';
import { MapModalRef } from '../MapModal/types';
import { CompanyModel } from 'app/models/CompanyModel';

interface SubmitData {
  name: string;
  address: PredictionModel | null;
  addressDescription: string;
  phone: string;
  email: string;
  comment: string;
}

const AddCompanyModal: React.ForwardRefRenderFunction<AddCompanyModalRef, AddCompanyModalProps> = (
  { getData },
  ref
) => {
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, setValue, reset, setError, clearErrors } = useForm<SubmitData>();
  const [loading, setLoading] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<CompanyModel | null>(null);

  const mapModalRef = useRef<MapModalRef>(null);

  useEffect(() => {
    reset();

    if (currentCompany) {
      for (let key in currentCompany) {
        if (key === 'address' && currentCompany.address.length) {
          const { description, longitude, latitude } = currentCompany.address[0];

          setValue('addressDescription', description);
          setValue('address', {
            description,
            location: {
              longitude,
              latitude,
            },
          });
        } else {
          //@ts-ignore
          setValue(key as keyof AddCompanyData, currentCompany[key]);
        }
      }
    }
  }, [currentCompany, reset, setValue]);

  useImperativeHandle(
    ref,
    () => ({
      show: (props) => {
        setCurrentCompany(null);
        if (props?.data) {
          setCurrentCompany(props.data);
        }
        setOpen(true);
      },
      hide: () => setOpen(false),
    }),
    []
  );

  const onSubmitHandler: SubmitHandler<SubmitData> = async (data) => {
    if (!data.address) return;

    try {
      setLoading(true);
      const {
        description,
        location: { longitude, latitude },
      } = data.address;

      const companyData: AddCompanyData | EditCompanyData = {
        name: data.name,
        comment: data.comment,
        email: data.email,
        address: {
          description,
          longitude,
          latitude,
        },
        phone: data.phone,
      };

      if (currentCompany) {
        await companiesService.editCompany({
          unique: currentCompany.unique,
          ...companyData,
        });
      } else {
        await companiesService.addCompany(companyData);
      }

      getData();
      onClose();
    } catch (e) {
      const err = e as AxiosError;
      const errorText = (err.response?.data as AddCompanyError)?.errorText;
      if (!!errorText?.length) setError('name', { message: errorText });
    } finally {
      setLoading(false);
    }
  };

  const confirmAddress = (address: { latitude: number; longitude: number; description: string }) => {
    setValue('addressDescription', address.description);
    setValue('address', {
      description: address.description,
      location: {
        latitude: address.latitude,
        longitude: address.longitude,
      },
    });

    clearErrors('addressDescription');
  };

  const onClose = () => {
    reset({ name: '', address: null, phone: '', email: '', comment: '', addressDescription: '' });

    clearErrors();
    setOpen(false);
  };

  return (
    <>
      <UIModal
        open={open}
        onClose={onClose}
        classes={{
          root: 'add-company-modal',
          paper: 'add-company-modal__paper',
        }}
      >
        <SubmitModalLayout
          title={currentCompany ? 'Edit Company' : 'Add Company'}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmitHandler)}
          loading={loading}
        >
          <div className='add-company-modal__inputs'>
            <FormControl className='add-company-modal__row'>
              <FormLabel required>Name</FormLabel>
              <FormTextInput
                control={control}
                name='name'
                rules={{
                  required: 'Required field',
                }}
              />
            </FormControl>
            <FormControl className='add-company-modal__row'>
              <FormLabel required>Address</FormLabel>
              <FormTextInput
                control={control}
                name='addressDescription'
                onClick={() => mapModalRef.current?.show(currentCompany?.address[0])}
                rules={{ required: 'Required field' }}
              />
            </FormControl>
            <FormControl className='add-company-modal__row'>
              <FormLabel required>Phone number</FormLabel>
              <FormPhoneInput
                control={control}
                name='phone'
                rules={{
                  required: 'Required field',
                }}
              />
            </FormControl>
            <FormControl className='add-company-modal__row'>
              <FormLabel required>Email</FormLabel>
              <FormTextInput
                control={control}
                name='email'
                rules={{
                  required: 'Required field',
                  pattern: {
                    value: validator.email,
                    message: 'Invalid Email',
                  },
                }}
              />
            </FormControl>
            <FormControl className='add-company-modal__row'>
              <FormLabel>Comment</FormLabel>
              <FormTextInput control={control} name='comment' type='text' multiline />
            </FormControl>
          </div>
        </SubmitModalLayout>
      </UIModal>
      <MapModal ref={mapModalRef} onSubmit={confirmAddress} />
    </>
  );
};

export default React.forwardRef(AddCompanyModal);
