import { StaffService } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ReactComponent as PlaceholderProfileIcon } from 'assets/icons/table/profile-2user.svg';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddMemeberModal from 'shared/Modals/AddMemberModal/AddMemeberModal';
import { AddMemberModalRef } from 'shared/Modals/AddMemberModal/types';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import { ModalBaseRef } from 'shared/Modals/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import { getCrew } from 'store/slices/staff';
import './Members.sass';
import { EmployeesModel } from 'app/models/StaffModel';

const Members: React.FC = () => {
  const { id } = useParams() as { id: string };
  const dispatch = useAppDispatch();

  const { currentCrew } = useAppSelector((state) => state.staff);
  const [loading, setLoading] = useState(false);

  const confirmModalRef = useRef<ModalBaseRef>(null);
  const addMemeberModalRef = useRef<AddMemberModalRef>(null);

  const deleteMember = (unique: number) => {
    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);
        const response = await StaffService.deleteCrewMember(unique);

        if (response.data.success) dispatch(getCrew(+id));
      } finally {
        setLoading(false);
        confirmModalRef.current?.hide();
      }
    });
  };

  const addMembers = async (crewMembers: EmployeesModel[]) => {
    if (!currentCrew) return;

    try {
      setLoading(true);

      const response = await StaffService.addCrewMembers({
        unique: currentCrew.unique,
        crewMembers: crewMembers.map((i) => i.unique),
      });

      if (response.data.success) {
        dispatch(getCrew(+id));
        addMemeberModalRef.current?.hide();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <UITable
        withTabs
        className='members__table'
        title={{
          title: 'List of Members',
          button: { text: 'Add Member', icon: 'plus', onClick: () => addMemeberModalRef.current?.show() },
        }}
        data={currentCrew?.crewMembers}
        headers={[{ label: 'Name' }, { label: 'Specialities' }, { label: 'Actions' }]}
        columns={[
          { columnName: 'name' },
          { renderCol: ({ specialities }) => <div className='members__specialities'>{specialities}</div> },
          {
            renderCol: (item) => {
              return <UIActionButton type='delete' onClick={() => deleteMember(item.unique)} />;
            },
          },
        ]}
        placeholder={{
          icon: <PlaceholderProfileIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of members is empty. It will be displayed here.',
        }}
      />
      <AddMemeberModal ref={addMemeberModalRef} onSubmit={addMembers} loading={loading} title='Add Member' />
      <ConfirmModal title='Delete' ref={confirmModalRef} loading={loading}>
        Do you really want to delete this member? You will not be able to undo this.
      </ConfirmModal>
    </>
  );
};

export default Members;
