import { CircularProgress } from '@mui/material';
import cn from 'classnames';
import _ from 'lodash';
import React from 'react';
import UIPagination from '../UIPagination/UIPagination';
import UITitle from '../UITitle/UITitle';
import TablePlaceholder from './components/TablePlaceholder/TablePlaceholder';
import { IUiTableProps } from './types';
import './UITable.sass';

const UITable = <T extends unknown>({
  headers,
  columns,
  data,
  className = '',
  showOrder,
  page,
  pageSize,
  loading,
  setRowClass,
  classes,
  onRowClick,
  pagination,
  withTabs,
  title,
  totalPages = 0,
  placeholder,
  topComponent,
  footer,
}: IUiTableProps<T>) => {
  if (!data || loading) {
    return (
      <div
        className={cn('ui-table__circular-progress', {
          'ui-table--first-tab': withTabs,
        })}
      >
        <CircularProgress />
      </div>
    );
  }

  const onRowClickHandler = (elem: T, index: number, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    // event.stopPropagation();
    let target = event.target as Element | null | undefined;
    while (target) {
      if (target === event.currentTarget) break;
      if (
        target.matches('[role="button"]') ||
        target.matches('[role="presentation"]') ||
        target.matches('a') ||
        target.matches('button')
      )
        return;
      target = target?.parentElement;
    }
    if (onRowClick) {
      onRowClick(elem, index, event);
    }
  };

  return (
    <div
      className={cn('ui-table__container', {
        [className]: className,
        'ui-table--with-title': title,
        'ui-table--first-tab': withTabs,
      })}
    >
      {title && (
        <UITitle
          {...title}
          className={cn('ui-table__title', {
            'ui-table__title--with-tabs': withTabs,
          })}
        />
      )}
      {!data.length && !loading && totalPages === 0 && placeholder ? (
        <TablePlaceholder
          icon={placeholder.icon}
          title={placeholder.title}
          subtitle={placeholder.subtitle}
          withTitle={!!title}
        />
      ) : (
        <>
          <table className={cn('ui-table')}>
            <thead>
              <tr
                className={cn({
                  [classes?.headerRow ?? '']: classes?.headerRow,
                })}
              >
                {showOrder && typeof page !== 'undefined' && <th className='ui-table__th'>№</th>}
                {headers.map((i, index) => (
                  <th
                    key={index}
                    className={cn('ui-table__th', {
                      'ui-table__th--right': i.align === 'right',
                      'ui-table__th--center': i.align === 'center',
                      'ui-table__th--nowrap': i.noWrap,
                      [classes?.headerCell ?? '']: classes?.headerCell,
                      [classes?.cell ?? '']: classes?.cell,
                    })}
                  >
                    {i.label ?? i.renderCol?.(data[index])}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {topComponent && (
                <tr className='ui-table__tr ui-table__tr--body'>
                  <td
                    className={cn('ui-table__td', {
                      [classes?.cell ?? '']: classes?.cell,
                    })}
                    colSpan={columns.length}
                  >
                    {topComponent}
                  </td>
                </tr>
              )}
              {data.map((i, index) => {
                return (
                  <tr
                    key={index}
                    className={cn('ui-table__tr ui-table__tr--body', {
                      'ui-table__tr--hoverable': onRowClick,
                      ...(setRowClass
                        ? {
                            [String(setRowClass(i, index))]: setRowClass(i, index),
                          }
                        : {}),
                      [classes?.bodyRow ?? '']: classes?.bodyRow,
                    })}
                    onClick={(event) => onRowClickHandler(i, index, event)}
                  >
                    {showOrder && pageSize && typeof page !== 'undefined' && (
                      <td
                        className={cn('ui-table__td', {
                          [classes?.cell ?? '']: classes?.cell,
                        })}
                      >
                        {page * pageSize + index + 1}
                      </td>
                    )}
                    {columns.map((c, iindex) => {
                      return (
                        <td
                          key={iindex}
                          className={cn('ui-table__td', {
                            [`ui-table__td--${c.align}`]: c.align,
                            [`ui-table__td--${c.columnClassName}`]: c.columnClassName,
                            'ui-table__td--nowrap': c.noWrap,
                            [classes?.cell ?? '']: classes?.cell,
                          })}
                        >
                          {c.columnName && _.get(i, c.columnName)}
                          {c.renderCol && c.renderCol(i, index)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {footer && (
                <tr className={'ui-table__tr ui-table__tr--body '}>
                  <td colSpan={columns.length} className='ui-table__td'>
                    {footer}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {pagination && (
            <div className='ui-table__pagination'>
              <UIPagination siblingCount={1} {...pagination} count={pagination.count || 1} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UITable;
