import { Box, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { StaffService } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CrewDetailsModel, CrewModel } from 'app/models/StaffModel';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import SubmitModalLayout from 'shared/Modals/SubmitModalLayout/SubmitModalLayout';
import { ModalBaseRef } from 'shared/Modals/types';
import UIInput from 'shared/ui/UIInput/UIInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import { setCreateScheduleCrew } from 'store/slices/schedules';
import { getCrews } from 'store/slices/staff';
import { ScheduleWorker } from '../../types';
import './AddScheduleCrewModal.sass';
import { FILTERS_LIMIT } from 'store/slices/filters/data';

interface Props {
  onChange: (data: ScheduleWorker[]) => void;
  workers: ScheduleWorker[];
}

const AddScheduleCrewModal: React.ForwardRefRenderFunction<ModalBaseRef, Props> = ({ onChange, workers }, ref) => {
  const { crews } = useAppSelector((state) => state.staff);
  const [open, setOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filteredCrews, setFilteredCrews] = useState<CrewModel[]>([]);
  const [selected, setSelected] = useState<CrewModel>();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentCrew, setCurrentCrew] = useState<CrewDetailsModel | null>(null);

  const dispatch = useAppDispatch();

  useImperativeHandle(
    ref,
    () => ({
      hide: () => setOpen(false),
      show: () => setOpen(true),
    }),
    []
  );

  useEffect(() => {
    dispatch(getCrews({ force: true, params: { page, pageSize: FILTERS_LIMIT } }));
  }, []);

  useEffect(() => {
    if (selected?.unique) {
      setLoading(true);

      StaffService.getCrew(selected.unique)
        .then((res) => setCurrentCrew(res.data.data))
        .finally(() => setLoading(false));
    }
  }, [selected]);

  useEffect(() => {
    setFilteredCrews(crews?.data ?? []);
  }, [crews]);

  const onSearchHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (!crews) return;
    const { value } = event.target;
    setSearchString(value);
    setFilteredCrews(crews?.data?.filter((el) => el.name.toLowerCase().includes(value.toLowerCase())));
  };

  const onSubmitHandler = async () => {
    if (!selected || !currentCrew) return;
    const members = currentCrew.crewMembers.map((el) => ({
      chatAccess: false,
      name: el.name,
      specialities: el.specialities,
      startTime: '',
      unique: el.unique,
      status: el.status,
      licenses: el.licenses,
    }));

    onChange([...members, ...workers]);
    dispatch(setCreateScheduleCrew(currentCrew));

    setOpen(false);
  };

  const getMoreCrews = () => {
    const nextPage = page + 1;

    if (crews?.totalPages && nextPage <= crews.totalPages) {
      dispatch(getCrews({ force: true, concat: true, params: { page: nextPage, pageSize: FILTERS_LIMIT } }));

      setPage(nextPage);
    }
  };

  return (
    <UIModal
      className='add-schedule-crew-modal'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ sx: { maxWidth: '817px', width: '100%' } }}
    >
      <SubmitModalLayout title='Add crew' onSubmit={onSubmitHandler} onClose={() => setOpen(false)}>
        <div className='add-crew'>
          <Box mb={2}>
            <UIInput
              value={searchString}
              onChange={onSearchHandler}
              fullWidth
              placeholder='Search By Name'
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
          </Box>
          <Stack className='add-schedule-crew-modal__table-head' direction='row' spacing={4}>
            <div className='add-schedule-crew-modal__table-title'>Crew Name</div>
            <div className='add-schedule-crew-modal__table-title'>Workers in the seleted crew</div>
          </Stack>
          <Stack direction='row' spacing={4}>
            <FormControl
              className='add-schedule-crew-modal__column scroll'
              onScroll={(event) => {
                const listBoxNode = event.currentTarget;

                if (listBoxNode.scrollTop + listBoxNode.clientHeight >= listBoxNode.scrollHeight) {
                  getMoreCrews();
                }
              }}
            >
              <RadioGroup name='schedule-add-crew'>
                {filteredCrews.map((el, index) => (
                  <FormControlLabel
                    onChange={(_event, checked) => setSelected(checked ? el : undefined)}
                    key={index}
                    value={el.unique}
                    control={
                      <Radio checked={selected?.unique === el.unique} sx={{ marginLeft: '2px', marginRight: '8px' }} />
                    }
                    label={el.name}
                    sx={{ height: '54px' }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <div className='add-schedule-crew-modal__members add-schedule-crew-modal__column scroll'>
              <div className='add-schedule-crew-modal__list'>
                {loading ? (
                  <div className='add-schedule-crew-modal__loading'>
                    <CircularProgress />
                  </div>
                ) : (
                  currentCrew?.crewMembers?.map((member) => (
                    <div className='add-schedule-crew-modal__member'>{member.name}</div>
                  ))
                )}
              </div>
            </div>
          </Stack>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(AddScheduleCrewModal);
