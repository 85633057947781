import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { companiesService } from 'api';
import { GetCompaniesBody, GetCompaniesResponse, GetCompanyFilesBody } from 'api/companies/types';
import { RateModel } from 'app/models/CompanyModel';
import { RootState } from 'store/store';
import { CompaniesState } from './types';

const initialState: CompaniesState = {
  companies: {
    data: [],
  },
};

export const selectCompany = createAsyncThunk(
  'companies/selectCompany',
  async (payload: { unique: number; callback?: Function }) => {
    const company = await companiesService.getCompanyById(payload.unique).then((res) => res.data.data);
    await payload.callback?.();

    return company;
  }
);

export const getCompanyFiles = createAsyncThunk(
  'companies/getCompanyFiles',
  async (payload: { params?: GetCompanyFilesBody }, ThunkApi) => {
    ThunkApi.dispatch(setCompanyFilesFilter(payload?.params));
    return await companiesService.getCompanyFiles(payload?.params).then((res) => res.data);
  }
);

export const getCompanies = createAsyncThunk(
  'companies/getCompanies',
  async (payload: { force?: boolean; params?: GetCompaniesBody } | undefined, ThunkApi) => {
    const { companies } = (ThunkApi.getState() as RootState).companies as CompaniesState;
    if (companies.data.length && !payload?.force) return companies;

    return await companiesService.getCompanies(payload?.params).then((res) => res.data);
  }
);

export const getRateHistory = createAsyncThunk(
  'companies/getRateHistory',
  async (payload: { force?: boolean; body: { uniqueC: number; uniqueS: number } }, ThunkApi) => {
    const { currentRateHistory } = (ThunkApi.getState() as RootState).companies as CompaniesState;
    if (currentRateHistory?.length && !payload.force) return currentRateHistory;

    return await companiesService.getRateHistory(payload.body).then((res) => res.data.data);
  }
);

export const setCurrentRate = createAsyncThunk('companies/setCurrentRate', async (payload: RateModel | undefined) => {
  return payload;
});

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanyFilesFilter: (state, action: PayloadAction<GetCompanyFilesBody | undefined>) => {
      state.currentCompanyFilesFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectCompany.pending, (state) => {
        state.selectedCompany = undefined;
        state.companyLoading = true;
      })
      .addCase(selectCompany.fulfilled, (state, action) => {
        state.companyLoading = false;
        state.selectedCompany = action.payload;
      })
      .addCase(getCompanyFiles.pending, (state) => {
        state.currentCompanyFiles = undefined;
        state.currentCompanyFilesLoading = true;
      })
      .addCase(getCompanyFiles.fulfilled, (state, action) => {
        state.currentCompanyFilesLoading = false;
        state.currentCompanyFiles = action.payload;
      })
      .addCase(getCompanies.pending, (state) => {
        state.companiesLoading = true;
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.companiesLoading = false;
        state.companies = action.payload as GetCompaniesResponse;
      })
      .addCase(getRateHistory.pending, (state) => {
        state.currentRateHistoryLoading = true;
      })
      .addCase(getRateHistory.fulfilled, (state, action) => {
        state.currentRateHistory = action.payload;
        state.currentRateHistoryLoading = false;
      })
      .addCase(setCurrentRate.fulfilled, (state, action) => {
        state.currentRate = action.payload;
      });
  },
});

export const { setCompanyFilesFilter } = companiesSlice.actions;

export const companiesReducer = companiesSlice.reducer;
