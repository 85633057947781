import { CircularProgress } from '@mui/material';
import { utilsService } from 'api';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import UIModal from 'shared/ui/UIModal/UIModal';
import { PreviewImageModalProps, PreviewImageModalRef } from './types';
import { IMAGE_EXTENSIONS } from 'app/constants/FILE_CONFIG';
import './PreviewImageModal.sass';
import { downloadBlobFile } from 'app/helpers/downloadBlobFile';

const PreviewImageModal: React.ForwardRefRenderFunction<PreviewImageModalRef, PreviewImageModalProps> = (_, ref) => {
  const [open, setOpen] = useState(false);
  const [fileData, setFileData] = useState<{ unique: number; name: string; extension: string } | null>(null);
  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      show: async (data) => {
        const isImage = data?.file.extension && IMAGE_EXTENSIONS.includes(data.file.extension.toLowerCase());

        if (isImage) {
          setOpen(true);
        }

        if (data?.file) {
          try {
            setLoading(true);
            setFileData(data.file);

            const res = await utilsService.getWebFile(data.file.unique);

            console.log('res: ', res);
            // @ts-ignore
            const file = new Blob([res.data], { type: res.data?.type });
            const url = window.URL.createObjectURL(file);
            setPreview(url);

            if (!isImage) {
              downloadBlobFile(res, data.file.name, data.file.extension, true);
            }
          } finally {
            setLoading(false);
          }
        }
      },
      hide: () => {
        setOpen(false);
      },
    }),
    []
  );

  useEffect(() => {
    if (!open) {
      setLoading(false);
      setPreview('');
      setFileData(null);
      window.URL.revokeObjectURL(preview);
    }
  }, [open]);

  const download = (url: string, name = 'file', extension = 'txt') => {
    const a = document.createElement('a');
    a.href = url;
    a.download = `${name}.${extension}`;

    a.click();
    a.remove();
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'preview-image-modal',
        paper: 'preview-image-modal__paper',
      }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '1014px',
          minHeight: '760px',
        },
      }}
    >
      {loading ? (
        <div className='preview-image-modal__loading'>
          <CircularProgress />
        </div>
      ) : (
        <img className='preview-image-modal__image' src={preview} alt='' />
      )}

      <UIActionButton onClick={() => setOpen(false)} type='close' classes={{ root: 'preview-image-modal__close' }} />
      {!!preview.length && (
        <UIButton
          className='preview-image-modal__download'
          color='white'
          icon='download'
          text='Download'
          outline
          onClick={() => download(preview, fileData?.name, fileData?.extension)}
        />
      )}
    </UIModal>
  );
};

export default React.forwardRef(PreviewImageModal);
