import { useAppSelector } from 'app/hooks';
import { ReactComponent as PlaceholderPeopleIcon } from 'assets/icons/table/profile-2user.svg';
import UITable from 'shared/ui/UITable/UITable';

const EmployeesDetails = () => {
  const { selectedCompany } = useAppSelector((state) => state.companies);

  return (
    <UITable
      withTabs
      data={selectedCompany?.employees}
      headers={[{ label: 'Name' }, { label: 'Email' }, { label: 'Phone' }]}
      columns={[{ columnName: 'name' }, { columnName: 'email' }, { columnName: 'phone' }]}
      placeholder={{
        icon: <PlaceholderPeopleIcon />,
        title: 'Sorry, it is empty here!',
        subtitle: 'The list of emplyees is empty yet. It will be displayed here.',
      }}
    />
  );
};

export default EmployeesDetails;
