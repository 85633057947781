import instance from 'api/instance';
import { AddPayroll, DeleteBill, DeletePayroll, EditPayroll, FillPayroll, GetPayroll, GetPayrolls } from './types';

export const getPayrolls: GetPayrolls = (body) => {
  return instance.post('/exec?action=get_payrolls', body, {
    responseType: body?.exportXls ? 'blob' : 'json',
  });
};

export const getPayroll: GetPayroll = (id) => {
  return instance.post('/exec?action=get_payroll', {
    unique: id,
  });
};

export const fillPayroll: FillPayroll = (startDate, endDate) => {
  return instance.post('/exec?action=fill_payroll', {
    startDate,
    endDate,
  });
};

export const editPayroll: EditPayroll = (body) => {
  return instance.post('/exec?action=edit_payroll', body);
};

export const deletePayroll: DeletePayroll = (unique) => {
  return instance.post(`/exec?action=delete_payroll`, {
    unique,
  });
};

export const addPayroll: AddPayroll = (data) => {
  return instance.post(`/exec?action=add_payroll`, data);
};

export const deleteBill: DeleteBill = (unique) => {
  return instance.post(`/exec?action=delete_payrollLine`, {
    unique,
  });
};
