import { FormControl, FormLabel } from '@mui/material';
import _ from 'lodash';
import React, { useImperativeHandle, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { EditDailyLogModalModalRef, EditDailyLogModalModalProps } from './types';
import UploadFiles from '../AddLicenseModal/components/UploadFiles/UploadFiles';
import { UploadFileModel } from '../AddLicenseModal/components/UploadFiles/types';
import { encodeBase64 } from 'app/helpers/toBase64';
import { schedulesService } from 'api';
import './EditDailyLogModal.sass';

interface SubmitData {
  unique: number;
  workDone: string;
}

const EditDailyLogModal: React.ForwardRefRenderFunction<EditDailyLogModalModalRef, EditDailyLogModalModalProps> = (
  { getData },
  ref
) => {
  const [open, setOpen] = useState(false);
  const { control, handleSubmit, setValue, reset } = useForm<SubmitData>();
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState<UploadFileModel[]>([]);
  const [deleteFilesId, setDeleteFilesId] = useState<number[]>([]);

  const [title, setTitle] = useState('');

  useImperativeHandle(
    ref,
    () => ({
      show: (dailyLog) => {
        if (open) return;
        reset();

        if (dailyLog) {
          setValue('workDone', dailyLog.workDone);
          setValue('unique', dailyLog.unique);

          if (dailyLog.imageUnique) {
            setFiles([
              {
                unique: dailyLog.imageUnique,
                base64: dailyLog.image,
                extension: '.jpg',
                preview: encodeBase64(dailyLog.image, '.jpg'),
              },
            ]);
          }

          setTitle(dailyLog.name);
        }

        setOpen(true);
      },
      hide: () => setOpen(false),
    }),
    [open]
  );

  const onSubmitHandler: SubmitHandler<SubmitData> = async (data) => {
    try {
      setLoading(true);

      await schedulesService.editDailyLog(data);

      if (deleteFilesId.length) {
        await Promise.all(deleteFilesId.map((item) => schedulesService.deleteDailyReportFile(item)));
      }

      const newFiles = files.filter((f) => f.isFileUpdated);

      if (newFiles.length) {
        await schedulesService.addDailyReportFile({
          unique: data.unique,
          files: newFiles.map(({ base64 }) => ({
            base64: base64.split('base64,')[1],
          })),
        });
      }

      getData();
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setOpen(false);
    setFiles([]);
    setDeleteFilesId([]);
    reset();
  };

  return (
    <>
      <UIModal
        open={open}
        onClose={onClose}
        classes={{
          root: 'edit-daily-report-modal',
          paper: 'edit-daily-report-modal__paper',
        }}
        PaperProps={{
          sx: { maxWidth: '593px', width: '100%' },
        }}
      >
        <SubmitModalLayout
          title={`Edit ${title}`}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmitHandler)}
          loading={loading}
          submitBtnText='Save'
        >
          <div className='edit-daily-report-modal__inputs'>
            <FormControl className='edit-daily-report-modal__row'>
              <FormLabel required>What has been done</FormLabel>
              <FormTextInput
                control={control}
                name='workDone'
                rules={{
                  required: 'Required field',
                }}
              />
            </FormControl>

            <UploadFiles
              title='Attachments'
              maxImages={1}
              files={files}
              setFiles={setFiles}
              deleteFilesId={deleteFilesId}
              setDeleteFilesId={setDeleteFilesId}
            />
          </div>
        </SubmitModalLayout>
      </UIModal>
    </>
  );
};

export default React.forwardRef(EditDailyLogModal);
