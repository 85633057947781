import cn from 'app/helpers/cn';
import React from 'react';
import Status from '../Status/Status';
import './SingleDetail.sass';
import { SingleDetailProps } from './types';

const SingleDetail: React.FC<SingleDetailProps> = ({ title, icon, label, className = '', status, render }) => {
  if (!label && !render && !status) return <></>;

  const Icon = icon;
  return (
    <div
      className={cn('single-detail', {
        [className]: className,
      })}
    >
      {Icon && <Icon className='single-detail__icon' />}
      <div className='single-detail__label'>
        {title && <span className='single-detail__title'>{title}</span>}
        {render ? (
          render
        ) : (
          <>
            {label}
            {status && <Status {...status} />}
          </>
        )}
      </div>
    </div>
  );
};

export default SingleDetail;
