import React, { useEffect, useImperativeHandle, useState } from 'react';
import SubmitModalLayout from 'shared/Modals/SubmitModalLayout/SubmitModalLayout';
import UIModal from 'shared/ui/UIModal/UIModal';
import { WorkersFilterModalProps, WorkersFilterModalRef } from './types';
import './WorkersFilterModal.sass';

import { FormControl, FormLabel } from '@mui/material';
import { GetEmployeesBody } from 'api/staff/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useFilters from 'app/hooks/useFilters';
import { WebQualificationsModel } from 'app/models/StaffModel';
import _ from 'lodash';
import { colorizeStatusEmployees } from 'pages/StaffPage/components/Employees/helpers';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import Status from 'shared/ui/Status/Status';
import { IStatus } from 'shared/ui/Status/types';
import UICheckbox from 'shared/ui/UICheckbox/UICheckbox';
import { getEmployees, getEmployeesStatuses, getWebQualifications } from 'store/slices/staff';
import { WorkersFilterParams } from '../../types';
import { FILTERS_LIMIT } from '../../../../../../../store/slices/filters/data';
import { getFEmployeesForSchedule } from '../../../../../../../store/slices/filters';
import dayjs from 'dayjs';

const initialFilterParams: WorkersFilterParams = {
  statuses: [],
  search: '',
};

interface SubmitData {
  qualifications: WebQualificationsModel[];
  pRate: number | string;
  statuses?: { label: string; value: string; color?: IStatus }[];
  search?: string;
}

const EmployeesFilterModal: React.ForwardRefRenderFunction<WorkersFilterModalRef, WorkersFilterModalProps> = (
  { scheduleDate },
  ref
) => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, reset } = useForm<SubmitData>();

  const [open, setOpen] = useState(false);
  const { statuses: statusesData, webQualifications } = useAppSelector((state) => state.staff);
  const [statuses, setStatuses] = useState<{ label: string; value: string; color?: IStatus }[]>([]);

  const { onChangeFilter, getNonEmptyFilters, resetFilters, resetAndOnChangeFilter } = useFilters({
    initialFilterParams,
  });

  useImperativeHandle(ref, () => ({
    show: () => {
      dispatch(getEmployeesStatuses());
      dispatch(getWebQualifications());
      setOpen(true);
    },
    hide: () => setOpen(false),
    onChange: (params: GetEmployeesBody) => {
      const filters = onChangeFilter(params);

      dispatch(
        getFEmployeesForSchedule({
          params: {
            ...getNonEmptyFilters(filters),
            type: 'worker',
            pageSize: FILTERS_LIMIT,
            date: dayjs(scheduleDate).format('DD/MM/YYYY'),
          },
        })
      );
    },
    refreshPage: () => {
      dispatch(
        getFEmployeesForSchedule({
          reset: true,
          params: {
            type: 'worker',
            pageSize: FILTERS_LIMIT,
            date: dayjs(scheduleDate).format('DD/MM/YYYY'),
          },
        })
      );
    },
  }));

  useEffect(() => {
    setStatuses(
      statusesData?.map((s) => {
        const { text: label, color } = colorizeStatusEmployees(s.id);

        return {
          label,
          value: s.id,
          color,
        };
      }) ?? []
    );
  }, [statusesData]);

  const applyFilters: SubmitHandler<SubmitData> = (data) => {
    const search = getNonEmptyFilters().search;

    const submitData: GetEmployeesBody = {
      statuses: data.statuses?.map((s) => s.value),
      qualifications: data.qualifications?.map((q) => q.unique),
    };

    if (search) _.set(submitData, 'search', search);
    if (data.pRate) _.set(submitData, 'pRate', +data.pRate);

    const filters = resetAndOnChangeFilter(getNonEmptyFilters(submitData));
    dispatch(
      getFEmployeesForSchedule({
        reset: true,
        params: {
          ...getNonEmptyFilters(filters),
          type: 'worker',
          pageSize: FILTERS_LIMIT,
          date: dayjs(scheduleDate).format('DD/MM/YYYY'),
        },
      })
    );
    setOpen(false);
  };

  const resetAppliedFilter = () => {
    const filters = resetFilters();

    reset({ statuses: [], qualifications: [], pRate: '' });
    getFEmployeesForSchedule({
      reset: true,
      params: {
        ...getNonEmptyFilters(filters),
        type: 'worker',
        pageSize: FILTERS_LIMIT,
        date: dayjs(scheduleDate).format('DD/MM/YYYY'),
      },
    });

    setOpen(false);
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'filter-modal',
        paper: 'filter-modal__paper',
      }}
    >
      <SubmitModalLayout
        title='Filter'
        onClose={() => setOpen(false)}
        submitBtnText='Apply'
        clearFilter={resetAppliedFilter}
        onSubmit={handleSubmit(applyFilters)}
      >
        <div className='filter-modal__inputs'>
          {statuses && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Status</FormLabel>
              <FormSelectInput
                control={control}
                name='statuses'
                allCheckbox
                isOptionEqualToValue={(option, value) => option.value === value.value}
                multiple
                options={statuses}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} style={{ paddingLeft: 7 }}>
                      <UICheckbox style={{ marginRight: 8 }} checked={selected} />
                      {option.color ? <Status text={option.label} color={option.color} /> : option.label}
                    </li>
                  );
                }}
              />
            </FormControl>
          )}

          {webQualifications && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Qualification</FormLabel>
              <FormSelectInput
                control={control}
                name='qualifications'
                allCheckbox
                isOptionEqualToValue={(option, value) => option.unique === value.unique}
                multiple
                options={webQualifications}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} style={{ paddingLeft: 7 }}>
                      <UICheckbox style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  );
                }}
              />
            </FormControl>
          )}
          <FormControl className='filter-modal__row'>
            <FormLabel>Rate ($ per hour)</FormLabel>
            <FormTextInput type='number' control={control} name='pRate' />
          </FormControl>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(EmployeesFilterModal);
