import { FormControl, FormLabel, Stack } from '@mui/material';
import { companiesService, utilsService } from 'api';
import DATE_FORMAT from 'app/constants/DATE_FORMAT';
import { downloadBlobFile } from 'app/helpers/downloadBlobFile';
import { getFileExtension } from 'app/helpers/getFileShortName';
import { toBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useFilters from 'app/hooks/useFilters';
import { CompanyFileModel } from 'app/models/CompanyModel';
import { ReactComponent as PlaceholderFolderOpenIcon } from 'assets/icons/table/folder-open.svg';
import dayjs from 'dayjs';
import qs from 'qs';
import React, { useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import FormDateInput from 'shared/Inputs/FormDateInput/FormDateInput';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import AddFileModal from 'shared/Modals/AddFileModal/AddFileModal';
import { AddFileModalRef } from 'shared/Modals/AddFileModal/types';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';
import { ModalBaseRef } from 'shared/Modals/types';
import Status from 'shared/ui/Status/Status';
import { StatusProps } from 'shared/ui/Status/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import { getCompanyFiles } from 'store/slices/companies';
import { FileType } from 'store/slices/utils/types';

interface SubmitData {
  fileName: string;
  fileDate: Date;
  fileType: FileType;
}

const limit = 10;
const FilesDetails: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams() as { id: string };

  const { selectedCompany, currentCompanyFiles, currentCompanyFilesFilter, currentCompanyFilesLoading } =
    useAppSelector((state) => state.companies);
  const { fileTypes } = useAppSelector((state) => state.utils);
  const { control, handleSubmit, setValue, reset } = useForm<SubmitData>();
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const addFilesModalRef = useRef<AddFileModalRef>(null);
  const confirmModalRef = useRef<ModalBaseRef>(null);
  const previewImageModalRef = useRef<PreviewImageModalRef>(null);

  const initialFilterParams = {
    page: +(qs.parse(location.search, { ignoreQueryPrefix: true }).page ?? 1),
  };

  const { replaceUrl, onChangeFilter } = useFilters({
    initialFilterParams,
    limit,
    initialCallback: (params) => dispatch(getCompanyFiles({ params: { ...params, pageSize: limit, unique: +id } })),
  });

  const onSubmitHandler: SubmitHandler<SubmitData> = async (data) => {
    if (!addFilesModalRef.current) return;
    const { files } = addFilesModalRef.current.handleSubmit();
    if (!selectedCompany || !files.length) return addFilesModalRef.current.validate();

    const file = files[0];

    const addFileData = {
      unique: selectedCompany.unique,
      name: data.fileName ?? file.name,
      type: data.fileType.id,
      expirationDate: dayjs(data.fileDate).format('DD/MM/YYYY'),
    };

    try {
      setLoading(true);
      const { unique } = await companiesService.addFileData(addFileData).then((res) => res.data);

      await toBase64(files[0]).then(async (result: string) => {
        await companiesService.addFile({
          unique: unique,
          extension: getFileExtension(file.name),
          file: result.split('base64,')[1],
          attachmentName: file.name,
        });
      });

      dispatch(getCompanyFiles({ params: currentCompanyFilesFilter }));
      addFilesModalRef.current?.hide();

      reset();
      addFilesModalRef.current.clearFiles();
    } finally {
      setLoading(false);
    }
  };

  const onAddFileHandler = () => {
    setIsUpdate(false);
    addFilesModalRef.current?.clearFiles();
    reset();

    addFilesModalRef.current?.show();
  };

  const onUpdateClickHandler = (file: CompanyFileModel) => {
    if (!selectedCompany) return;
    addFilesModalRef.current?.show(file);
    setIsUpdate(true);
    setValue('fileName', file.name);
    setValue('fileType', fileTypes.find((el) => el.name == file.type)!);
    setValue('fileDate', dayjs(file.expirationDate, 'DD/MM/YYYY').toDate());
  };

  const onSubmitUpdateHandler: SubmitHandler<SubmitData> = async (data) => {
    if (!addFilesModalRef.current) return;
    const { data: fileData, files } = addFilesModalRef.current.handleSubmit();
    if (!selectedCompany || !files.length || !fileData) return addFilesModalRef.current.validate();

    const editFileData = {
      unique: fileData.unique,
      name: data.fileName ?? files[0].name,
      type: data.fileType.id,
      expirationDate: dayjs(data.fileDate).format('DD/MM/YYYY'),
    };

    try {
      setLoading(true);
      const { unique } = await companiesService.editFileData(editFileData).then((res) => res.data);

      if (fileData.isFileUpdated) {
        await toBase64(files[0]).then(async (result: string) => {
          await companiesService.addFile({
            unique: unique,
            extension: getFileExtension(files[0].name),
            file: result.split('base64,')[1],
            attachmentName: files[0].name,
          });
        });
      }

      await dispatch(getCompanyFiles({ params: currentCompanyFilesFilter }));
      addFilesModalRef.current?.hide();

      reset();
      addFilesModalRef.current.clearFiles();
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = (unique: number) => {
    if (!selectedCompany) return;

    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);

        await companiesService.deleteCompanyFile(unique);
        confirmModalRef.current?.hide();

        await dispatch(getCompanyFiles({ params: currentCompanyFilesFilter }));
      } finally {
        setLoading(false);
      }
    });
  };

  const previewFileOrDownload = (item: CompanyFileModel, type?: 'download' | 'preview') => {
    if (type === 'preview') {
      previewImageModalRef.current?.show({ file: { name: item.name, unique: item.unique, extension: item.extension } });
    } else {
      utilsService.getWebFile(item.unique).then((res) => {
        downloadBlobFile(res, item.name, item.extension, true);
      });
    }
  };

  const getColor = (i: CompanyFileModel) => {
    let color: StatusProps['color'] = 'primary';
    if (!i.expirationDate) return color;
    if (dayjs(i.expirationDate, DATE_FORMAT).isValid()) {
      const diff = dayjs(i.expirationDate, DATE_FORMAT).diff(new Date(), 'days');
      if (diff < 0) {
        color = 'danger';
      } else if (diff >= 15) {
        color = 'primary';
      } else {
        color = 'warning';
      }
    }
    return color;
  };

  const changePage = (value: number) => {
    const filters = onChangeFilter({ page: value });
    replaceUrl(filters);
    dispatch(getCompanyFiles({ params: { ...filters, pageSize: limit, unique: +id } }));
  };

  return (
    <>
      <UITable
        loading={currentCompanyFilesLoading}
        data={currentCompanyFiles?.files}
        totalPages={currentCompanyFiles?.totalPages}
        headers={[{ label: 'Name' }, { label: 'Type' }, { label: 'Expiration Date' }, { label: 'Actions' }]}
        onRowClick={(i) => previewFileOrDownload(i, 'preview')}
        columns={[
          { columnName: 'name' },
          { columnName: 'type' },
          {
            renderCol: (file) => (
              <Status color={getColor(file)} text={dayjs(file.expirationDate, 'DD/MM/YYYY').format('DD MMM, YYYY')} />
            ),
            noWrap: true,
          },
          {
            renderCol: (file) => {
              return (
                <Stack direction='row' spacing={2}>
                  <UIActionButton type='download' onClick={() => previewFileOrDownload(file, 'download')} />
                  <UIActionButton type='edit' onClick={() => onUpdateClickHandler(file)} />
                  <UIActionButton type='delete' onClick={() => deleteFile(file.unique)} />
                </Stack>
              );
            },
            noWrap: true,
          },
        ]}
        title={{
          title: 'List of files',
          button: {
            text: 'Add File',
            icon: 'plus',
            onClick: onAddFileHandler,
          },
        }}
        placeholder={{
          icon: <PlaceholderFolderOpenIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of files is empty yet. You can first file right now.',
        }}
        pagination={{
          count: currentCompanyFiles?.totalPages ?? 1,
          page: currentCompanyFilesFilter?.page ?? 1,
          onChange: (_, value) => changePage(value),
        }}
      />
      <ConfirmModal title='Delete' submitBtnText='Confirm' ref={confirmModalRef} loading={loading}>
        Do you really want to delete this file? You will not be able to undo this.
      </ConfirmModal>
      <PreviewImageModal ref={previewImageModalRef} />
      <AddFileModal
        ref={addFilesModalRef}
        onSubmit={isUpdate ? handleSubmit(onSubmitUpdateHandler) : handleSubmit(onSubmitHandler)}
        loading={loading}
        maxSize={5}
      >
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <FormTextInput name='fileName' control={control} />
          </FormControl>
          <FormControl>
            <FormLabel required>Type</FormLabel>
            <FormSelectInput
              name='fileType'
              control={control}
              options={fileTypes}
              getOptionLabel={(option) => option.name}
              rules={{
                required: 'Required field',
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel required>Expiration Date</FormLabel>
            <FormDateInput value={null} name='fileDate' control={control} rules={{ required: 'Required field' }} />
          </FormControl>
        </Stack>
      </AddFileModal>
    </>
  );
};

export default FilesDetails;
