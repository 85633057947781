import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import authConfig from 'config/authConfig';
import React, { PropsWithChildren, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Layout from 'shared/Layout/Layout';
import ScrollToTop from 'shared/ScrollToTop/ScrollToTop';
import { useAppSelector } from './app/hooks';
import 'react-toastify/dist/ReactToastify.css';
import SocketSingleton from './api/socket';

authConfig();

const App: React.FC<PropsWithChildren> = ({ children }) => {
  const { token } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      connect(token);
    }
  }, [token]);

  const connect = async (token: string) => {
    const socket = SocketSingleton.getInstance();
    const success = await socket.login({ token });

    if (success) {
      socket.enableNotification();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <main className='main'>
        <Layout>
          <ScrollToTop />
          {children}
          <ToastContainer position='top-center' autoClose={2000} hideProgressBar limit={2} />
        </Layout>
      </main>
    </LocalizationProvider>
  );
};

export default App;
