import { CircularProgress, Stack } from '@mui/material';
import { DailyReportModel } from 'app/models/DailyReportModel';
import { FC, useRef } from 'react';
import { ReactComponent as CategoryIcon } from 'assets/icons/primary/category.svg';
import { ReactComponent as TickCircleIcon } from 'assets/icons/primary/tick-circle.svg';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';
import { encodeBase64 } from 'app/helpers/toBase64';
import { ReactComponent as CalendarIcon } from 'assets/icons/table/calendar.svg';
import './DailyReport.sass';
import ReportsPlaceholder from '../Placeholder/Placeholder';
import { Link } from 'react-router-dom';

interface Props {
  data: DailyReportModel | null;
  loading: boolean;
}

const DailyReport: FC<Props> = ({ data, loading }) => {
  const previewImageModalRef = useRef<PreviewImageModalRef>(null);

  if (!loading && !data) {
    return (
      <ReportsPlaceholder
        icon={<CalendarIcon />}
        title='Sorry, it is empty here!'
        subtitle='The daily report is empty. It will be displayed here.'
      />
    );
  }

  return (
    <>
      {loading ? (
        <div className='daily-report__loading'>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className='daily-report__cards'>
            {data?.reportedBy && (
              <Stack direction='row' gap='8px'>
                <p className='daily-report__reported-title'>Reported by:</p>
                <Link className='daily-report__reported-name' to={`/employees/${data.reportedBy}`}>
                  {data.reportedByName}
                </Link>
              </Stack>
            )}
            {data?.workDone && (
              <div className='daily-report__card'>
                <div className='daily-report__card-title'>
                  <TickCircleIcon className='daily-report__card-icon' />
                  What has been done
                </div>
                <p className='daily-report__card-description'>{data.workDone}</p>
              </div>
            )}
            {data?.workToDo && (
              <div className='daily-report__card'>
                <div className='daily-report__card-title'>
                  <CategoryIcon className='daily-report__card-icon' />
                  What is left to be done
                </div>
                <p className='daily-report__card-description'>{data.workToDo}</p>
              </div>
            )}
          </div>
          {!!data?.images.length && (
            <div className='daily-report__attachments'>
              <h3 className='daily-report__attachments-title'>Attachments</h3>
              <div className='daily-report__attachments-wrapper'>
                {data?.images.map((image, idx) => (
                  <img
                    className='daily-report__attachments-image'
                    src={encodeBase64(image.base64, 'jpg')}
                    alt={`${idx}`}
                    onClick={() =>
                      previewImageModalRef.current?.show({
                        file: {
                          name: `attachment-${image.unique}-${idx}`,
                          unique: image.unique,
                          extension: image.extension,
                        },
                      })
                    }
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <PreviewImageModal ref={previewImageModalRef} />
    </>
  );
};

export default DailyReport;
