import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';

interface Props {
  isPrivate?: boolean;
  ignore?: boolean;
  component: React.ReactElement;
}

export const PrivateRoute: React.FC<Props> = ({ isPrivate = true, ignore, component }) => {
  const { authorized } = useAppSelector((state) => state.auth);

  if (authorized && ignore) {
    return <Navigate to='/companies' />;
  }

  if (!authorized && isPrivate && !ignore) {
    return <Navigate to='/login' />;
  }

  return component;
};
