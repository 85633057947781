import { FormControl, FormLabel, Stack } from '@mui/material';
import { projectsService, schedulesService, utilsService } from 'api';
import { CreateScheduleBody } from 'api/schedules/types';
import DATE_FORMAT from 'app/constants/DATE_FORMAT';
import { downloadBlobFile } from 'app/helpers/downloadBlobFile';
import { getDateFromTime, getTimeAM_PM } from 'app/helpers/formatDate';
import { getFileExtension } from 'app/helpers/getFileShortName';
import { toBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useBlocker } from 'app/hooks/useBlocker';
import { ProjectModel } from 'app/models/ProjectModel';
import { ScheduleFileModel, ScheduleDetailedModel, ScheduleSupervisorModel } from 'app/models/SchedulesModel';
import history from 'app/routes/history';
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import FormDateInput from 'shared/Inputs/FormDateInput/FormDateInput';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import FormTimePickerInput from 'shared/Inputs/FormTimePickerInput/FormTimePickerInput';
import AddProjectModal from 'shared/Modals/AddProjectModal/AddProjectModal';
import { AddProjectModalRef } from 'shared/Modals/AddProjectModal/types';
import AddSupervisorModal from 'shared/Modals/AddSupervisorModal/AddSupervisorModal';
import { AddSupervisorModalRef } from 'shared/Modals/AddSupervisorModal/types';
import { ModalBaseRef } from 'shared/Modals/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import UIInputFile from 'shared/ui/UIFileInput/UIInputFile';
import UITable from 'shared/ui/UITable/UITable';
import UITabs from 'shared/ui/UITabs/UITabs';
import UITitle from 'shared/ui/UITitle/UITitle';
import { getWorkerAttendanceStatuses, setCreateScheduleCrew } from 'store/slices/schedules';
import AddScheduleCrewModal from './components/AddScheduleCrewModal/AddScheduleCrewModal';
import AddWorkerModal from './components/AddWorkerModal/AddWorkerModal';
import { AddWorkerModalRef } from './components/AddWorkerModal/types';
import SupervisorsTable from './components/SupervisorsTable/SupervisorsTable';
import WorkersTable from './components/WorkersTable/WorkersTable';
import { ScheduleWorker } from './types';
import './CreateSchedulePage.sass';
import FormProjectsSelect from 'shared/Inputs/Filters/FormProjectsSelect';
import { getFProjects } from 'store/slices/filters';

interface AddScheduleData {
  scheduleProject: ProjectModel;
  scheduleProjectAddress: string;
  scheduleCompany: string;
  scheduleStartTime: Date;
  scheduleDate: Date;
  scheduleJobDescription: string;
  scheduleLastDayWorked: string;
}

interface Props {
  mode?: 'edit';
}

const CreateSchedulePage: React.FC<Props> = ({ mode }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const stateParams = new URLSearchParams(location.search);
  const { projects } = useAppSelector((state) => state.filters);
  const { createScheduleSelectedCrew, workerAttendanceStatusesValueKey } = useAppSelector((state) => state.schedules);
  const { id } = useParams<{ id?: string }>();
  const { control, handleSubmit, setValue, reset, getValues, watch } = useForm<AddScheduleData>();

  const [files, setFiles] = useState<(File | ScheduleFileModel)[]>([]);
  const [projectSchedule, setProjectSchedule] = useState<ScheduleDetailedModel>();
  const [allowCopyLastSchedule, setAllowCopyLastSchedule] = useState(false);
  const [workers, setWorkers] = useState<ScheduleWorker[]>([]);
  const [superVisors, setSuperVisors] = useState<ScheduleSupervisorModel[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<ScheduleFileModel[]>([]);
  const [company, setCompany] = useState<{ uniqueC: number; name: string } | null>(null);

  const [createLoading, setCreateLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [ignoreBlocker, setIgnoreBlocker] = useState(false);

  const addWorkerModalRef = useRef<AddWorkerModalRef>(null);
  const addCrewModalRef = useRef<ModalBaseRef>(null);
  const addSupervisorsRef = useRef<AddSupervisorModalRef>(null);
  const addProjectsRef = useRef<AddProjectModalRef>(null);

  const [validScheduleDate, setValidScheduleDate] = useState(false);

  const currentProject = watch('scheduleProject');
  const scheduleDate = watch('scheduleDate');

  useBlocker(({ retry, location }) => {
    if (location.pathname === '/schedules/create' || location.pathname.includes('/schedules/edit') || ignoreBlocker) {
      return retry();
    }
    const confirm = window.confirm('Are you sure you want to leave?');
    if (confirm) {
      retry();
    }
  });

  useEffect(() => {
    setValidScheduleDate(scheduleDate ? dayjs(scheduleDate).isValid() : false);
  }, [scheduleDate]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getFProjects({ reset: true }));
    dispatch(getWorkerAttendanceStatuses());
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      e.returnValue = '';
    });

    return () => {
      window.removeEventListener('beforeunload', (e) => {
        e.preventDefault();
        e.returnValue = '';
      });
    };
  }, []);

  useEffect(() => {
    const selectedPeriod = stateParams.get('date');
    const today = new Date();
    today.setHours(7, 0, 0, 0);
    setValue('scheduleStartTime', today);

    if (selectedPeriod === 'today') {
      setValue('scheduleDate', today);
    }

    if (selectedPeriod === 'tomorrow') {
      const tommorrow = new Date(today);
      tommorrow.setDate(tommorrow.getDate() + 1);
      setValue('scheduleDate', tommorrow);
    }
  }, []);

  useEffect(() => {
    const projectId = searchParams.get('projectId');
    if (projectId) {
      const project = projects.data.find((project) => project.unique === Number(projectId));
      if (project) {
        setValue('scheduleProject', project);
        onChangeProject(project);
      }
    }
  }, [searchParams, projects]);

  useEffect(() => {
    if (mode === 'edit' && id && projects.data.length) {
      copyScheduleById(id);
    }
  }, [projects, mode, id]);

  const onChangeFile = async (filesArr: FileList) => {
    if (filesArr) {
      setFiles([...files, filesArr[0]]);
    }
  };

  const onChangeProject = async (data?: ProjectModel, schedule?: ScheduleDetailedModel) => {
    if (!data) return;

    const lastDayWorked = dayjs(data.lastDayWorked, DATE_FORMAT);

    setCompany({ uniqueC: data.uniqueC, name: data.company });
    setValue('scheduleCompany', data.company);
    setValue('scheduleProjectAddress', data.address?.[0]?.description);
    setValue('scheduleLastDayWorked', lastDayWorked.isValid() ? lastDayWorked.format('DD MMM, YYYY') : '');
    setAllowCopyLastSchedule(lastDayWorked.isValid());

    const scheduleData = schedule
      ? schedule
      : await schedulesService.getSchedule(data.unique).then((res) => res.data.data);

    setProjectSchedule(scheduleData);

    if (scheduleData) {
      setSuperVisors(scheduleData.supervisors);
    }

    if (schedule && data) {
      setValue('scheduleProject', data);
    }
  };

  const copyScheduleById = async (id: string) => {
    if (!projects.data.length) return;
    const schedule = await schedulesService.getSchedule(+id).then((res) => res.data.data);
    setProjectSchedule(schedule);

    let project = projects.data.find((el) => el.unique === schedule.uniqueP);

    if (!project) {
      project = await projectsService.getProject(schedule.uniqueP).then((res) => res.data.data as ProjectModel);
    }

    onChangeProject(project, schedule);

    setValue('scheduleStartTime', getDateFromTime(schedule.startTime));
    setValue('scheduleDate', dayjs(schedule.date, DATE_FORMAT).toDate());
    setValue('scheduleJobDescription', schedule.description ?? '');

    setWorkers(
      schedule.attendance.map((el) => ({
        name: el.nameEmployee ?? '',
        startTime: el.startTime ?? '',
        chatAccess: el.chatAccess ?? false,
        status: el.status ?? '',
        specialities: el.specialities ?? '',
        unique: el.unique ?? '',
        isForeman: el.isForeman ?? false,
        licenses: el.licenses,
      }))
    );
  };

  const copyLastScheduleHandler = () => {
    if (!projectSchedule) return;

    setValue('scheduleStartTime', getDateFromTime(projectSchedule.startTime));
    setValue('scheduleDate', dayjs(projectSchedule.date, DATE_FORMAT).toDate());
    setValue('scheduleJobDescription', projectSchedule.description);

    projectsService.getProjectFiles({ unique: currentProject.unique }).then((res) => {
      setFiles(() => res.data.files);
    });

    setWorkers(
      projectSchedule.attendance.map((el) => ({
        name: el.nameEmployee ?? '',
        startTime: el.startTime ?? '',
        chatAccess: el.chatAccess ?? false,
        status: '',
        specialities: el.specialities ?? '',
        unique: el.unique ?? '',
        isForeman: el.isForeman ?? false,
        licenses: el.licenses,
      }))
    );
    setSuperVisors(
      projectSchedule.supervisors.map((el) => ({
        nameEmployee: el.nameEmployee,
        type: el.type,
        unique: el.unique,
      }))
    );
  };

  const onSubmitHandler = async (data: AddScheduleData, ignoreReset: boolean, isSave?: boolean) => {
    try {
      isSave ? setSaveLoading(true) : setCreateLoading(true);
      const scheduleStartTime = getTimeAM_PM(data.scheduleStartTime);

      const workersFormatted = workers.map((el) => {
        let startTime = getTimeAM_PM(getDateFromTime(el.startTime));
        if (!startTime.time || !startTime.timeFormat) startTime = scheduleStartTime;

        const data = {
          chatAccess: el.chatAccess,
          startTime: `${startTime.time} ${startTime.timeFormat}` ?? '',
          statusId: workerAttendanceStatusesValueKey[el.status] ?? '',
          unique: el.unique,
          isForeman: el.isForeman,
        };

        if (!el.isForeman) _.omit(data, 'foreman');
        return data;
      });

      const postData: CreateScheduleBody = {
        date: dayjs(data.scheduleDate).format(DATE_FORMAT),
        startTime: `${scheduleStartTime.time} ${scheduleStartTime.timeFormat}`,
        description: data.scheduleJobDescription,
        unique: data.scheduleProject.unique,
        workers: workersFormatted,
        supervisors: superVisors.map((el) => ({
          unique: el.unique,
        })),
      };

      let scheduleUnique = projectSchedule?.unique;
      if (mode === 'edit' && scheduleUnique) {
        await schedulesService.editSchedule({ ...postData, uniqueS: scheduleUnique });
      } else {
        scheduleUnique = await schedulesService.createSchedule(postData).then((res) => res.data.unique);
      }
      if (files.length) {
        await Promise.allSettled(
          files.map((el) => {
            if ((el as ScheduleFileModel).unique) {
              return () => {};
            }
            return new Promise(async (resolve, reject) => {
              if (!scheduleUnique) return reject();
              try {
                const { unique: fileUnique } = await schedulesService
                  .addScheduleFileData({ unique: scheduleUnique, name: el.name })
                  .then((res) => res.data);

                await toBase64(el as File, true).then(async (res) => {
                  await schedulesService.addScheduleFile({
                    unique: fileUnique,
                    file: res,
                    extension: getFileExtension(el.name),
                    attachmentName: el.name,
                  });
                });
                resolve(null);
              } catch {
                reject(null);
              }
            });
          })
        );
      }
      if (filesToDelete.length) {
        await Promise.allSettled(
          filesToDelete.map((file) => {
            return new Promise(async (resolve, reject) => {
              try {
                await schedulesService.deleteScheduleFile(file.unique);
                resolve(null);
              } catch (err) {
                reject(err);
              }
            });
          })
        );
      }

      if (mode === 'edit') {
        history.push('/schedules/create');
      }

      if (!ignoreReset) {
        reset({ scheduleLastDayWorked: '' });
        setWorkers([]);
        setSuperVisors([]);
        setFiles([]);
        setProjectSchedule(undefined);
      }
    } finally {
      setCreateLoading(false);
      setSaveLoading(false);
    }
  };

  const onSaveSchedule = async (data: AddScheduleData) => {
    const scheduleDate = dayjs(data.scheduleDate).format(DATE_FORMAT);

    setIgnoreBlocker(true);
    await onSubmitHandler(data, true, true);
    history.push(`/schedules?from=${scheduleDate}&to=${scheduleDate}`);
  };

  const removeWorker = (index: number, removeCrew?: boolean) => {
    if (removeCrew) {
      setWorkers([]);
      return;
    }

    setWorkers((prev) => {
      const clone = _.cloneDeep(prev);
      clone.splice(index, 1);
      return clone;
    });
  };

  const removeSupervisor = (index: number, removeCrew?: boolean) => {
    if (removeCrew) return setSuperVisors([]);

    setSuperVisors((prev) => {
      const clone = _.cloneDeep(prev);
      clone.splice(index, 1);
      return clone;
    });
  };

  const onAddWorkersHandler = (data: ScheduleWorker[]) => {
    setWorkers(
      data.map((i) => {
        i.chatAccess = true;
        i.startTime = dayjs(new Date(getValues('scheduleStartTime'))).format('hh:mm A');
        return i;
      })
    );
  };

  const onChangeChatAccess = (type: 'chat' | 'foreman', worker: (typeof workers)[number], checked: boolean) => {
    setWorkers((prev) => {
      const clone = _.cloneDeep(prev);
      const foundIndex = clone.findIndex((el) => el.unique === worker.unique);
      if (foundIndex !== -1) {
        if (type === 'chat') clone[foundIndex].chatAccess = checked;
        if (type === 'foreman') clone[foundIndex].isForeman = checked;
      }
      return clone;
    });
  };

  const onChangeWorkerTime = (index: number, value: Date | null) => {
    if (!value) return;

    setWorkers((prev) => {
      const clone = _.cloneDeep(prev);
      clone[index].startTime = dayjs(new Date(value)).format('hh:mm A');

      return clone;
    });
  };

  const onFileDeleteHandler = (file: (typeof files)[number], index: number) => {
    setFiles((prev) => {
      const clone = _.cloneDeep(prev);
      clone.splice(index, 1);
      return clone;
    });
    if ('unique' in file) {
      setFilesToDelete((prev) => [...prev, file]);
    }
  };

  const addSupervisorsHandler = () => {
    const supervisorsToAdd = addSupervisorsRef.current?.submit() ?? [];
    setSuperVisors((prev) =>
      _.uniqBy(
        [
          ...supervisorsToAdd.map((el) => ({
            unique: el.unique,
            nameEmployee: el.name ?? '',
            chatAccess: true,
            type: el.type ?? '',
          })),
        ],
        'unique'
      )
    );
    addSupervisorsRef.current?.hide();
  };

  const handleChangeStartTime = (date: Date | null) => {
    setWorkers(
      workers.map((worker) => ({
        ...worker,
        startTime: dayjs(new Date(date!)).format('hh:mm A'),
      }))
    );
  };

  const downloadFile = (file: ScheduleFileModel) => {
    utilsService.getWebFile(file.unique).then((res) => {
      downloadBlobFile(res, file.name, file.extension);
    });
  };

  return (
    <div className='create-schedule'>
      <UITitle
        title={mode === 'edit' ? 'Edit Schedule' : 'Create Schedule'}
        backPath='/schedules'
        button={{
          text: 'Save & Create New',
          square: true,
          onClick: handleSubmit((data) => onSubmitHandler(data, false)),
          loading: createLoading,
        }}
        children={
          <>
            <UIButton text='Save' color='white' square onClick={handleSubmit(onSaveSchedule)} loading={saveLoading} />
          </>
        }
      />
      <div className='create-schedule__content'>
        <div className='create-schedule__left'>
          <div className='create-schedule__inputs'>
            <Stack spacing={2} sx={{ paddingInline: '24px' }}>
              <FormControl>
                <FormLabel required>Project</FormLabel>
                <FormProjectsSelect
                  name='scheduleProject'
                  control={control}
                  topButton={{
                    text: 'Add Project',
                    icon: 'plus',
                    fluid: true,
                    onClick: () => addProjectsRef.current?.show(),
                  }}
                  onChange={async (_, value) => {
                    if (!value) {
                      setCompany(null);

                      setValue('scheduleCompany', '');
                      setValue('scheduleProjectAddress', '');
                      setValue('scheduleLastDayWorked', '');

                      return;
                    }
                    onChangeProject(value);
                  }}
                  rules={{
                    required: 'Required field',
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Project Address</FormLabel>
                <FormTextInput control={control} name='scheduleProjectAddress' disabled />
              </FormControl>

              {allowCopyLastSchedule && (
                <FormControl>
                  <FormLabel>Last day worked</FormLabel>
                  <Stack spacing={2} direction='row'>
                    <FormTextInput control={control} name='scheduleLastDayWorked' disabled />
                    <UIButton
                      text='Copy last schedule'
                      square
                      className='create-schedule__form--btn'
                      onClick={copyLastScheduleHandler}
                    />
                  </Stack>
                </FormControl>
              )}

              <FormControl>
                <FormLabel>Company</FormLabel>
                <FormTextInput control={control} name='scheduleCompany' disabled />
              </FormControl>

              <Stack spacing={2} direction='row'>
                <FormControl>
                  <FormLabel required>Start Time</FormLabel>
                  <FormTimePickerInput
                    control={control}
                    name='scheduleStartTime'
                    rules={{
                      required: 'Required field',
                    }}
                    onChange={handleChangeStartTime}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel required>Date</FormLabel>
                  <FormDateInput
                    control={control}
                    name='scheduleDate'
                    rules={{
                      required: 'Required field',
                      validate: (val) => {
                        return dayjs(val as string).isValid();
                      },
                    }}
                    disablePast
                  />
                </FormControl>
              </Stack>

              <FormControl>
                <FormLabel>Job Description</FormLabel>
                <FormTextInput
                  control={control}
                  name='scheduleJobDescription'
                  multiline
                  // rules={{
                  //   required: 'Required field',
                  // }}
                />
              </FormControl>

              <div className='create-schedule__upload-file'>
                <Stack direction='row' justifyContent='space-between'>
                  <div className='create-schedule__upload-file--title'>Attachments</div>
                  <label className='create-schedule__upload-file--label'>
                    <div className='create-schedule__upload-file--button'>
                      <FolderIcon />
                      Attach file
                    </div>
                    <UIInputFile onChange={onChangeFile} accept='image/*,.xlsx,.xls,,.doc,.docx,.pdf,.mp4,.webm' />
                  </label>
                </Stack>
              </div>
            </Stack>
            {!!files.length && (
              <UITable
                classes={{ cell: 'create-schedule__table--cell', bodyRow: 'create-schedule__table--row' }}
                data={files}
                headers={[{ label: 'Name' }, { label: 'Actions' }]}
                columns={[
                  {
                    renderCol: (i) => (
                      <span
                        className='create-schedule__download-file'
                        onClick={() => downloadFile(i as ScheduleFileModel)}
                      >
                        {i.name}
                      </span>
                    ),
                  },
                  {
                    renderCol: (file, index) => (
                      <Stack spacing={2} direction='row'>
                        {/* <UIActionButton type='edit' /> */}
                        <UIActionButton type='delete' onClick={() => onFileDeleteHandler(file, index)} />
                      </Stack>
                    ),
                    noWrap: true,
                  },
                ]}
              />
            )}
          </div>
        </div>
        <div className='create-schedule__right'>
          <UITabs
            tabs={[
              {
                label: 'Workers',
                tab: 'workers',
                component: (
                  <WorkersTable
                    data={workers}
                    removeHandler={removeWorker}
                    onChangeChatAccess={onChangeChatAccess}
                    showCrewsModal={addCrewModalRef.current?.show}
                    showWorkersModal={addWorkerModalRef.current?.show}
                    onChangeWorkerTime={onChangeWorkerTime}
                    startTime={{ time: scheduleDate, isValid: validScheduleDate }}
                  />
                ),
              },
              {
                label: 'Supervisors',
                tab: 'supervisors',
                component: (
                  <SupervisorsTable
                    data={superVisors}
                    removeHandler={removeSupervisor}
                    showSuperVisorsModal={() =>
                      addSupervisorsRef.current?.show(
                        company,
                        superVisors.map((s) => s.unique)
                      )
                    }
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
      <AddWorkerModal
        ref={addWorkerModalRef}
        onChange={onAddWorkersHandler}
        workers={workers}
        scheduleDate={scheduleDate!}
      />
      <AddScheduleCrewModal ref={addCrewModalRef} onChange={onAddWorkersHandler} workers={workers} />
      <AddSupervisorModal ref={addSupervisorsRef} onSubmit={addSupervisorsHandler} />
      <AddProjectModal
        ref={addProjectsRef}
        getData={() => dispatch(getFProjects({ reset: true }))}
        successCallback={(id) => setSearchParams({ projectId: String(id) })}
      />
    </div>
  );
};

export default CreateSchedulePage;
