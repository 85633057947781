import { Stack } from '@mui/material';
import { StaffService } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useRef, useState } from 'react';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import { ModalBaseRef } from 'shared/Modals/types';
import Status from 'shared/ui/Status/Status';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import UITable from 'shared/ui/UITable/UITable';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as PlaceholderProfileIcon } from 'assets/icons/table/profile-2user.svg';

import { formatPhone } from 'app/helpers/formatPhone';
import history from 'app/routes/history';
import _ from 'lodash';
import { getLicenseColor } from 'pages/LicenseDetailsPage/helpers';
import qs from 'qs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import EditEmployeeModal from 'shared/Modals/EditEmployeeModal/EditEmployeeModal';
import { EditEmployeeModalRef } from 'shared/Modals/EditEmployeeModal/types';
import EmployeeInviteModal from 'shared/Modals/EmployeeInviteModal/EmployeeInviteModal';
import { EmployeeInviteModalRef } from 'shared/Modals/EmployeeInviteModal/types';
import { getEmployee } from 'store/slices/staff';
import EmployeesFilterModal from './components/EmployeesFilterModal/EmployeesFilterModal';
import { EmployeesFilterModalRef } from './components/EmployeesFilterModal/types';
import './Employees.sass';
import { colorizeStatusEmployees } from './helpers';

const limit = 12;

interface SubmitData {
  search: string;
}

const Employees: React.FC = () => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, setValue } = useForm<SubmitData>();
  const location = useLocation();

  const { employees, employeesFilters, employeesLoading } = useAppSelector((state) => state.staff);
  const { webSpicialities } = useAppSelector((state) => state.utils);

  const confirmModalRef = useRef<ModalBaseRef>(null);
  const employeeInviteModal = useRef<EmployeeInviteModalRef>(null);
  const editEmployeeModalRef = useRef<EditEmployeeModalRef>(null);
  const employeesFilterModalRef = useRef<EmployeesFilterModalRef>(null);
  const [loading, setLoading] = useState(false);
  const [countOfFilters, setCountOfFilters] = useState(0);

  useEffect(() => {
    if (!employeesFilters) return;

    setCountOfFilters(Object.keys(_.omit(employeesFilters, ['page', 'search', 'tab', 'pageSize'])).length);
  }, [location.search]);

  useEffect(() => {
    const { search } = qs.parse(location.search, { ignoreQueryPrefix: true });
    setValue('search', (search as string) ?? '');
  }, []);

  const deleteEmployee = (id: number) => {
    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);
        await StaffService.deleteEmployee(id);

        employeesFilterModalRef.current?.refreshPage();
      } finally {
        setLoading(false);
        confirmModalRef.current?.hide();
      }
    });
  };

  const editEmployee = async (unique: number) => {
    await dispatch(getEmployee(unique));

    editEmployeeModalRef.current?.show();
  };

  const submit: SubmitHandler<SubmitData> = ({ search }) => {
    employeesFilterModalRef.current?.onChange({ search, page: 1 });
  };

  const renderSpiecialities = (uniques: number[]): string => {
    return webSpicialities
      .filter((speciality) => uniques.includes(speciality.unique))
      .map((s) => s.name)
      .join(', ');
  };

  return (
    <>
      <UITable
        onRowClick={(i) => history.push(`/employees/${i.unique}`)}
        className='employees__table'
        withTabs
        totalPages={employees.totalPages}
        title={{
          title: 'List of Employees',
          button: {
            text: 'Invite',
            type: 'button',
            icon: 'plus',
            onClick: () => employeeInviteModal.current?.show(),
          },
          children: (
            <>
              <FormTextInput
                control={control}
                name='search'
                placeholder='Search by name or phone'
                InputProps={{
                  endAdornment: (
                    <button className='find-button' type='submit' onClick={handleSubmit(submit)}>
                      <SearchIcon />
                    </button>
                  ),
                  sx: { background: 'white', width: '304px' },
                }}
              />
              <UIButton
                count={countOfFilters}
                type='button'
                text='Filter'
                icon='filter'
                color='white'
                onClick={() => employeesFilterModalRef.current?.show()}
              />
            </>
          ),
        }}
        loading={employeesLoading}
        data={employees.data}
        headers={[
          { label: 'Full Name' },
          { label: 'Type' },
          { label: 'Licenses' },
          { label: 'Specialities' },
          { label: 'Status' },
          { label: 'Actions' },
        ]}
        columns={[
          {
            renderCol: (props) => (
              <div>
                <div className='employees__name'>{props.fullName}</div>
                <a href={`tel:${props.phone}`} className='employees__phone'>
                  {formatPhone(props.phone)}
                </a>
              </div>
            ),
          },
          {
            renderCol: (props) => (
              <div className='employees__type-wrapper'>
                <div className='employees__type'>{props.type}</div>
                <Status text={props.company} color='secondary' />
              </div>
            ),
          },
          {
            renderCol: (staff) => {
              return (
                <div className='employees__licenses'>
                  {staff.licenses.map((license) => (
                    <Status
                      key={license.unique}
                      text={license.type?.shortName ?? license.number}
                      color={getLicenseColor(license)}
                    />
                  ))}
                </div>
              );
            },
          },
          {
            renderCol: ({ specialities }) => (
              <div className='employees__specialities'>{renderSpiecialities(specialities)}</div>
            ),
          },
          { renderCol: (props) => <Status {...colorizeStatusEmployees(props.status)} /> },
          {
            renderCol: ({ unique }) => {
              return (
                <Stack direction='row' spacing={2}>
                  <UIActionButton type='edit' onClick={() => editEmployee(unique)} />
                  <UIActionButton type='delete' onClick={() => deleteEmployee(unique)} />
                </Stack>
              );
            },
          },
        ]}
        pagination={{
          page: employeesFilters?.page ?? 1,
          count: employees.totalPages ?? 1,
          onChange: (_, value) => {
            employeesFilterModalRef.current?.onChange({ page: value });
          },
        }}
        placeholder={{
          icon: <PlaceholderProfileIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of employees is empty. It will be displayed here.',
        }}
      />
      <EmployeesFilterModal ref={employeesFilterModalRef} limit={limit} />
      <EmployeeInviteModal ref={employeeInviteModal} getData={employeesFilterModalRef.current?.refreshPage} />
      <EditEmployeeModal ref={editEmployeeModalRef} getData={employeesFilterModalRef.current?.refreshPage} />
      <ConfirmModal ref={confirmModalRef} title='Delete' submitBtnText='Confirm' loading={loading}>
        Do you really want to delete this employee? You will not be able to undo this.
      </ConfirmModal>
    </>
  );
};

export default Employees;
