import { Switch } from '@mui/material';
import { ScheduleSupervisorModel } from 'app/models/SchedulesModel';
import React, { useRef, useState } from 'react';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import { ModalBaseRef } from '../ConfirmDeleteModal/types';

interface Props {
  data: ScheduleSupervisorModel[];
  removeHandler: (i: number, removeCrew: boolean) => void;
  showSuperVisorsModal?: () => void;
}

const SupervisorsTable: React.FC<Props> = ({ data, removeHandler, showSuperVisorsModal }) => {
  const [checkbox] = useState([
    { value: 'one', label: 'Delete selected supervisor only' },
    { value: 'crew', label: 'Delete the whole crew' },
  ]);

  const confirmModalRef = useRef<ModalBaseRef>(null);

  const deleteWorker = (index: number) => {
    confirmModalRef.current?.show((data: typeof checkbox[0]) => {
      removeHandler(index, data.value === 'crew');

      confirmModalRef.current?.hide();
    });
  };

  return (
    <>
      <UITable
        withTabs
        data={data}
        headers={[{ label: 'Full Name' }, { label: 'Type' }, { label: 'Action' }]}
        columns={[
          { columnName: 'nameEmployee' },
          { columnName: 'type' },
          {
            renderCol: (_, index) => <UIActionButton type='delete' onClick={() => deleteWorker(index)} />,
            noWrap: true,
          },
        ]}
        title={{
          title: 'List of supervisors',
          button: {
            text: 'Add Supervisors',
            onClick: showSuperVisorsModal,
          },
        }}
      />
      <ConfirmDeleteModal
        data={checkbox}
        ref={confirmModalRef}
        title='Delete'
        text='Do you want to delete selected worker only or the whole crew? Please, select one option'
        submitBtnText='Confirm'
      />
    </>
  );
};

export default SupervisorsTable;
