import cn from 'app/helpers/cn';
import { FC } from 'react';
import { StatusProps } from './types';

import './Status.sass';

const Status: FC<StatusProps> = ({ color, text }) => {
  if (!text) return <></>;

  return (
    <div
      className={cn('ui-status', {
        [`ui-status--${color}`]: color,
      })}
    >
      {text}
    </div>
  );
};

export default Status;
