import React, { useEffect, useImperativeHandle, useState } from 'react';
import SubmitModalLayout from 'shared/Modals/SubmitModalLayout/SubmitModalLayout';
import UIModal from 'shared/ui/UIModal/UIModal';
import './EmployeesFilterModal.sass';
import { EmployeesFilterModalProps, EmployeesFilterModalRef } from './types';
import { FormControl, FormLabel } from '@mui/material';
import { GetEmployeesBody } from 'api/staff/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useFilters from 'app/hooks/useFilters';
import { CompanyModel } from 'app/models/CompanyModel';
import { CrewModel, WorkerTypesModel } from 'app/models/StaffModel';
import WebSpeciality from 'app/models/WebSpeciality';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import Status from 'shared/ui/Status/Status';
import { IStatus } from 'shared/ui/Status/types';
import UICheckbox from 'shared/ui/UICheckbox/UICheckbox';
import { getEmployees } from 'store/slices/staff';
import { colorizeStatusEmployees } from '../../helpers';
import EmployeesFilterParams from '../../types';
import FormCompaniesSelect from 'shared/Inputs/Filters/FormCompaniesSelect';
import { getFCompanies, getFCrews } from 'store/slices/filters';
import FormCrewSelect from 'shared/Inputs/Filters/FromCrewSelect';

const initialFilterParams: EmployeesFilterParams = {
  page: 1,
  companies: [],
  statuses: [],
  specialities: [],
  crew: undefined,
  type: undefined,
  search: '',
};

interface SubmitData {
  page?: number;
  statuses?: { label: string; value: string; color?: IStatus }[];
  specialities?: WebSpeciality[];
  crew?: CrewModel;
  type?: WorkerTypesModel | null;
  companies?: CompanyModel[];
  search?: string;
}

const EmployeesFilterModal: React.ForwardRefRenderFunction<EmployeesFilterModalRef, EmployeesFilterModalProps> = (
  { limit },
  ref
) => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, setValue, reset, watch } = useForm<SubmitData>();

  const [open, setOpen] = useState(false);
  const { workerTypes, statuses: statusesData } = useAppSelector((state) => state.staff);
  const { webSpicialities } = useAppSelector((state) => state.utils);
  const { companies, crews } = useAppSelector((state) => state.filters);
  const [statuses, setStatuses] = useState<{ label: string; value: string; color?: IStatus }[]>([]);

  const typeWatch = watch('type');

  useEffect(() => {
    if (typeWatch?.id !== 'worker') setValue('crew', undefined);
    if (typeWatch?.id !== 'clientSupervisor') setValue('companies', undefined);

    if (typeWatch?.id === 'clientSupervisor') {
      dispatch(getFCompanies({ reset: true }));
    }
    if (typeWatch?.id === 'worker') {
      dispatch(getFCrews({ reset: true }));
    }
  }, [typeWatch]);

  const { replaceUrl, onChangeFilter, getNonEmptyFilters, resetFilters, resetAndOnChangeFilter } = useFilters({
    initialFilterParams,
    limit,
    initialCallback: (params) => dispatch(getEmployees({ force: true, params: { ...params, pageSize: limit } })),
    replaceState: {
      deps: [companies.data.length, webSpicialities.length, statuses.length, crews.data.length, workerTypes?.length],
      page: (value) => {
        setValue('page', value ? +value : 1);
      },
      companies: (value) => {
        let valueToSet: CompanyModel[] = [];

        if (typeof value === 'object') {
          valueToSet = value
            ?.map((el) => companies.data?.find((com) => !!el && +el === com.unique))
            .filter((el) => !!el) as CompanyModel[];
        } else {
          const company = companies.data.find((el) => el.unique === +value);
          if (company) valueToSet.push(company);
        }

        setValue('companies', valueToSet);
      },
      specialities: (value) => {
        const valueToSet =
          typeof value === 'object'
            ? (value
                ?.map((el) => webSpicialities?.find((com) => !!el && +el === com.unique))
                .filter((el) => !!el) as WebSpeciality[])
            : ([webSpicialities.find((el) => el.unique === +value)] as WebSpeciality[]);

        setValue('specialities', valueToSet);
      },
      statuses: (value) => {
        const valueToSet =
          typeof value === 'object'
            ? value?.map((el) => statuses?.find((status) => el === status.value)).filter((el) => !!el) ?? []
            : [statuses.find((el) => el.value === value)];

        setValue('statuses', valueToSet as typeof statuses);
      },
      crew: (value) => {
        setValue('crew', crews.data.find((w) => w.unique === (value ? +value : 1)) ?? undefined);
      },
      type: (value) => {
        setValue('type', workerTypes?.find((w) => w.id === value) ?? undefined);
      },
      search: (value) => {
        setValue('search', value as string);
      },
    },
  });

  useImperativeHandle(ref, () => ({
    show: () => setOpen(true),
    hide: () => setOpen(false),
    onChange: (params: GetEmployeesBody) => {
      const filters = onChangeFilter(params);
      replaceUrl(filters);

      dispatch(getEmployees({ force: true, params: { ...getNonEmptyFilters(filters), pageSize: limit } }));
    },
    refreshPage: () => {
      dispatch(getEmployees({ force: true, params: { ...getNonEmptyFilters(), pageSize: limit } }));
    },
  }));

  useEffect(() => {
    setStatuses(
      statusesData?.map((s) => {
        const { text: label, color } = colorizeStatusEmployees(s.id);

        return {
          label,
          value: s.id,
          color,
        };
      }) ?? []
    );
  }, [statusesData]);

  const applyFilters: SubmitHandler<SubmitData> = (data) => {
    const submitData: GetEmployeesBody = {
      companies: data.companies?.map((c) => c.unique),
      crew: data.crew?.unique,
      specialities: data.specialities?.map((s) => s.unique),
      statuses: data.statuses?.map((s) => s.value),
      type: data.type?.id,
    };

    const filters = resetAndOnChangeFilter({
      page: 1,
      ...getNonEmptyFilters(submitData),
    });
    replaceUrl(filters);

    dispatch(getEmployees({ force: true, params: { ...getNonEmptyFilters(filters), pageSize: limit } }));
    setOpen(false);
  };

  const resetAppliedFilter = () => {
    const filters = resetFilters();
    replaceUrl(filters);

    reset({ page: 1, companies: [], crew: undefined, specialities: [], statuses: [], type: null });
    dispatch(getEmployees({ force: true, params: { ...getNonEmptyFilters(filters), pageSize: limit } }));

    setOpen(false);
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'filter-modal',
        paper: 'filter-modal__paper',
      }}
    >
      <SubmitModalLayout
        title='Filter'
        onClose={() => setOpen(false)}
        submitBtnText='Apply'
        clearFilter={resetAppliedFilter}
        onSubmit={handleSubmit(applyFilters)}
      >
        <div className='filter-modal__inputs'>
          {workerTypes && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Type</FormLabel>
              <FormSelectInput
                control={control}
                name='type'
                options={workerTypes}
                getOptionLabel={(option) => option.name}
              />
            </FormControl>
          )}

          {statuses && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Status</FormLabel>
              <FormSelectInput
                control={control}
                name='statuses'
                allCheckbox
                isOptionEqualToValue={(option, value) => option.value === value.value}
                multiple
                options={statuses}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} style={{ paddingLeft: 7 }}>
                      <UICheckbox style={{ marginRight: 8 }} checked={selected} />
                      {option.color ? <Status text={option.label} color={option.color} /> : option.label}
                    </li>
                  );
                }}
              />
            </FormControl>
          )}

          {webSpicialities && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Speciality</FormLabel>
              <FormSelectInput
                control={control}
                name='specialities'
                multiple
                options={webSpicialities}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.unique === value.unique}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} style={{ paddingLeft: 7 }} key={option.unique} data-value={option.unique}>
                      <UICheckbox style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  );
                }}
              />
            </FormControl>
          )}

          {typeWatch?.id === 'worker' && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Crew</FormLabel>
              <FormCrewSelect name='crew' control={control} />
            </FormControl>
          )}

          {typeWatch?.id === 'clientSupervisor' && companies.data && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Company</FormLabel>
              <FormCompaniesSelect control={control} name='companies' multiple />
            </FormControl>
          )}
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(EmployeesFilterModal);
