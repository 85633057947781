import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import SingleDetail from 'shared/ui/SingleDetail/SingleDetail';
import { getRateHistory, selectCompany, setCurrentRate } from 'store/slices/companies';

import { ReactComponent as BriefcaseIcon } from 'assets/icons/primary/briefcase.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';

import { Stack } from '@mui/material';
import { companiesService } from 'api';
import { formatDate } from 'app/helpers/formatDate';
import { RateModel } from 'app/models/CompanyModel';
import dayjs from 'dayjs';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import { ModalBaseRef } from 'shared/Modals/types';
import UpdateRateModal from 'shared/Modals/UpdateRateModal/UpdateRateModal';
import BackButton from 'shared/ui/BackButton/BackButton';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import './RateDetailsPage.sass';

const RateDatailsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id, rateId } = useParams() as { id: string; rateId: string };
  const { selectedCompany, currentRateHistory, currentRateHistoryLoading } = useAppSelector((state) => state.companies);

  const [loading, setLoading] = useState(false);
  const updateRateModalRef = useRef<ModalBaseRef>(null);
  const confirmModalRef = useRef<ModalBaseRef>(null);

  useEffect(() => {
    if (!id && !rateId) return;

    if (!selectedCompany) {
      dispatch(selectCompany({ unique: +id }));
    }

    dispatch(
      getRateHistory({
        force: true,
        body: {
          uniqueC: +id,
          uniqueS: +rateId,
        },
      })
    );
  }, [dispatch, id, rateId]);

  const editRate = (rate: RateModel) => {
    if (!selectedCompany) return;

    dispatch(setCurrentRate(rate)).then(() => {
      updateRateModalRef.current?.show(async (data: { unique: number; rate: number; startDate: string }) => {
        try {
          setLoading(true);

          await companiesService.editRate({
            unique: data.unique,
            rate: +data.rate,
            date: dayjs(data.startDate).format('DD/MM/YYYY'),
          });

          dispatch(getRateHistory({ force: true, body: { uniqueC: +id, uniqueS: +rateId } }));
          updateRateModalRef.current?.hide();
        } finally {
          setLoading(false);
        }
      });
    });
  };

  const deleteRate = (unique: number) => {
    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);

        await companiesService.deleteRate(unique);
        dispatch(getRateHistory({ force: true, body: { uniqueC: +id, uniqueS: +rateId } }));
      } finally {
        setLoading(false);
        confirmModalRef.current?.hide();
      }
    });
  };

  return (
    <div>
      <BackButton label="View Company's Rate History Details" />
      {selectedCompany && (
        <div className='company-details__details'>
          <SingleDetail
            title='Name'
            className='company-details__details--single-detail'
            icon={ProfileIcon}
            label={selectedCompany.name}
          />
          <SingleDetail
            title='Speciality'
            className='company-details__details--single-detail'
            icon={BriefcaseIcon}
            label={currentRateHistory?.[0]?.specialityName ?? ''}
          />
        </div>
      )}
      <UITable
        loading={currentRateHistoryLoading}
        data={currentRateHistory}
        headers={[{ label: 'Activation Date' }, { label: 'Rate ($ per hour)' }, { label: 'Actions' }]}
        columns={[
          { renderCol: (i) => formatDate({ date: i.date }) },
          { renderCol: (i) => `$${i.rate}` },
          {
            renderCol: (i) => (
              <Stack direction='row' spacing={2}>
                <UIActionButton
                  type='edit'
                  onClick={() => editRate({ date: i.date, name: '', unique: i.unqiue, rate: i.rate })}
                />
                <UIActionButton type='delete' onClick={() => deleteRate(i.unqiue)} />
              </Stack>
            ),
          },
        ]}
      />
      <UpdateRateModal title='Edit Rate' ref={updateRateModalRef} loading={loading} />
      <ConfirmModal ref={confirmModalRef} title='Delete' loading={loading}>
        Do you really want to delete this rate? You will not be able to undo this.
      </ConfirmModal>
    </div>
  );
};

export default RateDatailsPage;
