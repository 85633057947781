import instance from 'api/instance';
import {
  AddCompany,
  AddCompanyFile,
  AddCompanyFileData,
  DeleteCompany,
  DeleteCompanyFile,
  DeleteRate,
  EditCompany,
  EditCompanyFileData,
  EditRate,
  GetCompanies,
  GetCompanyById,
  GetCompanyFiles,
  GetRateHistory,
  UpdateRate,
} from './types';

export const getCompanies: GetCompanies = (body) => {
  return instance.post('/exec?action=get_companies', body);
};

export const addCompany: AddCompany = (body) => {
  return instance.post('/exec?action=add_company', body);
};

export const getCompanyById: GetCompanyById = (id) => {
  return instance.post('/exec?action=get_company', {
    unique: id,
  });
};

export const deleteCompany: DeleteCompany = (unique) => {
  return instance.post('/exec?action=delete_company', {
    unique,
  });
};

export const deleteCompanyFile: DeleteCompanyFile = (unique) => {
  return instance.post('/exec?action=delete_companyFile', {
    unique,
  });
};

export const editCompany: EditCompany = (body) => {
  return instance.post('/exec?action=edit_company', body);
};

export const getCompanyFiles: GetCompanyFiles = (body) => {
  return instance.post('/exec?action=getCompany_files', body);
};

export const addFileData: AddCompanyFileData = (body) => {
  return instance.post('/exec?action=add_companyFileData', body);
};

export const addFile: AddCompanyFile = (body) => {
  return instance.post('/exec?action=post_companyFile', body);
};

export const editFileData: EditCompanyFileData = (body) => {
  return instance.post('/exec?action=edit_companyFileData', body);
};

export const updateRate: UpdateRate = (body) => {
  return instance.post('/exec?action=update_rate', body);
};

export const editRate: EditRate = (body) => {
  return instance.post('/exec?action=edit_rate', body);
};

export const getRateHistory: GetRateHistory = (body) => {
  return instance.post('/exec?action=get_rateHistory', body);
};

export const deleteRate: DeleteRate = (unique) => {
  return instance.post(`/exec?action=delete_rate`, {
    unique,
  });
};
