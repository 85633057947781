import qs from 'app/helpers/qs';
import history from 'app/routes/history';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface IFilterParams {
  page?: number;
  [key: string]: any;
}

type IOnChangeFilter<T> = (
  data: Partial<{
    [key in keyof T]: any;
  }>
) => T;

type IGetAttrsProps<T = unknown> = Partial<{
  noPag: boolean;
  noLimit: boolean;
  filters: T;
}>;

interface Options<T> {
  initialFilterParams: T;
  limit?: number;
  limitName?: string;
  initialCallback?: (data: T) => void;
  replaceState?: Partial<
    {
      [key in keyof T]: (value: string | (string | null)[] | null) => void;
    } & { deps?: any[] }
  >;
}

export interface IUseFiltersProps<T = IFilterParams> {
  getAttrs: (data?: IGetAttrsProps<T>) => string;
  replaceUrl: (filters?: T) => void;
  setUrlArgsToState: () => T;
  filterParams: T;
  onChangeFilter: IOnChangeFilter<T>;
  resetAndOnChangeFilter: IOnChangeFilter<T>;
  sumbitHandler: () => void;
  resetFilters: () => T;
  getNonEmptyFilters: (filters?: T) => Partial<T>;
}

function useFilters<T extends IFilterParams = IFilterParams>(options: Options<T>): IUseFiltersProps<T> {
  const { initialFilterParams, limit = 20, limitName = 'pageLimit' } = options;
  const [filterParams, setFilterParams] = useState<T>(initialFilterParams);
  const { search, pathname: path } = useLocation();

  useEffect(() => {
    if (options.initialCallback) {
      const filters = setUrlArgsToState();

      options.initialCallback(getNonEmptyFilters(filters));
    }
  }, []);

  useEffect(() => {
    if (options?.replaceState) {
      // if (options.replaceState.deps && options.replaceState.deps.every((el) => !el)) return;
      for (let k in options.replaceState) {
        let setter = options.replaceState[k];
        const value = qs.parse(search)?.[k];
        if (setter && value !== undefined) {
          setter(value);
        }
      }
    }
  }, [search, ...(options.replaceState?.deps ?? [])]);

  const getAttrs = (args?: IGetAttrsProps<T>) => {
    let noLimit;
    let noPag;
    let filters;
    if (args) {
      noLimit = args.noLimit;
      noPag = args.noPag;
      filters = args.filters;
    }
    const params = filters ?? filterParams;
    let attrs = '';
    if (limit && !noLimit) {
      attrs = `${[limitName ?? 'limit']}=${limit}&`;
    }

    if (!noPag && typeof params.page !== 'undefined') attrs += `&page=${params.page + 1}&`;

    const parsedObj = {};

    for (let key in params) {
      const item: any = params[key];
      if (item instanceof Date) {
        _.set(parsedObj, key, dayjs(item).format('YYYY-MM-DD'));
      } else {
        _.set(parsedObj, key, item);
      }
    }

    const attrsObject = _.omit(parsedObj, ['page']);

    const attrsQs = qs.stringify(_.pickBy(attrsObject, _.identity));
    attrs += `${attrsQs}`;
    return attrs;
  };

  const replaceUrl = (filters?: T) => {
    const filteredParams = filters ?? _.pickBy(filterParams, _.identity);

    let pickedQuery = '';

    const filterKeys = Object.keys(filters as {});
    const parsedSearch = qs.parse(search);
    const nonFilterQueryKeys = Object.keys(parsedSearch).filter((el) => !filterKeys.includes(el));
    pickedQuery = qs.stringify(_.pick(qs.parse(search), nonFilterQueryKeys));

    let urlParams = qs.stringify(getNonEmptyFilters(filteredParams as T));
    history.replace(`${path}?${pickedQuery}${(pickedQuery ? '&' : '') + urlParams}`);
  };

  const setUrlArgsToState = (): T => {
    const parsedArgs = qs.parse(search);

    const obj = { ...initialFilterParams };
    for (let key in obj) {
      let value: any = parsedArgs[key];
      if (!value) continue;
      let parsedValue: any;
      switch (key) {
        case 'page':
          _.set(obj, key, Number(value || 1));
          break;
        case 'project':
        case 'crew':
        case 'worker':
        case 'unique':
        case 'supervisor':
          _.set(obj, key, value ? Number(value) : null);
          break;
        case 'statuses':
        case 'stages':
          _.set(
            obj,
            key,
            [value].flat().map((v) => v)
          );
          break;
        case 'companies':
        case 'projects':
        case 'specialities':
          _.set(
            obj,
            key,
            [value].flat().map((v) => Number(v))
          );
          break;
        case 'dateFrom':
        case 'dateTo':
          parsedValue = new Date(value);
          if (parsedValue instanceof Date) {
            _.set(obj, key, parsedValue);
          } else {
            _.set(obj, key, null);
          }
          break;
        case 'accident':
          _.set(obj, key, JSON.parse(value));
          break;
        default:
          obj[key] = value;
      }
    }
    setFilterParams(obj);
    return obj;
  };

  const getNonEmptyFilters = (filters: T = filterParams) => {
    const nonEmptyFilters: T = {} as T;
    for (let key in filters) {
      const item = filters[key];
      if (typeof item === 'number' || typeof item === 'boolean') {
        nonEmptyFilters[key] = item;
        continue;
      }
      if (_.isEmpty(item)) {
        continue;
      }
      nonEmptyFilters[key] = item;
    }
    return nonEmptyFilters;
  };

  const onChangeFilter: IOnChangeFilter<T> = (obj) => {
    const params = {
      ...filterParams,
      ...obj,
    };

    setFilterParams(params);
    return params;
  };

  const resetAndOnChangeFilter: IOnChangeFilter<T> = (obj) => {
    const params = {
      ...initialFilterParams,
      ...obj,
    };

    setFilterParams(params);
    return params;
  };

  const sumbitHandler = () => {
    onChangeFilter(filterParams);
  };

  const resetFilters = (): T => {
    setFilterParams(initialFilterParams);
    return options.initialFilterParams;
    // replaceUrl();
  };

  return {
    getAttrs,
    replaceUrl,
    setUrlArgsToState,
    filterParams,
    onChangeFilter,
    sumbitHandler,
    resetFilters,
    getNonEmptyFilters,
    resetAndOnChangeFilter,
  };
}

export default useFilters;
