import GoogleMapReact from 'google-map-react';
import React, { useImperativeHandle, useState } from 'react';
import UIModal from 'shared/ui/UIModal/UIModal';
import { MapModalProps, MapModalRef } from './types';

import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import './MapModal.sass';

import { getAddress } from 'api/utils';
import { ReactComponent as MapLocationIcon } from 'assets/icons/primary/map-location.svg';
import _ from 'lodash';
import { utilsService } from 'api';
import PredictionModel from 'app/models/PredictionModel';
import UISelect from 'shared/ui/UISelect/UISelect';

const MapModal: React.ForwardRefRenderFunction<MapModalRef, MapModalProps> = ({ onSubmit }, ref) => {
  const [predictions, setPredictions] = useState<PredictionModel[]>([]);
  const [predictionsLoading, setPredictionsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);

  const [center, setCenter] = useState({
    lat: 40.73061,
    lng: -73.935242,
  });
  const [description, setDescription] = useState('');

  useImperativeHandle(
    ref,
    () => ({
      show: (address) => {
        if (address) {
          setCenter({
            lat: address.latitude,
            lng: address.longitude,
          });
          setDescription(address.description);
        }

        setOpen(true);
      },
      hide: () => setOpen(false),
    }),
    []
  );

  const getAddressHandle = async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
    try {
      setMapLoading(true);
      setCenter({ lat: latitude, lng: longitude });

      const { data } = await getAddress({ longitude, latitude });
      setDescription(data.data.description);
    } finally {
      setMapLoading(false);
    }
  };

  const handleConfirm = () => {
    onSubmit({
      latitude: center.lat,
      longitude: center.lng,
      description: description,
    });

    setOpen(false);
  };

  const searchAddress = _.debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPredictions([]);
    if (!value) return;

    try {
      setPredictionsLoading(true);
      console.log('value: ', value);
      const response = await utilsService.getPlaceAutocomplete({ input: value });

      // if(response.data)
      setPredictions([...response.data.predictions]);
    } catch (err) {
      console.log('err: ', err);
    } finally {
      setPredictionsLoading(false);
    }
  }, 300);

  const onSelectLocation = (value: PredictionModel | null) => {
    if (!value) return;

    setCenter({ lat: value.location.latitude, lng: value?.location.longitude });
    setDescription(value.description);
  };

  const fetchUserLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setCenter({ lat: latitude, lng: longitude });
              // Optionally, fetch the address description
              getAddressHandle({ latitude, longitude });
            },
            () => {
              // If the user denies the location request
              alert('Location permission was denied. Please enable it in your browser settings to use this feature.');
            }
          );
        } else if (result.state === 'denied') {
          // Inform the user they need to enable location services manually
          alert('Location permission was denied. Please enable it in your browser settings to use this feature.');
        }
      });
    } else {
      // Geolocation is not supported by this browser
      console.error('Geolocation is not supported by your browser.');
    }
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'map-modal',
        paper: 'map-modal__paper',
      }}
    >
      <div style={{ width: '1205px', height: '635px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyArvNHmkKvB4iiDCUmdrlqysdzIaxKwBg8' }}
          defaultCenter={center}
          defaultZoom={11}
          center={center}
          options={{ fullscreenControl: false, streetViewControl: false }}
          onClick={({ lat, lng }) => getAddressHandle({ latitude: lat, longitude: lng })}
        >
          <MapLocationIcon
            // @ts-ignore
            lat={center.lat}
            lng={center.lng}
            style={{
              position: 'absolute',
              width: 48,
              height: 55,
              left: -48 / 2,
              top: -55 / 2,
            }}
          />
        </GoogleMapReact>
      </div>
      <UIActionButton type='close' onClick={() => setOpen(false)} classes={{ root: 'map-modal__close' }} />
      <div className='map-modal__search'>
        <UISelect
          options={[...predictions]}
          loading={predictionsLoading}
          getOptionLabel={(i) => i.description}
          placeholder='Search...'
          InputProps={{
            onChange: searchAddress,
          }}
          onChange={(_, value) => onSelectLocation(value)}
          className='map-modal__search-input'
        />
      </div>
      <div className='map-modal__content'>
        <h2 className='map-modal__address'>{description}</h2>
        <div className='map-modal__wrapper'>
          <UIButton
            text='Use My Location'
            color='secondary'
            className='map-modal__use-my-location'
            onClick={fetchUserLocation}
            disabled={mapLoading}
            loading={mapLoading}
          />
          <UIButton
            text='Confirm Address'
            color='primary'
            className='map-modal__confirm'
            onClick={handleConfirm}
            disabled={!description || mapLoading}
          />
        </div>
      </div>
    </UIModal>
  );
};

export default React.forwardRef(MapModal);
