import { createTheme } from '@mui/material';
import COLORS from 'app/constants/COLORS';
import { ReactComponent as RadioChecked } from 'assets/icons/radio-checked.svg';
import { ReactComponent as RadioUnChecked } from 'assets/icons/radio-unchecked.svg';

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          font: '1.4rem Manrope-Medium',
          color: '#8080A6',
          marginBottom: '8px',
        },
        asterisk: {
          color: '#FF4B4B',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: '8px',
          padding: '0 10px',
          height: '45px',
        },
        root: {
          border: `1px solid ${COLORS.GRAY}`,
          font: '1.5rem Manrope-Medium',
          padding: '0',
          borderRadius: '11px',
          '&.Mui-error': {
            borderColor: COLORS.DANGER,
          },
          '& .MuiInputBase-inputMultiline': {
            height: '100px !important',
            padding: '5px 10px',
          },
          '& .MuiInputAdornment-root .MuiButtonBase-root': {
            marginRight: '8px',
          },
          '&.Mui-disabled': {
            background: COLORS.LIGHT,
            borderColor: COLORS.DARK_GRAY,
          },
        },
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiCheckbox-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        label: {
          font: '1.6rem Manrope-Medium',
          userSelect: 'none',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          font: '1.3rem Manrope-Medium',
          color: COLORS.DANGER,
          padding: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '& .MuiCalendarPicker-root': {
            '& .PrivatePickersFadeTransitionGroup-root, & .PrivatePickersYear-yearButton, & .MuiTypography-caption': {
              font: '1.6rem Manrope-Medium',
            },
            '& .MuiPickersDay-root.Mui-selected, & .PrivatePickersYear-yearButton.Mui-selected': {
              background: COLORS.PRIMARY,
            },
            '& .MuiPickersArrowSwitcher-button svg': {
              fontSize: '2.5rem',
            },
            '& .MuiPickersDay-today': {
              borderColor: 'transparent',
            },
            '& .MuiPickersDay-root, & .MuiTypography-caption': {
              width: '40px',
              height: '40px',
              font: '1.6rem Manrope-Medium',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '51px',
          height: '31px',
          padding: 0,
        },
        track: {
          backgroundColor: COLORS.GRAY,
          borderRadius: '50px',
        },
        thumb: {
          width: '27px',
          height: '27px',
          boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)',
        },
        switchBase: {
          padding: '2px',
          '&.Mui-checked': {
            color: '#FFFFFF',
          },
          '&.Mui-checked+.MuiSwitch-track': {
            backgroundColor: COLORS.PRIMARY,
            opacity: 1,
          },
          '&.Mui-checked.Mui-disabled .MuiSwitch-thumb': {
            color: '#fff',
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
        icon: <RadioUnChecked />,
        checkedIcon: <RadioChecked />,
      },
      styleOverrides: {
        checked: {},
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          font: '1.4rem Manrope-Medium',
          color: '#fff',
          // background: COLORS.DARK_GRAY,
          // borderRadius: '8px',
          // padding: '8px 16px',
          // boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)',
        },
      },
    },
  },
});

export default theme;
