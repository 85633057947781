import dayjs from 'dayjs';

type Props = {
  date: string;
  format?: string;
  parse?: string;
};

export const formatDate = ({ date, format = 'DD MMM, YYYY', parse = 'DD/MM/YYYY' }: Props) => {
  return dayjs(date, parse).format(format);
};

export const formatTime = (time: string, format = 'HH:mm', parse = 'hh:mm A'): string => {
  return dayjs(time, parse).format(format);
};

export const getDateFromTime = (time: string, parse = 'hh:mm A'): Date => {
  return dayjs(time, parse).toDate();
};
export const addMinutesToTime = (
  time: string,
  minutesToAdd: number,
  format = 'hh:mm A'
): string => {
  const date = dayjs(time, format, true); // Use strict parsing with the `true` flag

  if (!date.isValid()) {
    // If the date is invalid, return an empty string
    return '';
  }

  // Add the specified minutes to the valid date
  return date.add(minutesToAdd, 'minute').format(format);
};

export const getTimeAM_PM = (value: Date) => {
  if (dayjs(value).isValid()) {
    const [time, timeFormat] = dayjs(value).format('hh:mm A').trim().split(' ');

    return {
      time,
      timeFormat,
    };
  } else {
    return {
      time: null,
      timeFormat: null,
    };
  }
};
