import { companiesService } from 'api';
import { formatDate } from 'app/helpers/formatDate';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RateModel } from 'app/models/CompanyModel';
import history from 'app/routes/history';
import { ReactComponent as PlaceholderMoneysIcon } from 'assets/icons/table/moneys.svg';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { ModalBaseRef } from 'shared/Modals/types';
import UpdateRateModal from 'shared/Modals/UpdateRateModal/UpdateRateModal';
import UIButton from 'shared/ui/UIButton/UIButton';
import UITable from 'shared/ui/UITable/UITable';
import { selectCompany, setCurrentRate } from 'store/slices/companies';

import './RateDetails.sass';

const RateDetails = () => {
  const dispatch = useAppDispatch();
  const { selectedCompany } = useAppSelector((state) => state.companies);

  const updateRateModalRef = useRef<ModalBaseRef>(null);
  const [loading, setLoading] = useState(false);

  const updateRate = (rate: RateModel) => {
    if (!selectedCompany) return;

    dispatch(setCurrentRate(rate)).then(() => {
      updateRateModalRef.current?.show(async (data: { unique: number; rate: number; startDate: string }) => {
        try {
          setLoading(true);

          await companiesService.updateRate({
            uniqueC: selectedCompany.unique,
            uniqueS: data.unique,
            rate: +data.rate,
            date: dayjs(data.startDate).format('DD/MM/YYYY'),
          });

          dispatch(selectCompany({ unique: selectedCompany.unique }));
          updateRateModalRef.current?.hide();
        } finally {
          setLoading(false);
        }
      });
    });
  };

  return (
    <>
      <UITable
        className='rate-details__table'
        data={selectedCompany?.rates}
        onRowClick={(i) => selectedCompany && history.push(`/companies/${selectedCompany.unique}/rates/${i.unique}`)}
        headers={[{ label: 'Speciality' }, { label: 'Date' }, { label: 'Rate ($ per hour)' }, { label: 'Actions' }]}
        columns={[
          { columnName: 'name' },
          { renderCol: (i) => formatDate({ date: i.date, format: 'DD-MM-YYYY' }) },
          { renderCol: (i) => `$${i.rate}` },
          {
            renderCol: (i) => {
              return (
                <UIButton
                  className='rate-details__update-rate'
                  text='Update Rate'
                  color='white'
                  onClick={() => updateRate(i)}
                />
              );
            },
          },
        ]}
        placeholder={{
          icon: <PlaceholderMoneysIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of rates is empty yet. It will be displayed here.',
        }}
      />
      <UpdateRateModal title='Update Rate' ref={updateRateModalRef} loading={loading} />
    </>
  );
};

export default RateDetails;
