import { Controller, Path, PathValue } from 'react-hook-form';
import { UITimePickerProps } from 'shared/ui/UITimePicker/types';
import UITimePicker, { timeRegex } from 'shared/ui/UITimePicker/UITimePicker';
import BaseFormInputProps from '../types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
function FormTimePickerInput<T>({
  name,
  control,
  rules,
  onChange: onDateChange,
  defaultValue,
  ...rest
}: BaseFormInputProps<T> & UITimePickerProps & { defaultValue?: PathValue<T, Path<T>> }) {
  return (
    <Controller
      rules={{
        ...rules,
        validate: (val) => {
          const date = dayjs(val as string).format('hh:mm A');
          return !timeRegex.test(date) ? 'Invalid time format' : true;
          // const date = dayjs(val as string).format('HH:mm A');
          // return !timeRegex.test(date) ? 'Invalid time format' : true;
        },
      }}
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, formState: { errors } }) => {
        return (
          <UITimePicker
            {...rest}
            onBlur={onBlur}
            value={(value as Date) ?? null}
            onChange={(date) => {
              onChange(date);
              onDateChange?.(date);
            }}
            invalid={!!errors[name]?.message}
            helperText={(errors[name]?.message ?? '') as string}
          />
        );
      }}
    />
  );
}

export default FormTimePickerInput;
