import { FormControl, FormLabel, Stack } from '@mui/material';
import { StaffService } from 'api';
import { CrewModel, EmployeesModel } from 'app/models/StaffModel';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import './AddCrewModal.sass';
import { AddCrewModalProps, AddCrewModalRef } from './types';
import UIButton from 'shared/ui/UIButton/UIButton';
import AddMemberModal from '../AddMemberModal/AddMemeberModal';
import { AddMemberModalRef } from '../AddMemberModal/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';

interface SubmitData {
  unique?: number;
  name: string;
  crewMembers: EmployeesModel[];
}

const AddCrew: React.ForwardRefRenderFunction<AddCrewModalRef, AddCrewModalProps> = ({ getData }, ref) => {
  const { control, handleSubmit, setValue, reset } = useForm<SubmitData>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const [currentCrew, setCurrentCrew] = useState<CrewModel | null>(null);
  const [members, setMembers] = useState<EmployeesModel[]>([]);

  const addMemberModalRef = useRef<AddMemberModalRef>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: (data) => {
        setCurrentCrew(null);
        setMembers([]);

        if (data) {
          setCurrentCrew(data);
        }

        setUpdate(data ? true : false);
        setOpen(true);
      },
      hide: () => {
        setCurrentCrew(null);
        setOpen(false);
      },
    }),
    []
  );

  useEffect(() => {
    if (currentCrew?.name) {
      setValue('name', currentCrew.name);
      setValue('unique', currentCrew.unique);
    } else {
      reset();
    }
  }, [currentCrew, reset, setValue]);

  const addCrew: SubmitHandler<SubmitData> = async ({ name, unique }) => {
    try {
      setLoading(true);

      const crewMembers = members.map((m) => m.unique);

      if (update && unique) {
        await StaffService.editCrew({ name, unique });
      } else {
        await StaffService.addCrew({ name, crewMembers });
      }

      getData();
      setOpen(false);

      // @ts-ignore
      reset({ name: '', unique: null });
    } finally {
      setLoading(false);
    }
  };

  const addMembers = (employees: EmployeesModel[]) => {
    setMembers(employees);

    addMemberModalRef.current?.hide();
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'add-crew-modal',
        paper: 'add-crew-modal__paper',
      }}
    >
      <SubmitModalLayout
        onSubmit={handleSubmit(addCrew)}
        title={update ? 'Edit Crew' : 'Add Crew'}
        onClose={() => setOpen(false)}
        submitBtnText={update ? 'Edit' : 'Send'}
        loading={loading}
        rightComponent={
          !currentCrew && (
            <UIButton
              type='button'
              color='success'
              text='Add Member'
              onClick={() => addMemberModalRef.current?.show(members)}
            />
          )
        }
      >
        <div className='add-crew-modal__inputs'>
          <FormControl className='add-crew-modal__row'>
            <FormLabel required>Name</FormLabel>
            <FormTextInput name='name' control={control} rules={{ required: 'Name required' }} />
          </FormControl>
          {!!members.length && (
            <div>
              <FormLabel required>Members</FormLabel>
              <Stack direction='column' gap='8px' marginTop='8px'>
                {members.map((member) => (
                  <Stack direction='row' justifyContent='space-between' alignItems='center' key={member.unique}>
                    <a
                      href={`/employees/${member.unique}`}
                      target='_blank'
                      className='add-crew-modal__member-name'
                      rel='noreferrer'
                    >
                      {member.firstName}
                    </a>
                    <UIActionButton
                      type='delete'
                      onClick={(e) => {
                        e.stopPropagation();
                        setMembers(members.filter((i) => i.unique !== member.unique));
                      }}
                    />
                  </Stack>
                ))}
              </Stack>
            </div>
          )}
        </div>
      </SubmitModalLayout>
      <AddMemberModal ref={addMemberModalRef} onSubmit={addMembers} title='Add Member' />
    </UIModal>
  );
};

export default React.forwardRef(AddCrew);
