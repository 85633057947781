class HistoryManager {
  private static instance: HistoryManager;
  private readonly history: string[];

  private constructor() {
    const storedHistory = localStorage.getItem('history');
    if (storedHistory) {
      this.history = JSON.parse(storedHistory);
    } else {
      this.history = [];
    }
  }

  public static getInstance(): HistoryManager {
    if (!HistoryManager.instance) {
      HistoryManager.instance = new HistoryManager();
    }
    return HistoryManager.instance;
  }

  public addToHistory(url: string): void {
    const has = this.history.findIndex((i) => i === url);

    if (has === -1) {
      this.history.push(url);
    } else {
      this.history.splice(has, 1);
      this.history.push(url);
    }

    this.saveHistory();
  }

  public navigateBack(): string | undefined {
    if (this.history.length > 1) {
      this.history.pop();
      const url = this.history.pop();
      this.saveHistory();

      return url;
    }
  }

  public navigateTo(qry: string): string | undefined {
    let has = -1;
    for (let i = this.history.length - 1; i >= 0; i--) {
      if (this.history[i].includes(qry)) {
        has = i;
        break;
      }
    }
    if (has !== -1) {
      while (this.history.length - 1 > has) {
        this.history.pop();
      }
    }
    this.saveHistory();
    return this.history[has];
  }

  private saveHistory(): void {
    localStorage.setItem('history', JSON.stringify(this.history));
  }
}

export default HistoryManager;
