import { chatsService } from 'api';
import cn from 'app/helpers/cn';
import { formatDate } from 'app/helpers/formatDate';
import { getFileShortName } from 'app/helpers/getFileShortName';
import { useAppSelector } from 'app/hooks';
import { MessageModel } from 'app/models/ChatModel';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import DateDivider from '../DateDevider/DateDevider';

import { ReactComponent as CheckIcon } from 'assets/icons/chat/check.svg';
import { ReactComponent as CheckDoubleIcon } from 'assets/icons/chat/check-double.svg';
import { ReactComponent as PlaceholderFileDarkIcon } from 'assets/icons/chat/image-dark.svg';
import { ReactComponent as PlaceholderFileIcon } from 'assets/icons/chat/image-white.svg';
import { ReactComponent as TranslateIcon } from 'assets/icons/chat/translate.svg';
import { ReactComponent as UndoIcon } from 'assets/icons/chat/undo.svg';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';

export const Message: React.FC<{
  message: MessageModel;
  index: number;
  showPopover: (event: React.MouseEvent<HTMLDivElement>, message: MessageModel) => void;
  setType: (v: 'edit' | 'reply' | null) => void;
  setCurrentMessage: (message: MessageModel) => void;
}> = ({ message, index, showPopover, setCurrentMessage, setType }) => {
  const { chatHistory } = useAppSelector((state) => state.chats);

  const { text, files, own, unique, replyAuthor, replyText, replyTo, author } = message;
  const [translate, setTranslate] = useState('');
  const [showTranslate, setShowTranslate] = useState(false);

  const previewImageModalRef = useRef<PreviewImageModalRef>(null);

  const translateMessage = async () => {
    if (!translate.length) {
      const response = await chatsService.translateScheduleMessage(message.unique);
      setTranslate(response.data.data.translatedText);
    }

    setShowTranslate(!showTranslate);
  };

  const getDateTime = (date: string) => {
    const splitDate = date.split(' ');

    return {
      date: splitDate[0],
      time: splitDate[1],
    };
  };

  const downloadFile = (item: { uniqueF: number; name: string; extension: string }) => {
    previewImageModalRef.current?.show({ file: { name: item.name, unique: item.uniqueF, extension: item.extension } });
  };

  const prevMsg = chatHistory[index - 1];
  let groupDate = '';
  const dateTime = getDateTime(message.dateTime);

  if (prevMsg) {
    const prevMsgDate = getDateTime(prevMsg.dateTime);
    const diff = dayjs(formatDate({ date: dateTime.date, format: 'YYYY/MM/DD' })).diff(
      dayjs(formatDate({ date: prevMsgDate.date, format: 'YYYY/MM/DD' })),
      'day'
    );

    if (!!diff) {
      groupDate = formatDate({ date: dateTime.date });
    }
  } else {
    groupDate = formatDate({ date: dateTime.date });
  }

  return (
    <React.Fragment key={unique}>
      {groupDate && <DateDivider date={groupDate} />}
      <div
        className={cn('chat__message', {
          'chat__message--reverse': own,
        })}
      >
        <div className='chat__message-content' onDoubleClick={(e) => showPopover(e, message)}>
          {replyTo && (
            <div className='chat__message-reply'>
              <div className='chat__message-reply-title'>Reply to {replyAuthor}</div>
              <div className='chat__message-reply-text'>{replyText}</div>
            </div>
          )}
          {!own && <div className='chat__message-name'>{author}</div>}
          {!!files.length && (
            <div className='chat__message-file' onClick={() => downloadFile(files[0])}>
              {own ? <PlaceholderFileDarkIcon /> : <PlaceholderFileIcon />}
              {getFileShortName(files[0].name, 25)}
            </div>
          )}
          {text && <div className='chat__message-text'>{showTranslate ? translate : text}</div>}
          <div className='chat__message-sent'>
            {dateTime.time}
            {message.readBy.length > 1 ? <CheckDoubleIcon /> : <CheckIcon />}
          </div>
        </div>
        <div className='chat__message-buttons'>
          <div className='chat__message-button' onClick={() => translateMessage()}>
            <TranslateIcon />
          </div>
          <div
            className='chat__message-button'
            onClick={() => {
              setType('reply');
              setCurrentMessage(message);
            }}
          >
            <UndoIcon />
          </div>
        </div>
      </div>

      <PreviewImageModal ref={previewImageModalRef} />
    </React.Fragment>
  );
};
