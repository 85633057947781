import { IStatus } from 'shared/ui/Status/types';

export const colorizeStatusEmployees = (status: string): { color: IStatus; text: string } => {
  const data: { color: IStatus; text: string } = {
    color: 'primary',
    text: '',
  };

  switch (status) {
    case 'active':
    case 'Active':
      data.color = 'primary';
      data.text = 'active';
      break;
    case 'inactive':
    case 'Inactive':
      data.color = 'danger';
      data.text = 'inactive';
      break;
    case 'draft':
    case 'Draft':
      data.color = 'blue';
      data.text = 'invited';
      break;
    case 'needApproval':
    case 'Need approval':
      data.color = 'secondary';
      data.text = 'need approval';
      break;
    case 'needAttention':
    case 'Need attention':
      data.color = 'warning';
      data.text = 'need attention';
      break;
  }

  return data;
};
