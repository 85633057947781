import PayrollFillDataModel from 'app/models/PayrollFillDataModel';
import React, { useRef, useState } from 'react';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import { ModalBaseRef } from 'shared/Modals/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import UITable from 'shared/ui/UITable/UITable';
import './WorkersAndBills.sass';

import { ReactComponent as PlaceholderMoneysOpenIcon } from 'assets/icons/table/moneys.svg';

interface Props {
  data: PayrollFillDataModel[];
  loading: boolean;
  onClear: () => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  setBillsList: (data: PayrollFillDataModel[]) => void;
}

const WorkersAndBills: React.FC<Props> = ({ data, loading: tableLoading, onClear, onSubmit, setBillsList }) => {
  const [loading, setLoading] = useState(false);
  const confirmModalRef = useRef<ModalBaseRef>(null);

  const deleteBill = (unique: number) => {
    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);
        // await payrollsService.deleteBill(unique);

        setBillsList(data.filter((b) => b.unique !== unique));
      } finally {
        setLoading(false);
        confirmModalRef.current?.hide();
      }
    });
  };

  return (
    <div>
      <UITable
        withTabs
        loading={tableLoading}
        title={{
          title: 'List of Workers & Bills',
          onSubmit,
          button: {
            text: 'Save',
            color: 'primary',
          },
          children: <UIButton text='Clear List' color='secondary' onClick={onClear} />,
        }}
        data={data}
        headers={[
          { label: 'Full Name' },
          { label: 'Rate ($ per hour)' },
          { label: 'Total Hours' },
          { label: 'Total Sum' },
          { label: 'Actions', noWrap: true },
        ]}
        columns={[
          { columnName: 'name' },
          { columnName: 'rate' },
          { columnName: 'totalHours' },
          { columnName: 'totalSum' },
          {
            renderCol: (bill) => <UIActionButton type='delete' onClick={() => deleteBill(bill.unique)} />,
            noWrap: true,
          },
        ]}
        placeholder={{
          icon: <PlaceholderMoneysOpenIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of payroll is empty. It will be displayed here.',
        }}
      />
      <ConfirmModal ref={confirmModalRef} title='Delete' loading={loading}>
        Do you really want to delete this bill? You will not be able to undo this.
      </ConfirmModal>
    </div>
  );
};

export default WorkersAndBills;
