import { Stack } from '@mui/material';
import { StaffService } from 'api';
import { formatDate } from 'app/helpers/formatDate';
import { encodeBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddRenewalModal from 'shared/Modals/AddRenewalModal/AddRenewalModal';
import { AddRenewalModalRef } from 'shared/Modals/AddRenewalModal/types';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';
import { ModalBaseRef } from 'shared/Modals/types';
import Status from 'shared/ui/Status/Status';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import { getLicense, getWebLicenseStatuses } from 'store/slices/staff';
import { ReactComponent as PlaceholderRefreshIcon } from 'assets/icons/table/refresh.svg';
import './Renewals.sass';
import { getLicenseColor } from '../../helpers';

const Renewals: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { currentLicense } = useAppSelector((state) => state.staff);
  const [loading, setLoading] = useState(false);

  const confirmModalRef = useRef<ModalBaseRef>(null);
  const addRenewalModalRef = useRef<AddRenewalModalRef>(null);
  const previewImageModalRef = useRef<PreviewImageModalRef>(null);

  useEffect(() => {
    dispatch(getWebLicenseStatuses(true));
  }, [dispatch]);

  const deleteLicense = (unique: number) => {
    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);
        await StaffService.deleteLicense(unique);

        dispatch(getLicense(+id!));
        confirmModalRef.current?.hide();
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <UITable
        withTabs
        title={{
          title: 'List of Renewals',
          button: {
            text: 'Add Renewal',
            icon: 'plus',
            onClick: () => addRenewalModalRef.current?.show(),
          },
        }}
        totalPages={currentLicense?.extensions.length}
        data={currentLicense?.extensions}
        headers={[{ label: 'Photo' }, { label: 'Expiration Date' }, { label: 'Status' }, { label: 'Actions' }]}
        columns={[
          {
            renderCol: (i) => (
              <Stack direction='row' spacing='8px'>
                {i.images.map(({ image, extension, unique }) => {
                  const url = encodeBase64(image, extension);

                  return unique && image.length ? (
                    <img
                      onClick={(e) => {
                        e.stopPropagation();
                        previewImageModalRef.current?.show({
                          file: {
                            name: currentLicense?.number ? `${currentLicense?.number}-${i.unique}` : String(i.unique),
                            unique,
                            extension,
                          },
                        });
                      }}
                      className='license-details-page__number-img'
                      src={url}
                      alt=''
                    />
                  ) : (
                    <div className='license-details-page__number-img-placeholder'></div>
                  );
                })}
              </Stack>
            ),
          },
          { renderCol: (i) => formatDate({ date: i.expirationDate }) },
          {
            renderCol: (i) => <Status color={getLicenseColor({ expirationDate: i.expirationDate })} text={i.status} />,
          },
          {
            renderCol: (i) => {
              return (
                <Stack direction='row' spacing={2}>
                  <UIActionButton type='edit' onClick={() => addRenewalModalRef.current?.show(i)} />
                  <UIActionButton type='delete' onClick={() => deleteLicense(i.unique)} />
                </Stack>
              );
            },
          },
        ]}
        placeholder={{
          icon: <PlaceholderRefreshIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of renewals is empty. It will be displayed here.',
        }}
      />
      <AddRenewalModal ref={addRenewalModalRef} />
      <ConfirmModal ref={confirmModalRef} title='Delete' loading={loading}>
        Do you really want to delete this renewal? You will not be able to undo this.
      </ConfirmModal>
      <PreviewImageModal ref={previewImageModalRef} />
    </>
  );
};

export default Renewals;
