import React, { useEffect, useImperativeHandle, useState } from 'react';
import SubmitModalLayout from 'shared/Modals/SubmitModalLayout/SubmitModalLayout';
import UIModal from 'shared/ui/UIModal/UIModal';
import './ProjectSchedulesFilterModal.sass';
import { ProjectSchedulesFilterModalProps, ProjectSchedulesFilterModalRef } from './types';

import { FormControl, FormLabel, Stack } from '@mui/material';
import { GetProjectsBody } from 'api/projects/types';
import { formatDate } from 'app/helpers/formatDate';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useFilters from 'app/hooks/useFilters';
import dayjs from 'dayjs';
import _ from 'lodash';
import ProjectSchedulesFilterParams from 'pages/ProjectDetailsPage/components/SchedulesDetails/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormDateInput from 'shared/Inputs/FormDateInput/FormDateInput';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import Status from 'shared/ui/Status/Status';
import { IStatus } from 'shared/ui/Status/types';
import UICheckbox from 'shared/ui/UICheckbox/UICheckbox';
import { getProjectSchedules } from 'store/slices/projects';
import { colorizeStageSchedule } from 'pages/SchedulesPage/helpers';
import DATE_FORMAT from 'app/constants/DATE_FORMAT';

const initialFilterParams: ProjectSchedulesFilterParams = {
  page: 1,
  stages: [],
  startDate: dayjs(new Date()).subtract(1, 'month').format(DATE_FORMAT),
  endDate: dayjs(new Date()).format(DATE_FORMAT),
  accident: undefined,
  supervisor: undefined,
};

interface SubmitData {
  page?: number;
  supervisor?: { label: string; value: number };
  stages?: { label: string; value: string; color?: IStatus }[];
  startDate?: string;
  endDate?: string;
  accident?: { label: string; value: boolean } | null;
}

const accidentSelect = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const ProjectSchedulesFilterModal: React.ForwardRefRenderFunction<
  ProjectSchedulesFilterModalRef,
  ProjectSchedulesFilterModalProps
> = ({ limit, projectUnique }, ref) => {
  const dayjsFormat = 'DD/MM/YYYY';
  const { control, handleSubmit, setValue, reset, getValues } = useForm<SubmitData>();
  const { stages } = useAppSelector((state) => state.schedules);
  const { supervisors: projectSupervisors } = useAppSelector((state) => state.projects);

  const [open, setOpen] = useState(false);
  const [statuses, setStatuses] = useState<{ label: string; value: string; color?: IStatus }[]>([]);
  const [supervisors, setSupervisors] = useState<{ label: string; value: number }[]>([]);

  const dispatch = useAppDispatch();

  const { replaceUrl, onChangeFilter, getNonEmptyFilters, resetFilters, resetAndOnChangeFilter } = useFilters({
    initialFilterParams,
    limit,
    initialCallback: (params) => {
      dispatch(getProjectSchedules({ params: { ...params, pageSize: limit, unique: projectUnique } }));
      replaceUrl(params);
    },
    replaceState: {
      deps: [supervisors.length, statuses.length],
      page: (value) => {
        setValue('page', value ? +value : 1);
      },
      stages: (value) => {
        const valueToSet =
          typeof value === 'object'
            ? value?.map((el) => statuses?.find((status) => el === status.value)).filter((el) => !!el) ?? []
            : [statuses.find((el) => el.value === value)];

        setValue('stages', valueToSet as typeof statuses);
      },
      startDate: (value) => {
        setValue(
          'startDate',
          dayjs(value as string, dayjsFormat).isValid() ? `${dayjs(formatDate({ date: value as string }))}` : ''
        );
      },
      endDate: (value) => {
        setValue(
          'endDate',
          dayjs(value as string, dayjsFormat).isValid() ? `${dayjs(formatDate({ date: value as string }))}` : ''
        );
      },
      supervisor: (value) => {
        setValue(
          'supervisor',
          supervisors.find((s) => s.value === Number(value))
        );
      },
      accident: (value) => {
        setValue(
          'accident',
          accidentSelect.find((i) => i.value === JSON.parse(value as string))
        );
      },
    },
  });

  useEffect(() => {
    setStatuses(
      stages?.map((s) => ({
        label: s.name.toLowerCase(),
        value: s.id,
        color: colorizeStageSchedule(s.id).color,
      })) ?? []
    );
  }, [stages]);

  useEffect(() => {
    setSupervisors(
      projectSupervisors.map((s) => ({
        label: s.name,
        value: s.unique,
      }))
    );
  }, [projectSupervisors]);

  useImperativeHandle(ref, () => ({
    show: () => setOpen(true),
    hide: () => setOpen(false),
    onChange: (params: GetProjectsBody) => {
      const filters = onChangeFilter(params);
      replaceUrl(filters);

      dispatch(
        getProjectSchedules({ params: { ...getNonEmptyFilters(filters), pageSize: limit, unique: projectUnique } })
      );
    },
    refreshPage: () => {
      dispatch(getProjectSchedules({ params: { ...getNonEmptyFilters(), pageSize: limit, unique: projectUnique } }));
    },
  }));

  const applyFilters: SubmitHandler<SubmitData> = (data) => {
    const submitData = {
      stages: data.stages?.map((el) => el.value),
      startDate: data.startDate ? dayjs(data.startDate).format(dayjsFormat) : '',
      endDate: data.endDate ? dayjs(data.endDate).format(dayjsFormat) : '',
      accident: data.accident?.value,
      supervisor: data.supervisor?.value,
    };

    const filters = resetAndOnChangeFilter({
      page: 1,
      ..._.omitBy(submitData, _.isNull || _.isUndefined),
    });

    replaceUrl(filters);

    dispatch(
      getProjectSchedules({ params: { ...getNonEmptyFilters(filters), pageSize: limit, unique: projectUnique } })
    );

    setOpen(false);
  };

  const resetAppliedFilter = () => {
    const filters = resetFilters();
    replaceUrl(filters);

    // @ts-ignore
    reset({ page: 1, stages: [], startDate: '', endDate: '', supervisor: null });

    dispatch(
      getProjectSchedules({ params: { ...getNonEmptyFilters(filters), pageSize: limit, unique: projectUnique } })
    );

    setOpen(false);
  };

  const handleChangeStartDate = (date: Date | null) => {
    if (date === null) {
      setValue('startDate', dayjs(new Date()).format('DD MMM, YYYY'));
    } else {
      const endDate = getValues('endDate');

      if (dayjs(date).isAfter(endDate)) {
        setValue('endDate', dayjs(date).format('DD MMM, YYYY'));
      }
    }
  };

  const handleChangeEndDate = (date: Date | null) => {
    if (date === null) {
      setValue('endDate', dayjs(new Date()).format('DD MMM, YYYY'));
    } else {
      const startDate = getValues('startDate');

      if (dayjs(date).isBefore(startDate)) {
        setValue('startDate', dayjs(date).format('DD MMM, YYYY'));
      }
    }
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'filter-modal',
        paper: 'filter-modal__paper',
      }}
    >
      <SubmitModalLayout
        title='Filter'
        onClose={() => setOpen(false)}
        submitBtnText='Apply'
        clearFilter={resetAppliedFilter}
        onSubmit={handleSubmit(applyFilters)}
      >
        <div className='filter-modal__inputs'>
          <Stack direction='row' spacing={2}>
            <FormControl className='filter-modal__row'>
              <FormLabel>Start Date</FormLabel>
              <FormDateInput name='startDate' control={control} onChange={handleChangeStartDate} />
            </FormControl>
            <FormControl className='filter-modal__row'>
              <FormLabel>End Date</FormLabel>
              <FormDateInput name='endDate' control={control} onChange={handleChangeEndDate} />
            </FormControl>
          </Stack>
          {statuses && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Status</FormLabel>
              <FormSelectInput
                control={control}
                name='stages'
                allCheckbox
                isOptionEqualToValue={(option, value) => option.value === value.value}
                multiple
                options={statuses}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} style={{ paddingLeft: 7 }}>
                      <UICheckbox style={{ marginRight: 8 }} checked={selected} />
                      {option.color ? <Status text={option.label} color={option.color} /> : option.label}
                    </li>
                  );
                }}
              />
            </FormControl>
          )}
          {supervisors && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Supervisor</FormLabel>
              <FormSelectInput
                control={control}
                name='supervisor'
                options={supervisors}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} style={{ paddingLeft: 7 }}>
                      {option.label}
                    </li>
                  );
                }}
              />
            </FormControl>
          )}
          <FormControl className='filter-modal__row'>
            <FormLabel>Accidents</FormLabel>
            <FormSelectInput
              control={control}
              name='accident'
              options={accidentSelect}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} style={{ paddingLeft: 7 }}>
                    {option.label}
                  </li>
                );
              }}
            />
          </FormControl>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(ProjectSchedulesFilterModal);
