import { AxiosResponse } from 'axios';

export const downloadBlobFile = (
  res: AxiosResponse<string, any>,
  name: string = 'file',
  extension: string = 'txt',
  isDownload: boolean = false
) => {
  // @ts-ignore
  const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));

  const a = document.createElement('a');
  a.target = '_blank';
  a.href = url;
  if (isDownload) a.download = `${name}.${extension}`;
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
};
