import { useEffect, useRef, useState } from 'react';
import { ReactComponent as FolderOpenFilledIcon } from 'assets/icons/folder-open-filled.svg';
import { CircularProgress, Stack } from '@mui/material';
import UIButton from 'shared/ui/UIButton/UIButton';
import { DailyLogModel } from 'app/models/SchedulesModel';
import { useAppSelector } from 'app/hooks';
import { schedulesService } from 'api';
import './DailyLogs.sass';
import DailyLogModal from 'shared/Modals/DailyLogModal/DailyLogModal';
import { DailyLogModalRef } from 'shared/Modals/DailyLogModal/types';
import ReportsPlaceholder from '../Placeholder/Placeholder';
import { ReactComponent as CalendarIcon } from 'assets/icons/table/calendar.svg';

const DailyLogs = () => {
  const { currentSchedule } = useAppSelector((state) => state.schedules);

  const [dailyLogs, setDailyLogs] = useState<DailyLogModel[]>([]);
  const [loading, setLoading] = useState(false);

  const dailyLogModalRef = useRef<DailyLogModalRef>(null);

  useEffect(() => {
    if (!currentSchedule?.unique) return;

    getDailyLog(currentSchedule.unique);
  }, [currentSchedule]);

  const getDailyLog = async (unique: number) => {
    try {
      setLoading(true);

      const response = await schedulesService.getDailyLogs(unique);
      setDailyLogs(response.data.data);
    } finally {
      setLoading(false);
    }
  };

  if (!loading && !dailyLogs.length) {
    return (
      <ReportsPlaceholder
        icon={<CalendarIcon />}
        title='Sorry, it is empty here!'
        subtitle='The daily logs is empty. It will be displayed here.'
      />
    );
  }

  return (
    <>
      {loading ? (
        <div className='daily-report__loading'>
          <CircularProgress />
        </div>
      ) : (
        <Stack direction='column' spacing='8px'>
          {dailyLogs.map((dailyLog) => (
            <Stack
              className='daily-log__card'
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              key={dailyLog.unique}
            >
              <Stack direction='row' spacing='12px'>
                <div className='daily-log__card-icon'>
                  <FolderOpenFilledIcon />
                </div>
                <p className='daily-log__card-title'>Daily log {dailyLog.name}</p>
              </Stack>
              <UIButton
                text='View'
                type='button'
                color='secondary'
                onClick={() => {
                  dailyLogModalRef.current?.show(dailyLog.unique);
                }}
              />
            </Stack>
          ))}
        </Stack>
      )}

      <DailyLogModal ref={dailyLogModalRef} />
    </>
  );
};

export default DailyLogs;
