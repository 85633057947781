import { CachedDataModel } from 'app/models/CompanyModel';
import dayjs from 'dayjs';

async function getCachedData<T, U extends CachedDataModel<T, unknown>>(
  data: U | undefined,
  callback: () => Promise<T>,
  ignoreLastUpdateLimit = false,
  lastUpdateLimit: number = 3
) {
  if (data && data.data && data.lastUpdate && !ignoreLastUpdateLimit) {
    if (dayjs(data.lastUpdate).diff(new Date(), 'm') > lastUpdateLimit) {
      const returnData = await callback();
      return { ...data, data: returnData, lastUpdate: new Date() };
    } else {
      return data;
    }
  }
  const returnData = await callback();
  return { ...data, data: returnData, lastUpdate: new Date() };
}

export default getCachedData;
