import { FormControl, FormLabel } from '@mui/material';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { StaffService } from 'api';
import { encodeBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LicenseExtensionsModel, webLicenseStatusesModel } from 'app/models/StaffModel';
import dayjs from 'dayjs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import FormDateInput from 'shared/Inputs/FormDateInput/FormDateInput';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import { getLicense } from 'store/slices/staff';
import { AddRenewalModalProps, AddRenewalModalRef } from './types';
import './AddRenewalModal.sass';
import UploadFiles from '../AddLicenseModal/components/UploadFiles/UploadFiles';
import { UploadFileModel } from '../AddLicenseModal/components/UploadFiles/types';

interface SubmitData {
  unique: number;
  expirationDate: Date | null;
  statusId: webLicenseStatusesModel | null;
}

const AddLicenseModal: React.ForwardRefRenderFunction<AddRenewalModalRef, AddRenewalModalProps> = (_, ref) => {
  const dispatch = useAppDispatch();
  const { id } = useParams() as { id: string };

  const { control, handleSubmit, setValue, reset } = useForm<SubmitData>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const [currentExtension, setCurrentExtension] = useState<LicenseExtensionsModel | null>(null);

  const [files, setFiles] = useState<UploadFileModel[]>([]);
  const [deleteFilesId, setDeleteFilesId] = useState<number[]>([]);

  const { webLicenseTypes, webLicenseStatuses, currentLicense } = useAppSelector((state) => state.staff);

  useImperativeHandle(
    ref,
    () => ({
      show: (data) => {
        setDeleteFilesId([]);
        setCurrentExtension(null);
        if (data) {
          setCurrentExtension(data);

          if (data.images.length) {
            setFiles(
              data.images.map(({ unique, image, extension }) => ({
                base64: image,
                extension: extension,
                preview: encodeBase64(image, extension),
                unique: unique,
              }))
            );
          }
        }

        setUpdate(!!data);
        setOpen(true);
      },
      hide: () => {
        setCurrentExtension(null);
        setOpen(false);
      },
    }),
    []
  );

  useEffect(() => {
    if (!currentExtension) return clearFields();
    const { unique, expirationDate, status } = currentExtension;

    setValue('unique', unique);
    setValue('expirationDate', dayjs(expirationDate, 'DD/MM/YYYY').toDate());
    setValue('statusId', webLicenseStatuses?.find((w) => w.name === status) ?? null);
  }, [currentExtension, reset, setValue, webLicenseStatuses, webLicenseTypes]);

  const addLicense: SubmitHandler<SubmitData> = async (data) => {
    if (!currentLicense) return;
    if (!files.length) return alert('Please, choose Licence image');

    try {
      setLoading(true);

      const response = await StaffService.editLicense({
        unique: currentLicense.unique,
        number: currentLicense.number,
        statusId: webLicenseStatuses?.find((w) => w.name === currentLicense.status)?.id ?? '',
        type: currentLicense.type.id,
        expirationDate: currentLicense.expirationDate,
        issuedBy: currentLicense.issuedBy,
        extensions: [
          {
            statusId: data.statusId?.id!,
            expirationDate: dayjs(data.expirationDate).format('DD/MM/YYYY'),
          },
        ],
      });

      const extension = response.data.data.extensions[response.data.data.extensions.length - 1];

      if (files.length) {
        await StaffService.addLicenseFile({
          uniqueE: extension.unique,
          files: files.map(({ extension, base64 }) => ({
            file: base64.split('base64,')[1],
            extension: extension,
          })),
        });
      }

      setOpen(false);
      dispatch(getLicense(+id!));
      clearFields();
    } finally {
      setLoading(false);
    }
  };

  const editLicense: SubmitHandler<SubmitData> = async (data) => {
    if (!currentLicense) return;
    if (!files.length) return alert('Please, choose Licence image');

    try {
      setLoading(true);

      await StaffService.editLicense({
        unique: currentLicense.unique,
        number: currentLicense.number,
        statusId: webLicenseStatuses?.find((w) => w.name === currentLicense.status)?.id ?? '',
        type: currentLicense.type.id,
        expirationDate: currentLicense.expirationDate,
        issuedBy: currentLicense.issuedBy,
        extensions: [
          {
            unique: data.unique,
            statusId: data.statusId?.id!,
            expirationDate: dayjs(data.expirationDate).format('DD/MM/YYYY'),
          },
        ],
      });

      if (deleteFilesId.length) {
        await Promise.all(
          deleteFilesId.map(async (unique) => {
            return await StaffService.deleteLicenseFile(unique);
          })
        );
      }

      const newFiles = files.filter((f) => f.isFileUpdated);

      if (newFiles.length) {
        await StaffService.addLicenseFile({
          uniqueE: data.unique,
          files: newFiles.map(({ extension, base64 }) => ({
            file: base64.split('base64,')[1],
            extension: extension,
          })),
        });
      }

      setOpen(false);
      dispatch(getLicense(+id!));
      clearFields();
    } finally {
      setLoading(false);
    }
  };

  const clearFields = () => {
    reset();
    reset({ expirationDate: null, statusId: null });
    setFiles([]);
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'add-renewal-modal',
        paper: 'add-renewal-modal__paper',
      }}
    >
      <SubmitModalLayout
        onSubmit={update ? handleSubmit(editLicense) : handleSubmit(addLicense)}
        title={update ? 'Edit Renewal' : 'Add Renewal'}
        onClose={() => setOpen(false)}
        submitBtnText={update ? 'Edit' : 'Save'}
        loading={loading}
      >
        <div className='add-renewal-modal__inputs'>
          <UploadFiles
            files={files}
            setFiles={setFiles}
            deleteFilesId={deleteFilesId}
            setDeleteFilesId={setDeleteFilesId}
          />
          <FormControl className='add-renewal-modal__row'>
            <FormLabel required>Expiration Date</FormLabel>
            <FormDateInput
              value={null}
              name='expirationDate'
              control={control}
              rules={{ required: 'Required field' }}
            />
          </FormControl>

          <FormControl className='add-renewal-modal__row'>
            <FormLabel required>Status</FormLabel>
            <FormSelectInput
              name='statusId'
              control={control}
              options={webLicenseStatuses ?? []}
              getOptionLabel={(option) => option.name}
              rules={{
                required: 'Required field',
              }}
            />
          </FormControl>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(AddLicenseModal);
