import React, { FC } from 'react';
import cn from 'app/helpers/cn';
import './Placeholder.sass';

type Props = {
  className?: string;
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
};

const ReportsPlaceholder: FC<Props> = ({ className, icon, title, subtitle }) => {
  return (
    <div className={cn('reports-placeholder', className)}>
      <div className='reports-placeholder__icon-wrapper'>{icon}</div>
      <div className='reports-placeholder__title'>{title}</div>
      <div className='reports-placeholder__subtitle'>{subtitle}</div>
    </div>
  );
};

export default ReportsPlaceholder;
