import React from 'react';
import { Stack } from '@mui/material';
import { ReactComponent as FolderIcon } from 'assets/icons/folder.svg';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import { FILE_SIZE, FILE_TYPE_IMAGE } from 'app/constants/FILE_CONFIG';
import { toBase64 } from 'app/helpers/toBase64';
import { getFileExtension } from 'app/helpers/getFileShortName';
import { UploadFileModel } from './types';
import './UploadFiles.sass';

interface Props {
  files: UploadFileModel[];
  setFiles: (files: UploadFileModel[]) => void;
  deleteFilesId: number[];
  setDeleteFilesId: (ids: number[]) => void;
  title?: string;
  maxImages?: number | null;
}

const UploadFiles: React.FC<Props> = ({
  files,
  setFiles,
  title = 'Add License Photos',
  maxImages = 2,
  deleteFilesId,
  setDeleteFilesId,
}) => {
  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && (maxImages === null || files.length < maxImages)) {
      const file = e.target.files[0];
      const url = await toBase64(file);

      if (file.size > FILE_SIZE) {
        return alert('Max accessible size is 5Mb');
      }

      setFiles([
        ...files,
        {
          file: file,
          preview: URL.createObjectURL(file),
          base64: url,
          extension: getFileExtension(file.name),
          isFileUpdated: true,
        },
      ]);
    }
  };

  const handleDeleteImage = async (file: UploadFileModel, idx: number) => {
    if (file.unique) {
      setFiles(files.filter((i) => i.unique !== file.unique));
      setDeleteFilesId([...deleteFilesId, file.unique]);
    } else {
      const start = files.slice(0, idx);
      const end = files.splice(idx + 1);
      setFiles([...start, ...end]);
    }
  };

  return (
    <div className='upload-files'>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='row' spacing='8px' alignItems='center'>
          <h3 className='upload-files__title'>{title}</h3>
          {maxImages && <p className='upload-files__desc'>(max {maxImages} images)</p>}
        </Stack>
        <label className='upload-files__label'>
          <Stack direction='row' spacing='10px' alignItems='center' className='upload-files__btn'>
            <FolderIcon />
            <p>Attach file</p>
          </Stack>
          <input type='file' accept={FILE_TYPE_IMAGE} onChange={handleChangeFile} />
        </label>
      </Stack>
      <Stack direction='row' justifyContent='space-between' gap='16px' className='upload-files__wrapper'>
        {files.map((file, idx) => {
          return (
            <div className='upload-files__image'>
              <img src={file.preview} alt='License 1' />
              <UIActionButton
                type='close'
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteImage(file, idx);
                }}
              />
            </div>
          );
        })}
      </Stack>
    </div>
  );
};

export default UploadFiles;
