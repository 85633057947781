import cn from 'app/helpers/cn';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { NotificationModel } from 'app/models/Notifications';
import { ReactComponent as PlaceholderBellIcon } from 'assets/icons/table/notifications.svg';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import TablePlaceholder from 'shared/ui/UITable/components/TablePlaceholder/TablePlaceholder';
import { getNotifications } from 'store/slices/notifications';
import NotificationsItem from './components/NotificationsItem/NotificationsItem';
import './Notifications.sass';

const limit = 10;
const Notifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const date = new Date();
  const today = dayjs(date).format('DD/MM/YYYY');
  const yesterday = dayjs(date.setDate(date.getDate() - 1)).format('DD/MM/YYYY');

  const { notifications, notificationsLoading } = useAppSelector((state) => state.notifications);
  const [page, setPage] = useState(1);
  const [showShadow, setShowShadow] = useState(true);

  const [todayNotifications, setTodayNotifications] = useState<NotificationModel[]>([]);
  const [yesterdayNotifications, setYesterdayNotifications] = useState<NotificationModel[]>([]);
  const [othersNotifications, setOthersTodayNotifications] = useState<NotificationModel[]>([]);

  useEffect(() => {
    if (!notifications.data.length) return;

    const todayData: NotificationModel[] = [];
    const yesterdayData: NotificationModel[] = [];
    const othersData: NotificationModel[] = [];

    notifications.data.forEach((notification) => {
      if (notification.date === today) {
        return todayData.push(notification);
      }
      if (notification.date === yesterday) {
        return yesterdayData.push(notification);
      }

      othersData.push(notification);
    });

    setTodayNotifications(todayData);
    setYesterdayNotifications(yesterdayData);
    setOthersTodayNotifications(othersData);
  }, [notifications]);

  useEffect(() => {
    dispatch(getNotifications({ concat: false, force: true, params: { page: page, pageSize: limit } }));
  }, []);

  const getData = () => {
    dispatch(getNotifications({ concat: true, force: true, params: { page: page + 1, pageSize: limit } }));

    setPage((prev) => prev + 1);
  };

  const handleScrollNotifications = (e: React.UIEvent<HTMLDivElement>) => {
    const atBottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;

    if (notifications.totalPages && page === notifications.totalPages && atBottom) {
      setShowShadow(false);
    } else {
      setShowShadow(true);
    }
  };

  return (
    <div
      className={cn('notifications', {
        'show-shadow': showShadow,
      })}
    >
      <div className='notifications__title'>Notifications</div>
      {notifications.totalPages === 0 && !notificationsLoading ? (
        <div className='notifications__placeholder'>
          <TablePlaceholder
            icon={<PlaceholderBellIcon />}
            title='Sorry, it is empty here!'
            subtitle='You don’t have any notifications yet. All news will be displayed here.'
          />
        </div>
      ) : (
        <div className='notifications__wrapper'>
          <div className='notifications__content scroll' id='scrollableDiv' onScroll={handleScrollNotifications}>
            <InfiniteScroll
              dataLength={notifications.data.length}
              next={getData}
              hasMore={page < (notifications.totalPages ?? 0)}
              loader={<></>}
              scrollableTarget='scrollableDiv'
            >
              <div className='notifications__list'>
                {!!todayNotifications.length && (
                  <div className='notifications__devider'>
                    <div className='notifications__devider-title'>Today</div>
                    <div className='notifications__list'>
                      {todayNotifications.map((notification) => (
                        <NotificationsItem notification={notification} key={notification.unique} />
                      ))}
                    </div>
                  </div>
                )}
                {!!yesterdayNotifications.length && (
                  <div className='notifications__devider'>
                    <div className='notifications__devider-title'>Yesterday</div>
                    <div className='notifications__list'>
                      {yesterdayNotifications.map((notification) => (
                        <NotificationsItem notification={notification} key={notification.unique} />
                      ))}
                    </div>
                  </div>
                )}
                {!!othersNotifications.length && (
                  <div className='notifications__devider'>
                    <div className='notifications__devider-title'>Previous days</div>
                    <div className='notifications__list'>
                      {othersNotifications.map((notification) => (
                        <NotificationsItem notification={notification} key={notification.unique} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
