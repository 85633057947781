import { Controller, FieldValues } from 'react-hook-form';
import { UIInputProps } from '../../ui/UIInput/types';
import UIInput from '../../ui/UIInput/UIInput';
import BaseFormInputProps from '../types';

function FormTextInput<T = FieldValues>({
  control,
  name,
  rules,
  defaultValue,
  condition,
  ...rest
}: BaseFormInputProps<T> & UIInputProps & { condition?: (val: string) => boolean }) {
  return (
    <Controller
      // @ts-ignore
      defaultValue={defaultValue}
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, formState: { errors } }) => {
        return (
          <UIInput
            {...rest}
            onChange={(event) => {
              if (event.target.value && condition && !condition(event.target.value)) return;
              onChange(event);
            }}
            onBlur={onBlur}
            value={value ?? ''}
            error={!!errors[name]?.message}
            helperText={<>{errors[name]?.message ?? ''}</>}
          />
        );
      }}
    />
  );
}

export default FormTextInput;
