import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { chatsReducer } from 'store/slices/chats';
import { notificationsReducer } from 'store/slices/notifications';
import { payrollsReducer } from 'store/slices/payrolls';
import { appReducer } from './slices/app';
import { authReducer } from './slices/auth';
import { companiesReducer } from './slices/companies';
import { projectsReducer } from './slices/projects';
import { schedulesReducer } from './slices/schedules';
import { staffReducer } from './slices/staff';
import { utilsReducer } from './slices/utils';
import { filtersReducer } from './slices/filters';

export const store = configureStore({
  reducer: {
    app: appReducer,
    companies: companiesReducer,
    projects: projectsReducer,
    auth: authReducer,
    utils: utilsReducer,
    schedules: schedulesReducer,
    staff: staffReducer,
    payrolls: payrollsReducer,
    chats: chatsReducer,
    notifications: notificationsReducer,
    filters: filtersReducer,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({ serializableCheck: false });
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
