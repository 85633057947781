import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { payrollsService } from 'api';
import { GetPayrollsBody } from 'api/payrolls/types';
import { RootState } from 'store/store';
import { PayrollsState } from './types';

const initialState: PayrollsState = {
  payrolls: {
    data: [],
  },
};

export const getPayrolls = createAsyncThunk(
  'payrolls/getPayrolls',
  async (payload: { force: boolean; params?: GetPayrollsBody } | undefined, ThunkApi) => {
    const { payrolls } = (ThunkApi.getState() as RootState).payrolls as PayrollsState;
    if (payrolls?.data.length && !payload?.force) return payrolls;

    return await payrollsService.getPayrolls(payload?.params).then((res) => res.data);
  }
);

export const getPayroll = createAsyncThunk('payrolls/getPayroll', async (id: number, ThunkApi) => {
  return await payrollsService.getPayroll(id).then((response) => response.data.data);
});

export const payrollsSlice = createSlice({
  name: 'payrolls',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPayrolls.pending, (state) => {
        state.payrollsLoading = true;
      })
      .addCase(getPayrolls.fulfilled, (state, action) => {
        state.payrollsLoading = false;
        state.payrolls = action.payload;
      })
      .addCase(getPayroll.pending, (state, action) => {
        state.currentPayroll = undefined;
        state.currentPayrollLoading = true;
      })
      .addCase(getPayroll.fulfilled, (state, action) => {
        state.currentPayrollLoading = false;
        state.currentPayroll = action.payload;
      });
  },
});

export const payrollsReducer = payrollsSlice.reducer;
