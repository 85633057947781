import instance from 'api/instance';
import {
  DeleteScheduleMessage,
  EditScheduleMessage,
  GetScheduleChat,
  PostScheduleMessageAttachment,
  SendScheduleMessage,
  TranslateScheduleMessage,
} from './types';

export const getScheduleChat: GetScheduleChat = (body) => {
  return instance.post('/exec?action=get_scheduleChat', body);
};

export const sendScheduleMessage: SendScheduleMessage = (body) => {
  return instance.post('/exec?action=send_scheduleMessage', body);
};

export const editScheduleMessage: EditScheduleMessage = (body) => {
  return instance.post('/exec?action=edit_scheduleMessage', body);
};

export const deleteScheduleMessage: DeleteScheduleMessage = (unique) => {
  return instance.post('/exec?action=delete_scheduleMessage', {
    unique,
  });
};

export const postScheduleMessageAttachment: PostScheduleMessageAttachment = (body) => {
  return instance.post('/exec?action=post_message_attachment', body);
};

export const translateScheduleMessage: TranslateScheduleMessage = (unique) => {
  return instance.post('/exec?action=translate_scheduleMessage', {
    unique,
  });
};
