import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import React, { useEffect, useState } from 'react';
import { UITimePickerProps } from './types';

// export const timeRegex = /\b((1[0-2]|0?[0-9]):([0-5][0-9]) ([AaPp][Mm]))/;
export const timeRegex = /\b((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))\b/;

const UITimePicker: React.FC<UITimePickerProps> = ({ value, invalid, helperText, onChange, disabled }) => {
  const [date, setDate] = useState<Date | null>(value ?? null);

  useEffect(() => {
    setDate(value ?? null);
  }, [value]);

  const onChangeHandler = (date: Date | null, _inputValue?: string) => {
    // console.log('date: ', date);
    setDate(date);
    onChange && onChange(date);
  };

  return (
    <TimePicker
      label=''
      value={date}
      onChange={onChangeHandler}
      renderInput={(params) => <TextField {...params} helperText={helperText} error={invalid} />}
      disabled={disabled}
    />
  );
};

export default UITimePicker;
