import React, { FC } from 'react';

import cn from 'classnames';
import './TablePlaceholder.sass';

type Props = {
  className?: string;
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
  withTitle?: boolean;
};

const EmptyPlaceholder: FC<Props> = ({ icon, title, subtitle, className, withTitle }) => {
  return (
    <div
      className={cn('table-placeholder', className, {
        'table-placeholder--with-title': withTitle,
      })}
    >
      <div className='table-placeholder__icon-wrapper'>{icon}</div>
      <div className='table-placeholder__title'>{title}</div>
      <div className='table-placeholder__subtitle'>{subtitle}</div>
    </div>
  );
};

export default EmptyPlaceholder;
