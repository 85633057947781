import { FILE_SIZE, FILE_TYPE_ALL } from 'app/constants/FILE_CONFIG';
import cn from 'app/helpers/cn';
import { FC } from 'react';
import './UIInputFile.sass';

interface Props {
  onChange: (files: FileList) => void;
  className?: string;
  multiple?: boolean;
  accept?: string;
  disabled?: boolean;
}

const UIInputFile: FC<Props> = ({ onChange, className, multiple = false, accept = FILE_TYPE_ALL, disabled }) => {
  return (
    <input
      type='file'
      accept={accept}
      multiple={multiple}
      className={cn(className, disabled && 'ui-input-file')}
      onChange={(e) => {
        if (e.target.files && e.target.files[0].size < FILE_SIZE) {
          onChange(e.target.files);
        }
      }}
      disabled={disabled}
    />
  );
};

export default UIInputFile;
