import { FormControl, FormLabel } from '@mui/material';
import { formatDate } from 'app/helpers/formatDate';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormDateInput from 'shared/Inputs/FormDateInput/FormDateInput';
import FormPhoneInput from 'shared/Inputs/FormPhoneInput/FormPhoneInput';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import { setCurrentRate } from 'store/slices/companies';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { SubmitModalProps } from '../SubmitModalLayout/types';
import { ModalBaseRef } from '../types';
import './UpdateRateModal.sass';

interface Props extends Omit<SubmitModalProps, 'children'> {
  children?: React.ReactNode;
}

interface SubmitData {
  unique: number;
  rate: string | number;
  startDate: string;
}

const UpdateRateModal: React.ForwardRefRenderFunction<ModalBaseRef, Props> = ({ children, onSubmit, ...rest }, ref) => {
  const dispatch = useAppDispatch();
  const { currentRate } = useAppSelector((state) => state.companies);

  const [open, setOpen] = useState(false);
  const [submitCallback, setSubmitCallback] = useState<Function>(() => () => {});

  const { control, handleSubmit, setValue } = useForm<SubmitData>();

  useImperativeHandle(
    ref,
    () => ({
      show: (callback) => {
        if (callback) {
          setSubmitCallback(() => callback);
        }
        setOpen(true);
      },
      hide: () => {
        setOpen(false);
        dispatch(setCurrentRate(undefined));
        setSubmitCallback(() => () => {});
      },
    }),
    [currentRate]
  );

  useEffect(() => {
    if (!currentRate) return;

    setValue('unique', currentRate.unique);
    setValue('rate', currentRate.rate);
    setValue('startDate', formatDate({ date: currentRate.date }));
  }, [currentRate]);

  const onSubmitHandler: SubmitHandler<SubmitData> = async (data) => {
    if (submitCallback) {
      await submitCallback(data);
    }
  };

  return (
    <UIModal open={open} classes={{ root: 'update-rate-modal' }} onBackdropClick={() => setOpen(false)}>
      <SubmitModalLayout {...rest} onSubmit={handleSubmit(onSubmitHandler)} onClose={() => setOpen(false)}>
        <div>
          <FormControl className='update-rate-modal__row'>
            <FormLabel required>Rate</FormLabel>
            <FormTextInput
              name='rate'
              control={control}
              condition={(value) => /^[0-9]*(?:\.[0-9]*)?$/.test(value)}
              rules={{
                required: 'Required field',
                pattern: {
                  value: /\d/g,
                  message: 'Invalid Rate',
                },
              }}
            />
          </FormControl>
          <FormControl className='update-rate-modal__row'>
            <FormLabel required>Start Date</FormLabel>
            <FormDateInput value={null} name='startDate' control={control} rules={{ required: 'Required field' }} />
          </FormControl>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(UpdateRateModal);
