import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import SubmitModalLayout from 'shared/Modals/SubmitModalLayout/SubmitModalLayout';
import { SubmitModalProps } from 'shared/Modals/SubmitModalLayout/types';
import UIModal from 'shared/ui/UIModal/UIModal';
import { ModalBaseRef } from './types';

interface Props extends Omit<SubmitModalProps, 'children'> {
  data: { label: string; value: string }[];
  text?: string;
  children?: React.ReactNode;
}

const ConfirmModal: React.ForwardRefRenderFunction<ModalBaseRef, Props> = (
  { children, text, data, onSubmit, ...rest },
  ref
) => {
  const [value, setValue] = useState(data[0]);
  const [open, setOpen] = useState(false);
  const [submitCallback, setSubmitCallback] = useState<Function>(() => () => {});

  useEffect(() => {
    if (!open) {
      setValue(data[0]);
      setSubmitCallback(() => () => {});
    }
  }, [open]);

  useImperativeHandle(
    ref,
    () => ({
      show: (callback) => {
        if (callback) {
          setSubmitCallback(() => callback);
        }
        setOpen(true);
      },
      hide: () => {
        setOpen(false);
      },
    }),
    []
  );

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(event);
    }
    if (submitCallback) {
      await submitCallback(value);
    }
  };

  return (
    <UIModal
      open={open}
      classes={{ root: 'create-schedule__confirm-delete-modal' }}
      onBackdropClick={() => setOpen(false)}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '486px',
        },
      }}
    >
      <SubmitModalLayout {...rest} onSubmit={onSubmitHandler} onClose={() => setOpen(false)}>
        <>
          <div className='create-schedule__confirm-delete-modal-text'>{text}</div>
          <FormControl className='create-schedule__confirm-delete-modal-options'>
            <RadioGroup defaultValue={data[0].value}>
              {data.map((radio) => (
                <FormControlLabel
                  key={radio.value}
                  value={radio.value}
                  control={
                    <Radio
                      checked={radio.value === value.value}
                      onChange={(_, checked) => checked && setValue(radio)}
                    />
                  }
                  label={radio.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(ConfirmModal);
