import { formatPhone } from 'app/helpers/formatPhone';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as EmergencyIcon } from 'assets/icons/emergency.svg';
import { ReactComponent as BriefcaseIcon } from 'assets/icons/primary/briefcase.svg';
import { ReactComponent as DirectboxIcon } from 'assets/icons/primary/directbox.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/primary/document.svg';
import { ReactComponent as MarriageIcon } from 'assets/icons/primary/marriage.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { colorizeStatusEmployees } from 'pages/StaffPage/components/Employees/helpers';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from 'shared/ui/BackButton/BackButton';
import SingleDetail from 'shared/ui/SingleDetail/SingleDetail';
import Status from 'shared/ui/Status/Status';
import UITabs from 'shared/ui/UITabs/UITabs';
import {
  getEmployee,
  getMaritalStatuses,
  getWebLicenseStatuses,
  getWebLicenseTypes,
  getWorkerTypes,
  setCurrentEmployee,
} from 'store/slices/staff';
import Files from './components/Files/Files';
import Licences from './components/Licences/Licences';
import './EmployeeDetailsPage.sass';

const EmployeeDetailsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams() as { id: string };

  const { currentEmployee, maritalStatuses, workerTypes } = useAppSelector((state) => state.staff);

  useEffect(() => {
    if (!id) return;

    dispatch(getEmployee(+id));
    dispatch(getMaritalStatuses());
    dispatch(getWebLicenseTypes());
    dispatch(getWebLicenseStatuses(true));
    dispatch(getWorkerTypes());

    return () => {
      dispatch(setCurrentEmployee(undefined));
    };
  }, [dispatch, id]);

  return (
    <div className='employee-details-page'>
      <BackButton label='View Employee Details' />
      {currentEmployee && (
        <div className='employee-details-page__details'>
          {!!currentEmployee.fullName && (
            <SingleDetail
              title='Name'
              className='employee-details-page__details--single-detail'
              icon={ProfileIcon}
              render={
                <div className='employee-details-page__details--single-detail__with-status'>
                  {currentEmployee.fullName}
                  <Status {...colorizeStatusEmployees(currentEmployee.statusId)} />
                </div>
              }
            />
          )}
          {!!formatPhone(currentEmployee.phone) && (
            <SingleDetail
              title='Phone'
              className='employee-details-page__details--single-detail'
              icon={PhoneIcon}
              label={`${formatPhone(currentEmployee.phone)}`}
            />
          )}
          {!!formatPhone(currentEmployee.emergencyContact) && (
            <SingleDetail
              title='Emergency contact'
              className='employee-details-page__details--single-detail'
              icon={EmergencyIcon}
              label={`${formatPhone(currentEmployee.emergencyContact)}`}
            />
          )}
          <SingleDetail
            title='Speciality'
            className='employee-details-page__details--single-detail'
            icon={BriefcaseIcon}
            render={
              <div className='employee-details-page__details--single-detail__with-status'>
                {currentEmployee.typeId && <div>{workerTypes?.find((w) => w.id === currentEmployee.typeId)?.name}</div>}
                <Status color='secondary' text={`$${currentEmployee.paymentRate} per hour`} />
              </div>
            }
          />
          {!!currentEmployee.crew && (
            <SingleDetail
              title='Worker'
              className='employee-details-page__details--single-detail'
              icon={DocumentIcon}
              label={currentEmployee.crew.name}
            />
          )}
          <SingleDetail
            title='Email'
            className='employee-details-page__details--single-detail'
            icon={MailIcon}
            label={currentEmployee.email}
          />
          <SingleDetail
            title='Address'
            className='employee-details-page__details--single-detail'
            icon={LocationIcon}
            label={Object.values(currentEmployee.address)
              .filter((v) => !!v)
              .join(', ')}
          />
          {(currentEmployee.ssn || currentEmployee.tin) && (
            <SingleDetail
              title='Licenses'
              className='employee-details-page__details--single-detail'
              icon={DirectboxIcon}
              render={
                <div>
                  {currentEmployee.ssn && `SSN: ${currentEmployee.ssn}`}
                  <br />
                  {currentEmployee.tin && `TIN: ${currentEmployee.tin}`}
                </div>
              }
            />
          )}
          {(currentEmployee.maritalStatusId || !!currentEmployee.dependents) && (
            <SingleDetail
              title='Status'
              className='employee-details-page__details--single-detail'
              icon={MarriageIcon}
              render={
                <div>
                  {maritalStatuses && maritalStatuses.find((m) => m.id === currentEmployee.maritalStatusId)?.name}{' '}
                  <br />
                  {!!currentEmployee.dependents && <>Dependents: {currentEmployee.dependents}</>}
                </div>
              }
            />
          )}
        </div>
      )}
      {currentEmployee && (
        <UITabs
          tabs={[
            { tab: 'licences', label: 'Licences', component: <Licences /> },
            { tab: 'files', label: 'Files', component: <Files /> },
          ]}
        />
      )}
    </div>
  );
};

export default EmployeeDetailsPage;
