import { IStatus } from 'shared/ui/Status/types';

export const colorizeStatusProject = (status: string): { color: IStatus; text: string } => {
  const data: { color: IStatus; text: string } = {
    color: 'primary',
    text: '',
  };

  switch (status) {
    case 'Active':
      data.color = 'primary';
      data.text = 'active';
      break;
    case 'Inactive':
      data.color = 'danger';
      data.text = 'inactive';
      break;
    case 'Finished':
      data.color = 'secondary';
      data.text = 'finished';
      break;
  }

  return data;
};
