import { FormControl, FormLabel, Stack } from '@mui/material';
import { StaffService, utilsService } from 'api';
import { downloadBlobFile } from 'app/helpers/downloadBlobFile';
import { getFileExtension } from 'app/helpers/getFileShortName';
import { toBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useFilters from 'app/hooks/useFilters';
import { EmployeeFileModel } from 'app/models/StaffModel';
import { ReactComponent as PlaceholderFolderOpenIcon } from 'assets/icons/table/folder-open.svg';
import qs from 'qs';
import React, { useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import AddFileModal from 'shared/Modals/AddFileModal/AddFileModal';
import { AddFileModalRef } from 'shared/Modals/AddFileModal/types';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';
import { ModalBaseRef } from 'shared/Modals/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import { getEmployeeFiles } from 'store/slices/staff';

interface SubmitData {
  fileName: string;
}

const limit = 10;
const Files: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id } = useParams() as { id: string };

  const { currentEmployee, currentEmployeeFiles, currentEmployeeFilesLoading, currentEmployeeFilesFilters } =
    useAppSelector((state) => state.staff);
  const { control, handleSubmit, setValue, reset } = useForm<SubmitData>();
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const addFilesModalRef = useRef<AddFileModalRef>(null);
  const confirmModalRef = useRef<ModalBaseRef>(null);
  const previewImageModalRef = useRef<PreviewImageModalRef>(null);

  const initialFilterParams = {
    page: +(qs.parse(location.search, { ignoreQueryPrefix: true }).page ?? 1),
  };

  const { replaceUrl, onChangeFilter } = useFilters({
    initialFilterParams,
    limit,
    initialCallback: (params) => dispatch(getEmployeeFiles({ params: { ...params, pageSize: limit, unique: +id } })),
  });

  const changePage = (value: number) => {
    const filters = onChangeFilter({ page: value });
    replaceUrl(filters);
    dispatch(getEmployeeFiles({ params: { ...filters, pageSize: limit, unique: +id } }));
  };

  const onSubmitHandler: SubmitHandler<SubmitData> = async (data) => {
    if (!addFilesModalRef.current) return;
    const { files } = addFilesModalRef.current.handleSubmit();
    if (!currentEmployee || !files.length) return addFilesModalRef.current.validate();

    const file = files[0];

    const addFileData = {
      unique: currentEmployee.unique,
      name: data.fileName ?? file.name,
    };

    try {
      setLoading(true);
      const { unique } = await StaffService.addFileData(addFileData).then((res) => res.data);

      await toBase64(files[0]).then(async (result: string) => {
        await StaffService.addFile({
          unique: unique,
          extension: getFileExtension(file.name),
          file: result.split('base64,')[1],
          attachmentName: file.name,
        });
      });

      dispatch(getEmployeeFiles({ params: { ...currentEmployeeFilesFilters } }));

      reset();
      addFilesModalRef.current.clearFiles();
      addFilesModalRef.current.hide();
    } finally {
      setLoading(false);
    }
  };

  const onAddFileHandler = () => {
    setIsUpdate(false);
    addFilesModalRef.current?.clearFiles();
    reset();

    addFilesModalRef.current?.show();
  };

  const onUpdateClickHandler = (file: EmployeeFileModel) => {
    if (!currentEmployee) return;

    addFilesModalRef.current?.show(file);
    setIsUpdate(true);
    setValue('fileName', file.name);
  };

  const onSubmitUpdateHandler: SubmitHandler<SubmitData> = async (data) => {
    if (!addFilesModalRef.current) return;
    const { data: fileData, files } = addFilesModalRef.current.handleSubmit();
    if (!currentEmployee || !files.length || !fileData) return addFilesModalRef.current.validate();

    const editFileData = {
      unique: fileData.unique,
      name: data.fileName ?? files[0].name,
    };

    try {
      setLoading(true);
      const { unique } = await StaffService.editFileData(editFileData).then((res) => res.data);

      if (fileData.isFileUpdated) {
        await toBase64(files[0]).then(async (result: string) => {
          await StaffService.addFile({
            unique: unique,
            extension: getFileExtension(files[0].name),
            file: result.split('base64,')[1],
            attachmentName: files[0].name,
          });
        });
      }

      dispatch(getEmployeeFiles({ params: { ...currentEmployeeFilesFilters } }));
      reset();
      addFilesModalRef.current.clearFiles();
      addFilesModalRef.current.hide();
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = (unique: number) => {
    if (!currentEmployee) return;

    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);

        await StaffService.deleteEmployeeFile(unique);
        confirmModalRef.current?.hide();

        await dispatch(getEmployeeFiles({ params: currentEmployeeFilesFilters }));
      } finally {
        setLoading(false);
      }
    });
  };

  const previewFileOrDownload = (item: EmployeeFileModel, type?: 'download' | 'preview') => {
    if (type === 'preview') {
      previewImageModalRef.current?.show({ file: { name: item.name, unique: item.unique, extension: item.extension } });
    } else {
      utilsService.getWebFile(item.unique).then((res) => {
        downloadBlobFile(res, item.name, item.extension, true);
      });
    }
  };

  return (
    <>
      <UITable
        loading={currentEmployeeFilesLoading}
        totalPages={currentEmployeeFiles?.totalPages}
        data={currentEmployeeFiles?.files}
        headers={[{ label: 'Name' }, { label: 'Actions' }]}
        onRowClick={(i) => previewFileOrDownload(i, 'preview')}
        columns={[
          { columnName: 'name' },
          {
            renderCol: (file) => {
              return (
                <Stack direction='row' spacing={2}>
                  <UIActionButton type='download' onClick={() => previewFileOrDownload(file, 'download')} />
                  <UIActionButton type='edit' onClick={() => onUpdateClickHandler(file)} />
                  <UIActionButton type='delete' onClick={() => deleteFile(file.unique)} />
                </Stack>
              );
            },
            noWrap: true,
          },
        ]}
        title={{
          title: 'List of files',
          button: {
            text: 'Add File',
            icon: 'plus',
            onClick: onAddFileHandler,
          },
        }}
        placeholder={{
          icon: <PlaceholderFolderOpenIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of files is empty yet. You can first file right now.',
        }}
        pagination={{
          count: currentEmployeeFiles?.totalPages ?? 1,
          page: currentEmployeeFilesFilters?.page ?? 1,
          onChange: (_, value) => changePage(value),
        }}
      />
      <PreviewImageModal ref={previewImageModalRef} />
      <ConfirmModal ref={confirmModalRef} title='Delete' submitBtnText='Confirm' loading={loading}>
        Do you really want to delete this file? You will not be able to undo this.
      </ConfirmModal>
      <AddFileModal
        ref={addFilesModalRef}
        onSubmit={isUpdate ? handleSubmit(onSubmitUpdateHandler) : handleSubmit(onSubmitHandler)}
        loading={loading}
      >
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <FormTextInput name='fileName' control={control} />
          </FormControl>
        </Stack>
      </AddFileModal>
    </>
  );
};

export default Files;
