import { FC, useEffect } from 'react';
import { formatDate } from 'app/helpers/formatDate';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as BankIcon } from 'assets/icons/primary/bank.svg';
import { ReactComponent as Calendar2Icon } from 'assets/icons/primary/calendar-2.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/primary/calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/primary/clock.svg';
import { ReactComponent as MoneysIcon } from 'assets/icons/primary/moneys.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { useParams } from 'react-router-dom';
import BackButton from 'shared/ui/BackButton/BackButton';
import SingleDetail from 'shared/ui/SingleDetail/SingleDetail';
import { IStatus } from 'shared/ui/Status/types';
import UITabs from 'shared/ui/UITabs/UITabs';
import { getProject } from 'store/slices/projects';
import AttendanceDetails from './components/AttendanceDetails/AttendanceDetails';
import FilesDetails from './components/FilesDetails/FilesDetails';
import SupervisorsDetails from './components/SupervisorsDetails/SupervisorsDetails';
import SchedulesDetails from './components/SchedulesDetails/SchedulesDetails';
import './ProjectDetailsPage.sass';
import Status from 'shared/ui/Status/Status';

const ProjectDetailsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams() as { id: string };

  const { currentProject } = useAppSelector((state) => state.projects);

  useEffect(() => {
    if (!id) return;
    dispatch(getProject(+id));
  }, [dispatch, id]);

  const getStatusData = (status: string): { color: IStatus; text: string } => {
    const data: { color: IStatus; text: string } = {
      color: 'primary',
      text: '',
    };

    switch (status) {
      case 'Active':
        data.color = 'primary';
        data.text = 'active';
        break;
      case 'Inactive':
        data.color = 'danger';
        data.text = 'inactive';
        break;
      case 'Finished':
        data.color = 'secondary';
        data.text = 'finished';
        break;
    }

    return data;
  };

  return (
    <div className='project-details-page'>
      <BackButton label='View Project’s Details' />
      {currentProject && (
        <div className='project-details-page__details'>
          <SingleDetail
            title='Name'
            className='project-details-page__details--single-detail'
            icon={ProfileIcon}
            label={currentProject.name}
          />
          <SingleDetail
            title='Address'
            className='project-details-page__details--single-detail'
            icon={LocationIcon}
            label={currentProject.address[0].description}
          />
          <SingleDetail
            title='Company & Status'
            className='project-details-page__details--single-detail'
            icon={BankIcon}
            render={
              <div className='project-details-page__details--single-detail__with-status'>
                {currentProject.company}
                <Status {...getStatusData(currentProject.status)} />
              </div>
            }
          />
          <SingleDetail
            title='Date'
            className='project-details-page__details--single-detail'
            icon={CalendarIcon}
            label={`${formatDate({ date: currentProject.startDate })} - ${formatDate({
              date: currentProject.endDate,
            })}`}
          />
          {!!currentProject.namePaymentOption && (
            <SingleDetail
              title='Rate'
              className='project-details-page__details--single-detail'
              icon={MoneysIcon}
              label={currentProject.namePaymentOption}
            />
          )}
          {!!currentProject.lastDayWorked && (
            <SingleDetail
              title='Last day worked on'
              className='project-details-page__details--single-detail'
              icon={Calendar2Icon}
              label={`Last day worked on ${formatDate({ date: currentProject.lastDayWorked })}`}
            />
          )}
          {!!currentProject.workedDays && (
            <SingleDetail
              title='Days worked'
              className='project-details-page__details--single-detail'
              icon={ClockIcon}
              label={`${currentProject.workedDays} days`}
            />
          )}
        </div>
      )}
      {currentProject && (
        <UITabs
          tabs={[
            { tab: 'supervisors', label: 'Supervisors', component: <SupervisorsDetails /> },
            { tab: 'files', label: 'Files', component: <FilesDetails /> },
            { tab: 'attendance', label: 'Attendance', component: <AttendanceDetails /> },
            { tab: 'schedules', label: 'Schedules', component: <SchedulesDetails /> },
          ]}
        />
      )}
    </div>
  );
};

export default ProjectDetailsPage;
