import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChatsState } from './types';
import { MessageModel } from 'app/models/ChatModel';

const initialState: ChatsState = {
  chatHistory: [],
};

export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setChatHistory: (state, action: PayloadAction<MessageModel[]>) => {
      state.chatHistory = action.payload;
    },
    setChtHistoryLoading: (state, action: PayloadAction<boolean>) => {
      state.chatHistoryLoading = action.payload;
    },
    setNewMessage: (state, action: PayloadAction<MessageModel | undefined>) => {
      state.newMessage = action.payload;
    },
    setCurrentChatId: (state, action: PayloadAction<number>) => {
      state.currentChatId = action.payload;
    },
  },
  extraReducers: () => {},
});

export const { setChatHistory, setChtHistoryLoading, setCurrentChatId, setNewMessage } = chatsSlice.actions;

export const chatsReducer = chatsSlice.reducer;
