import cn from 'app/helpers/cn';
import { ReactComponent as ArrowIcon } from 'assets/icons/left-arrow.svg';
import React from 'react';
import './BackButton.sass';
import { BackButtonProps } from './types';
import HistoryManager from 'app/helpers/historyManager';
import history from 'app/routes/history';

const BackButton: React.FC<BackButtonProps> = ({ label, className = '', destination = null }) => {
  const historyManager = HistoryManager.getInstance();

  const handleBack = () => {
    const url = historyManager.navigateBack();
    if (destination) {
      const destinationUrl = historyManager.navigateTo(destination);
      history.push(destinationUrl || '/');
    } else if (url) {
      history.push(url);
    }
  };

  return (
    <div
      className={cn('back-button', {
        [className]: className,
      })}
    >
      <div className='back-button__link' onClick={handleBack}>
        <ArrowIcon className='back-button__icon' />
      </div>
      <div className='back-button__label'>{label}</div>
    </div>
  );
};

export default BackButton;
