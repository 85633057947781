import { CircularProgress, InputBase, Popover } from '@mui/material';
import { chatsService } from 'api';
import { getFileExtension } from 'app/helpers/getFileShortName';
import { toBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MessageModel } from 'app/models/ChatModel';
import { ReactComponent as CancelCircleIcon } from 'assets/icons/chat/cancel-circle.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/chat/delete.svg';
import { ReactComponent as EditCircleIcon } from 'assets/icons/chat/edit-circle.svg';
import { ReactComponent as EditIcon } from 'assets/icons/chat/edit.svg';
import { ReactComponent as ReplyCircleIcon } from 'assets/icons/chat/reply-circle.svg';
import { ReactComponent as AttachFileIcon } from 'assets/icons/primary/attach-file.svg';
import { ReactComponent as SendIcon } from 'assets/icons/primary/send.svg';
import { ReactComponent as PlaceholderSendIcon } from 'assets/icons/table/send.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import { ModalBaseRef } from 'shared/Modals/types';
import BackButton from 'shared/ui/BackButton/BackButton';
import UIInputFile from 'shared/ui/UIFileInput/UIInputFile';
import TablePlaceholder from 'shared/ui/UITable/components/TablePlaceholder/TablePlaceholder';
import { getSchedule } from 'store/slices/schedules';
import './Chat.sass';
import { Message } from './components/Message/Message';
import SocketSingleton from 'api/socket';
import { setChtHistoryLoading, setNewMessage } from 'store/slices/chats';
import { getShortCompanyName } from './helpers';
import cn from 'classnames';

const Chat: React.FC = () => {
  const socket = SocketSingleton.getInstance();

  const { id } = useParams() as { id: string };
  const dispatch = useAppDispatch();

  const { currentSchedule } = useAppSelector((state) => state.schedules);
  const { chatHistory, chatHistoryLoading, newMessage } = useAppSelector((state) => state.chats);

  const [messageText, setMessageText] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [type, setType] = useState<'edit' | 'reply' | null>(null);
  const [currentMessage, setCurrentMessage] = useState<MessageModel | null>(null);
  const [actionsPopoverAnchor, setActionsPopoverAnchor] = useState<HTMLDivElement | null>(null);
  const confirmModalRef = useRef<ModalBaseRef>(null);

  const [files, setFiles] = useState<FileList>();
  const [sent, setSent] = useState(false);

  const messagesRef = useRef<HTMLDivElement>(null);
  const messagesContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getSchedule({ id: +id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(setChtHistoryLoading(true));

    const timer = () => {
      if (socket.loggedIn) {
        socket.joinRoom({ unique: +id });
        clearInterval(interval);
      }
    };

    const interval = setInterval(timer, 500);

    return () => {
      socket.exitRoom({ unique: +id });
    };
  }, []);

  useEffect(() => {
    if (type === 'edit') {
      setMessageText(currentMessage?.text ?? '');
    }
  }, [type]);

  useEffect(() => {
    if (!messagesRef.current && !messagesContentRef.current?.scrollTop !== undefined) return;

    if (messagesContentRef.current) {
      messagesContentRef.current.scrollTop = messagesRef.current?.scrollHeight ?? 0;
    }
  }, [chatHistory, messagesRef, messagesContentRef]);

  useEffect(() => {
    if (sent && newMessage && files && files?.length > 0) {
      sendFile(files[0]);
    }
  }, [newMessage]);

  const sendFile = async (file: File) => {
    await toBase64(file).then(async (result: string) => {
      chatsService.postScheduleMessageAttachment({
        unique: newMessage?.unique!,
        extension: getFileExtension(file.name),
        file: result.split('base64,')[1],
        name: file.name,
      });

      setCurrentMessage(null);
      setFiles(undefined);
      setSent(false);
    });
  };

  const sendMessage = async (files?: FileList) => {
    if (!messageText && !files?.length) return;
    const text = messageText;
    setMessageText('');

    await dispatch(setNewMessage(undefined));

    try {
      if (type === 'edit') {
        await socket.editMessage({
          unique: currentMessage?.unique!,
          scheduleId: +id,
          text,
        });
      } else {
        const data = {
          scheduleId: +id,
          text,
          replyTo: currentMessage?.unique,
          hasFile: files && files.length > 0,
        };

        if (!data.replyTo) {
          delete data.replyTo;
        }

        await socket.sendMessage(data);

        setSent(true);
      }
    } finally {
      setType(null);
      setCurrentMessage(null);
    }
  };

  const showPopover = (el: React.MouseEvent<HTMLDivElement>, message: MessageModel) => {
    setCurrentMessage(message);
    if (message.own) {
      setActionsPopoverAnchor(el.currentTarget);
    }
  };

  if (chatHistoryLoading) {
    return <CircularProgress className='chat__progress' />;
  }

  const deleteMessage = () => {
    setActionsPopoverAnchor(null);
    if (!currentMessage?.unique) return;

    confirmModalRef.current?.show(async () => {
      try {
        setDeleteLoading(true);

        socket.deleteMessage({
          unique: currentMessage.unique,
          scheduleId: +id,
          hasFile: !!currentMessage.files.length,
        });
      } finally {
        setCurrentMessage(null);
        setFiles(undefined);
        setDeleteLoading(false);
        confirmModalRef.current?.hide();
      }
    });
  };

  return (
    <div className='chat'>
      <div className='chat__wrapper'>
        <div className='chat__header'>
          <BackButton label='' />
          <div className='chat__company'>
            <div className='chat__company-shortname'>{getShortCompanyName(currentSchedule?.company ?? '')}</div>
            <div className='chat__company-name'>{currentSchedule?.company}</div>
          </div>
        </div>
        <div className='chat__content scroll' ref={messagesContentRef}>
          {chatHistory.length ? (
            <div className='chat__messages' ref={messagesRef}>
              {chatHistory.map((message, index) => (
                <Message
                  message={message}
                  index={index}
                  key={message.unique}
                  showPopover={showPopover}
                  setCurrentMessage={setCurrentMessage}
                  setType={setType}
                />
              ))}
            </div>
          ) : (
            <div className='chat__placeholder'>
              <TablePlaceholder
                icon={<PlaceholderSendIcon />}
                title='Sorry, it is empty here!'
                subtitle='There are no messages yet in this chat. They will be displayed here.'
              />
            </div>
          )}

          {currentMessage && type && (
            <div className='chat__reply'>
              {type === 'edit' && <EditCircleIcon className='chat__reply-icon' />}
              {type === 'reply' && <ReplyCircleIcon className='chat__reply-icon' />}
              <div className='chat__reply-content'>
                <div className='chat__reply-title'>
                  {type === 'reply' ? `Reply to ${currentMessage.author}` : 'Edit Message'}
                </div>
                <div className='chat__reply-text'>{currentMessage.text}</div>
              </div>
              <CancelCircleIcon
                className='chat__reply-cancel'
                onClick={() => {
                  setType(null);
                  setCurrentMessage(null);
                }}
              />
            </div>
          )}
        </div>
        <div className='chat__footer'>
          <label
            className={cn('chat__attach', {
              'chat__attach--disabled': type === 'edit',
            })}
          >
            <UIInputFile
              className='chat__attach-input'
              onChange={async (files) => {
                setFiles(files);
                sendMessage(files);
              }}
              disabled={type === 'edit'}
            />
            <AttachFileIcon />
          </label>
          <InputBase
            value={messageText}
            onChange={(event) => {
              if (event.target.value.length >= 255) return;
              setMessageText(event.target.value);
            }}
            className='chat__body-input'
            placeholder='Start typing a message'
            size='medium'
            multiline
            maxRows={5}
          />
          <div className='chat__send' onClick={() => sendMessage()}>
            <SendIcon />
          </div>
        </div>
      </div>
      <Popover
        anchorEl={actionsPopoverAnchor}
        open={!!actionsPopoverAnchor}
        onClose={() => setActionsPopoverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginTop: '5px',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.08)',
          borderRadius: '12px',
        }}
      >
        {!currentMessage?.files.length && (
          <div
            className='chat__popover-btn'
            onClick={() => {
              setType('edit');
              setActionsPopoverAnchor(null);
            }}
          >
            Edit <EditIcon />
          </div>
        )}
        <div className='chat__popover-btn delete' onClick={deleteMessage}>
          Delete <DeleteIcon />
        </div>
      </Popover>
      <ConfirmModal ref={confirmModalRef} title='Delete' loading={deleteLoading}>
        Do you really want to delete this message? You will not be able to undo this.
      </ConfirmModal>
    </div>
  );
};

export default Chat;
