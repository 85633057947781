import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { projectsService } from 'api';
import cn from 'app/helpers/cn';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { SupervisorModel, SupervisorType } from 'app/models/ProjectModel';
import _ from 'lodash';
import { getLicenseColor } from 'pages/LicenseDetailsPage/helpers';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Status from 'shared/ui/Status/Status';
import UICheckbox from 'shared/ui/UICheckbox/UICheckbox';
import UIInput from 'shared/ui/UIInput/UIInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import UITable from 'shared/ui/UITable/UITable';
import UITabs from 'shared/ui/UITabs/UITabs';
import { getProject, getSupervisors } from 'store/slices/projects';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import './AddSupervisorModal.sass';
import { AddSupervisorModalProps, AddSupervisorModalRef } from './types';

const AddSupervisorModal: React.ForwardRefRenderFunction<AddSupervisorModalRef, AddSupervisorModalProps> = (
  { onSubmit },
  ref
) => {
  const { id } = useParams() as { id: string };
  const dispatch = useAppDispatch();
  const { supervisors, supervisorsLoading, currentProject } = useAppSelector((state) => state.projects);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeSupervisors, setActiveSupervisors] = useState<number[]>([]);
  const [filteredSuperVisors, setFilteredSuperVisors] = useState<SupervisorModel[]>([]);
  const [company, setCompany] = useState<{ uniqueC: number; name: string } | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: async (company, activeSupervisors) => {
        setCompany(company);

        setOpen(true);
        await dispatch(getSupervisors({ force: true, params: { type: 'inhouse' } }));
        setActiveSupervisors(activeSupervisors);
      },
      hide: () => setOpen(false),
      submit: () => _.compact(activeSupervisors.map((el) => supervisors.find((sup) => sup.unique === el))),
    }),
    [activeSupervisors, supervisors]
  );

  useEffect(() => {
    setFilteredSuperVisors(supervisors);
  }, [supervisors]);

  const onSubmitHandler: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!currentProject) return;

    try {
      setLoading(true);

      await projectsService.addSupervisors({ unique: +id, supervisors: activeSupervisors });
      setOpen(false);
      dispatch(getProject(+id));
    } finally {
      setLoading(false);
    }
  };

  const selectAllSupervisors = (checked: boolean) => {
    setActiveSupervisors(checked ? supervisors.map((s) => s.unique) : []);
  };

  const handleChangeSupervisor = (value: number, isChecked: boolean) => {
    if (isChecked) {
      setActiveSupervisors([...activeSupervisors, value]);
    } else {
      setActiveSupervisors(activeSupervisors.filter((c) => c !== value));
    }
  };

  const onChangeTab = (type: SupervisorType) => {
    const data: { force: boolean; params: { type: SupervisorType; companyId?: number } } = {
      force: true,
      params: { type },
    };

    if (type === "client's") data.params.companyId = company?.uniqueC;

    dispatch(getSupervisors(data));
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'add-supervisor-modal',
        paper: 'add-supervisor-modal__paper',
      }}
      PaperProps={{ sx: { maxWidth: '760px', width: '100%' } }}
    >
      <SubmitModalLayout
        title='Add Supervisor'
        onClose={() => setOpen(false)}
        onSubmit={onSubmit ? onSubmit : onSubmitHandler}
        loading={loading}
      >
        <UITabs
          replaceUrl={false}
          onChange={(_, value) => onChangeTab(value)}
          tabs={[
            {
              tab: 'inhouse',
              label: 'Inhouse supervisors',
              component: (
                <UITable
                  loading={supervisorsLoading}
                  withTabs
                  data={filteredSuperVisors}
                  className='add-supervisor-modal__table'
                  classes={{
                    headerRow: 'add-supervisor-modal__table--header-row',
                  }}
                  topComponent={
                    <>
                      <Box sx={{ pr: 1 }}>
                        <UIInput
                          fullWidth
                          placeholder='Search by Name'
                          onChange={(event) =>
                            setFilteredSuperVisors(() => {
                              return supervisors.filter((el) =>
                                el.name.toLowerCase().includes(event.target.value.toLowerCase())
                              );
                            })
                          }
                        />
                      </Box>
                    </>
                  }
                  headers={[
                    {
                      renderCol: () => (
                        <UICheckbox
                          checked={supervisors.every((el) => activeSupervisors.some((unique) => unique === el.unique))}
                          onChange={(_, checked) => selectAllSupervisors(checked)}
                        />
                      ),
                      noWrap: true,
                    },
                    { label: 'Full Name' },
                    { label: 'Licenses' },
                  ]}
                  columns={[
                    {
                      renderCol: (i) => (
                        <UICheckbox
                          checked={
                            !!activeSupervisors.length && activeSupervisors.some((unique) => unique === i.unique)
                          }
                          onChange={(_event, checked) => handleChangeSupervisor(i.unique, checked)}
                        />
                      ),
                    },
                    {
                      renderCol: (i) => {
                        return (
                          <div className={cn('add-supervisor-modal__table-status')}>
                            <Link to={`/employees/${i.unique}`} className='add-supervisor-modal__table-link'>
                              {i.name}
                            </Link>
                          </div>
                        );
                      },
                    },
                    {
                      renderCol: (i) => (
                        <Stack spacing={1} flexWrap='wrap' direction='row'>
                          {i.licenses.map((el, index) => (
                            <Status key={index} text={el.type.shortName} color={getLicenseColor(el)} />
                          ))}
                        </Stack>
                      ),
                    },
                  ]}
                />
              ),
            },
            {
              notVisible: !company,
              tab: "client's",
              label: `${company?.name}'s supervisors`,
              component: (
                <UITable
                  loading={supervisorsLoading}
                  withTabs
                  data={supervisors}
                  className='add-supervisor-modal__table'
                  classes={{
                    headerRow: 'add-supervisor-modal__table--header-row',
                  }}
                  headers={[
                    {
                      renderCol: () => (
                        <UICheckbox
                          checked={
                            !!supervisors.length &&
                            supervisors.every((el) => activeSupervisors.some((unique) => unique === el.unique))
                          }
                          onChange={(_, checked) => selectAllSupervisors(checked)}
                        />
                      ),
                      noWrap: true,
                    },
                    { label: 'Full Name' },
                    { label: 'Licenses' },
                  ]}
                  columns={[
                    {
                      renderCol: (i) => (
                        <UICheckbox
                          checked={activeSupervisors.some((unique) => unique === i.unique)}
                          onChange={(_event, checked) => handleChangeSupervisor(i.unique, checked)}
                        />
                      ),
                    },
                    {
                      renderCol: (i) => {
                        return (
                          <div className={cn('add-supervisor-modal__table-status')}>
                            <Link to={`/employees/${i.unique}`} className='add-supervisor-modal__table-link'>
                              {i.name}
                            </Link>
                          </div>
                        );
                      },
                    },
                    {
                      renderCol: (i) => (
                        <Stack spacing={1} flexWrap='wrap' direction='row'>
                          {/* {i.licenses.map((el, index) => (
                <Status key={index} text={el.type.shortName} color={getLicenseColor(el)} />
              ))} */}
                        </Stack>
                      ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(AddSupervisorModal);
