import { Stack } from '@mui/material';
import { schedulesService } from 'api';
import DATE_FORMAT from 'app/constants/DATE_FORMAT';
import cn from 'app/helpers/cn';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { SchedulesModel } from 'app/models/SchedulesModel';
import history from 'app/routes/history';
import dayjs from 'dayjs';
import _ from 'lodash';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import { ModalBaseRef } from 'shared/Modals/types';
import UIAccordion from 'shared/ui/UIAccordion/UIAccordion';
import UIButton from 'shared/ui/UIButton/UIButton';
import UITitle from 'shared/ui/UITitle/UITitle';
import ScheduleItem from './components/ScheduleItem/ScheduleItem';
import SchedulesFilterModal from './components/SchedulesFilterModal/SchedulesFilterModal';
import { SchedulesFilterModalRef } from './components/SchedulesFilterModal/types';
import './SchedulesPage.sass';
import { checkoutSchedule, getSchedules } from 'api/schedules';
import axios from 'axios';
import { getSchedulesActions } from 'store/slices/schedules';

const SchedulesPage = () => {
  const location = useLocation();

  const { schedules, schedulesLoading } = useAppSelector((state) => state.schedules);
  const schedulesFilterModalRef = useRef<SchedulesFilterModalRef>(null);
  const confirmModalRef = useRef<ModalBaseRef>(null);

  const [countOfFilters, setCountOfFilters] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sendToSupervisorsLoading, setSendToSupervisorsLoading] = useState(false);
  const [sendToWorkersLoading, setSendToWorkersLoading] = useState(false);
  const [dates, setDates] = useState([
    { label: 'Yesterday', value: 'yesterday', checked: false },
    { label: 'Today', value: 'today', checked: false },
    { label: 'Tomorrow', value: 'tomorrow', checked: false },
  ]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { from, to } = params;

    setCountOfFilters(Object.keys(_.omit(params, ['page', 'search'])).length);

    const date = new Date();

    const today = dayjs(date).format(DATE_FORMAT);
    const yesterday = dayjs(date.setDate(date.getDate() - 1)).format(DATE_FORMAT);
    const tomorrow = dayjs(date.setDate(date.getDate() + 2)).format(DATE_FORMAT);

    setDates(
      dates.map((option) => {
        option.checked = false;

        if (((from === today && to === today) || !from) && option.value === 'today') {
          option.checked = true;
        }

        if (from === yesterday && to === yesterday && option.value === 'yesterday') {
          option.checked = true;
        }

        if (from === tomorrow && to === tomorrow && option.value === 'tomorrow') {
          option.checked = true;
        }

        return option;
      })
    );
  }, [location.search]);

  const changeOptions = (value: string, checked: boolean = false) => {
    const result = dates.map((option) => {
      if (option.value === value) option.checked = checked;
      else option.checked = false;

      return option;
    });

    setDates(result);

    if (checked) {
      const date = new Date();

      if (value === 'yesterday') {
        date.setDate(date.getDate() - 1);
      }

      if (value === 'tomorrow') {
        date.setDate(date.getDate() + 1);
      }

      schedulesFilterModalRef.current?.resetAndOnChangeFilter({
        from: dayjs(date).format('DD/MM/YYYY'),
        to: dayjs(date).format('DD/MM/YYYY'),
      });
    }

    if (dates.filter((d) => d.checked).length === 0) {
      schedulesFilterModalRef.current?.resetAndOnChangeFilter({ from: '', to: '' });
    }
  };

  const createdSendAll = async (e: React.MouseEvent) => {
    e.stopPropagation();

    const schedulesCreated = schedules?.data?.filter((el) => el.stage === 'Created');
    if (!schedulesCreated?.length) return;

    try {
      setSendToWorkersLoading(true);

      await Promise.all(
        schedulesCreated.map(async (schedule) => {
          await schedulesService.sendWorkRequests(schedule.unique);
        })
      );
    } finally {
      setSendToWorkersLoading(false);
      schedulesFilterModalRef.current?.refreshPage();
    }
  };

  const filledSendAllForApproval = async (e: React.MouseEvent, data: SchedulesModel[]) => {
    e.stopPropagation();

    if (!data.length) return;

    try {
      setSendToSupervisorsLoading(true);

      await Promise.all(
        data.map(async (schedule) => {
          await schedulesService.sendScheduleToSupervisors(schedule.unique);
        })
      );
    } finally {
      setSendToSupervisorsLoading(false);
      schedulesFilterModalRef.current?.refreshPage();
    }
  };

  const closeSchedule = (unique: number) => {
    confirmModalRef.current?.show(
      async () => {
        try {
          setLoading(true);

          await schedulesService.closeSchedule(unique);
          schedulesFilterModalRef.current?.refreshPage();
        } finally {
          setLoading(false);
          confirmModalRef.current?.hide();
        }
      },
      { title: 'Close Schedule', text: 'Do you really want to close this schedule? You will not be able to undo this.' }
    );
  };

  const deleteSchedule = (unique: number) => {
    confirmModalRef.current?.show(
      async () => {
        try {
          setLoading(true);

          await schedulesService.deleteSchedule(unique);
          schedulesFilterModalRef.current?.refreshPage();
        } finally {
          setLoading(false);
          confirmModalRef.current?.hide();
        }
      },
      {
        title: 'Delete Schedule',
        text: 'Do you really want to delete this schedule? You will not be able to undo this.',
      }
    );
  };

  // @ts-ignore
  // prettier-ignore
  const createdSchedulesData = schedules?.data?.filter((el) => el.stage === 'Created').sort((a, b) => new Date(a.dateS) - new Date(b.dateS)) ?? [];
  // @ts-ignore
  // prettier-ignore
  const sendSchedulesData = schedules?.data?.filter((el) => el.stage === 'Sent').sort((a, b) => new Date(a.dateS) - new Date(b.dateS)) ?? [];
  // @ts-ignore
  // prettier-ignore
  const acceptedSchedulesData = schedules?.data?.filter((el) => el.stage === 'Accepted').sort((a, b) => new Date(a.dateS) - new Date(b.dateS)) ?? [];
  // @ts-ignore
  // prettier-ignore
  const inProgressSchedulesData = schedules?.data?.filter((el) => el.stage === 'In progress').sort((a, b) => new Date(a.dateS) - new Date(b.dateS)) ?? [];
  // @ts-ignore
  // prettier-ignore
  const filledSchedulesData = schedules?.data?.filter((el) => el.stage === 'Filled').sort((a, b) => new Date(a.dateS) - new Date(b.dateS)) ?? [];
  // @ts-ignore
  // prettier-ignore
  const sendForApprovalSchedulesData = schedules?.data?.filter((el) => el.stage === 'Sent for approval').sort((a, b) => new Date(a.dateS) - new Date(b.dateS)) ?? [];
  // @ts-ignore
  // prettier-ignore
  const needRevisionSchedulesData = schedules?.data?.filter((el) => el.stage === 'Need revision').sort((a, b) => new Date(a.dateS) - new Date(b.dateS)) ?? [];
  // @ts-ignore
  // prettier-ignore
  const approvedSchedulesData = schedules?.data?.filter((el) => el.stage === 'Approved').sort((a, b) => new Date(a.dateS) - new Date(b.dateS)) ?? [];
  // @ts-ignore
  // prettier-ignore
  const closedSchedulesData = schedules?.data?.filter((el) => el.stage === 'Closed').sort((a, b) => new Date(a.dateS) - new Date(b.dateS)) ?? [];

  const handleCheckoutSchedules = async (e: any) => {
    e.stopPropagation();
    try {
      const promises = inProgressSchedulesData.map((schedule) => checkoutSchedule(schedule.unique));
      await axios.all(promises);
      // await checkoutSchedule(unique);
      dispatch(getSchedulesActions());
    } catch {
      // setSendLoading(false);
    }
  };
  return (
    <div className='schedules-page'>
      <UITitle
        title='Schedules'
        button={{
          text: 'Add Schedules',
          color: 'primary',
          icon: 'plus',
          onClick: () => history.push(`/schedules/create?date=${dates.find((item) => item.checked)?.value}`),
          disabled: dates.find((item) => item.checked)?.value === 'yesterday' ? true : false,
        }}
      >
        <Stack direction='row' spacing={2}>
          <div className='schedules-page__date-wrapper'>
            {dates.map((option) => (
              <label
                key={option.value}
                className={cn('schedules-page__date', {
                  checked: option.checked,
                })}
              >
                <input
                  type='checkbox'
                  className='schedules-page__date-input'
                  value={`${option.value}`}
                  checked={option.checked}
                  onChange={(e) => changeOptions(option.value, e.target.checked)}
                  disabled={option.checked}
                />
                {option.label}
              </label>
            ))}
          </div>
          <UIButton
            count={countOfFilters}
            text='Filter'
            icon='filter'
            color='white'
            onClick={() => schedulesFilterModalRef.current?.show()}
          />
        </Stack>
      </UITitle>
      <UIAccordion
        loading={schedulesLoading}
        data={schedules?.data}
        tabs={[
          {
            name: 'created',
            summary: {
              title: 'Created',
              count: { count: createdSchedulesData.length, color: 'blue' },
              rightContent: !!createdSchedulesData.length && (
                <UIButton loading={sendToWorkersLoading} text='Send all' onClick={createdSendAll} />
              ),
            },
            children: (
              <div className='schedules-page__schedules'>
                {createdSchedulesData.map((el, index) => (
                  <ScheduleItem
                    key={index}
                    index={index}
                    allData={createdSchedulesData}
                    data={el}
                    className='schedules-page__schedules--item'
                    deleteSchedule={deleteSchedule}
                    closeSchedule={closeSchedule}
                  />
                ))}
              </div>
            ),
          },
          {
            name: 'sent',
            summary: {
              title: 'Sent',
              count: { count: sendSchedulesData.length, color: 'pink' },
            },
            children: (
              <div className='schedules-page__schedules'>
                {sendSchedulesData.map((el, index) => (
                  <ScheduleItem
                    key={index}
                    index={index}
                    allData={sendSchedulesData}
                    data={el}
                    className='schedules-page__schedules--item'
                    deleteSchedule={deleteSchedule}
                    closeSchedule={closeSchedule}
                  />
                ))}
              </div>
            ),
          },
          {
            name: 'accepted',
            summary: {
              title: 'Accepted',
              count: { count: acceptedSchedulesData.length, color: 'primary' },
            },
            children: (
              <div className='schedules-page__schedules'>
                {acceptedSchedulesData.map((el, index) => (
                  <ScheduleItem
                    key={index}
                    index={index}
                    allData={acceptedSchedulesData}
                    data={el}
                    className='schedules-page__schedules--item'
                    deleteSchedule={deleteSchedule}
                    closeSchedule={closeSchedule}
                  />
                ))}
              </div>
            ),
          },
          {
            name: 'in-progress',
            summary: {
              title: 'In Progress',
              count: { count: inProgressSchedulesData.length, color: 'warning' },
              rightContent: inProgressSchedulesData.length > 0 && (
                <UIButton color='danger' text='Check-out' onClick={handleCheckoutSchedules} />
              ),
            },
            children: (
              <div className='schedules-page__schedules'>
                {inProgressSchedulesData.map((el, index) => (
                  <ScheduleItem
                    key={index}
                    index={index}
                    allData={inProgressSchedulesData}
                    data={el}
                    className='schedules-page__schedules--item'
                    deleteSchedule={deleteSchedule}
                    closeSchedule={closeSchedule}
                  />
                ))}
              </div>
            ),
          },
          {
            name: 'filled',
            summary: {
              title: 'Filled',
              count: { count: filledSchedulesData.length, color: 'purple' },
              rightContent: !!filledSchedulesData.length && (
                <UIButton
                  loading={sendToSupervisorsLoading}
                  text='Send All for Approval'
                  onClick={(e: any) => filledSendAllForApproval(e, filledSchedulesData)}
                />
              ),
            },
            children: (
              <div className='schedules-page__schedules'>
                {filledSchedulesData.map((el, index) => (
                  <ScheduleItem
                    key={index}
                    hideEditBtn
                    index={index}
                    allData={filledSchedulesData}
                    data={el}
                    className='schedules-page__schedules--item'
                    deleteSchedule={deleteSchedule}
                    closeSchedule={closeSchedule}
                  />
                ))}
              </div>
            ),
          },
          {
            name: 'sent-for-approval',
            summary: {
              title: 'Waiting for approval',
              count: {
                count: sendForApprovalSchedulesData.length,
                color: 'warning',
              },
            },
            children: (
              <div className='schedules-page__schedules'>
                {sendForApprovalSchedulesData.map((el, index) => (
                  <ScheduleItem
                    key={index}
                    hideEditBtn
                    index={index}
                    allData={sendForApprovalSchedulesData}
                    data={el}
                    className='schedules-page__schedules--item'
                    deleteSchedule={deleteSchedule}
                    closeSchedule={closeSchedule}
                  />
                ))}
              </div>
            ),
          },
          {
            name: 'need-revision',
            summary: {
              title: 'Need revision',
              count: {
                count: needRevisionSchedulesData.length,
                color: 'blue',
              },
              rightContent: !!needRevisionSchedulesData.length && (
                <UIButton
                  text='Send All for Approval'
                  onClick={(e: any) => filledSendAllForApproval(e, needRevisionSchedulesData)}
                />
              ),
            },
            children: (
              <div className='schedules-page__schedules'>
                {needRevisionSchedulesData.map((el, index) => (
                  <ScheduleItem
                    key={index}
                    hideEditBtn
                    index={index}
                    allData={needRevisionSchedulesData}
                    data={el}
                    className='schedules-page__schedules--item'
                    deleteSchedule={deleteSchedule}
                    closeSchedule={closeSchedule}
                  />
                ))}
              </div>
            ),
          },
          {
            name: 'approved',
            summary: {
              title: 'Approved',
              count: { count: approvedSchedulesData.length, color: 'black' },
            },
            children: (
              <div className='schedules-page__schedules'>
                {approvedSchedulesData.map((el, index) => (
                  <ScheduleItem
                    hideEditBtn
                    key={index}
                    index={index}
                    allData={approvedSchedulesData}
                    data={el}
                    className='schedules-page__schedules--item'
                    deleteSchedule={deleteSchedule}
                    closeSchedule={closeSchedule}
                  />
                ))}
              </div>
            ),
          },
          {
            name: 'closed',
            summary: {
              title: 'Closed',
              count: { count: closedSchedulesData.length, color: 'red' },
            },
            children: (
              <div className='schedules-page__schedules'>
                {closedSchedulesData.map((el, index) => (
                  <ScheduleItem
                    key={index}
                    hideEditBtn
                    index={index}
                    allData={closedSchedulesData}
                    data={el}
                    className='schedules-page__schedules--item'
                    deleteSchedule={deleteSchedule}
                  />
                ))}
              </div>
            ),
          },
        ]}
      />
      <SchedulesFilterModal ref={schedulesFilterModalRef} limit={10} />
      <ConfirmModal ref={confirmModalRef} title='Delete' loading={loading} />
    </div>
  );
};

export default SchedulesPage;
