import { CircularProgress, Stack } from '@mui/material';
import { encodeBase64 } from 'app/helpers/toBase64';
import { ForemanReportModel } from 'app/models/ForemanReportModel';
import { ReactComponent as TickCircleIcon } from 'assets/icons/primary/tick-circle.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/table/calendar.svg';
import { FC, useRef } from 'react';
import { Link } from 'react-router-dom';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';
import ReportsPlaceholder from 'shared/Modals/ForemanReportsModal/components/Placeholder/Placeholder';
import './DailyReport.sass';

type foremanReportType = {
  title: string;
  type: string;
  data: ForemanReportModel | null;
};
interface Props {
  report: foremanReportType;
  loading: boolean;
}

const ForemanDailyReport: FC<Props> = ({ report, loading }) => {
  const previewImageModalRef = useRef<PreviewImageModalRef>(null);
  const { data } = report;


  return (
    <>
      {loading ? (
        <div className='daily-report__loading'>
          <CircularProgress />
        </div>
      ) : !data ? (
        <div className='daily-report__empty'>
          <Stack alignItems={'baseline'} direction='row' gap='18px'>
            <p className='daily-report__reported-title'>{report.title}</p>
          </Stack>
          <ReportsPlaceholder
            className='daily-report__empty--placeholder'
            icon={<CalendarIcon />}
            title='Sorry, it is empty here!'
            subtitle={`The ${report.title} is empty. It will be displayed here.`}
          />
        </div>
      ) : (
        <div>
          <div className='daily-report__cards'>
            <Stack alignItems={'baseline'} direction='row' gap='18px'>
              <p className='daily-report__reported-title'>{report.title}:</p>
              <p className='daily-report__reported-name'>{report?.data?.createdAt}</p>
            </Stack>
            {data?.description && <p className='daily-report__card-description'>{data.description}</p>}
          </div>
          {!!data?.files.length && (
            <div className='daily-report__attachments'>
              <div className='daily-report__attachments-wrapper'>
                {data.files?.map((image, idx) => (
                  <img
                    className='daily-report__attachments-image'
                    src={encodeBase64(image.file, 'jpg')}
                    alt={`${idx}`}
                    onClick={() =>
                      previewImageModalRef.current?.show({
                        file: {
                          name: `attachment-${image.unique}-${idx}`,
                          unique: image.unique,
                          extension: image.extension || 'jpg',
                        },
                      })
                    }
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      <PreviewImageModal ref={previewImageModalRef} />
    </>
  );
};

export default ForemanDailyReport;
