import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { UIDatePickerProps } from './types';

const UIDatePicker: React.FC<UIDatePickerProps> = ({
  value,
  onChange,
  invalid,
  onBlur,
  helperText,
  disableFuture,
  disablePast,
}) => {
  const dateFormat = 'DD MMM, YYYY';
  const [date, setDate] = useState<Date | null>(value ?? null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setDate(value ?? null);
  }, [value]);

  const onChangeHandler = (date: Date | null, _inputValue?: string) => {
    setDate(date);
    onChange?.(date, dayjs(date).format(dateFormat));
  };

  return (
    <DesktopDatePicker
      label=''
      open={open}
      inputFormat='DD MMM, YYYY'
      value={date}
      onClose={() => setOpen(false)}
      onChange={onChangeHandler}
      disablePast={disablePast}
      disableFuture={disableFuture}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            // onBlur={onBlur}
            // value={inputValue}
            // onChange={(e) => {
            //   setInputValue('');
            //   if (!e.target.value) {
            //     onChangeHandler(null);
            //   }
            // }}
            onClick={() => setOpen(!open)}
            helperText={helperText}
            error={invalid}
          />
        );
      }}
    />
  );
};

export default UIDatePicker;
