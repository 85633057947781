import { Menu } from '@mui/material';
import cn from 'app/helpers/cn';
import { useAppSelector } from 'app/hooks';
import NavigationIconModel from 'app/models/NavigationIconModel';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import EditProfileModal from 'shared/Modals/EditProfileModal/EditProfileModal';
import { EditProfileModalRef } from 'shared/Modals/EditProfileModal/types';
import { ModalBaseRef } from 'shared/Modals/types';
import Notifications from 'shared/Notifications/Notifications';
import UIButton from 'shared/ui/UIButton/UIButton';
import { signOut } from 'store/slices/auth';
import HeaderItem from './components/HeaderItem/HeaderItem';
import './Header.sass';
import NAVDATA from './NAVDATA';

const Header: React.FC = () => {
  const dispatch = useDispatch();

  const confirmModalRef = useRef<ModalBaseRef>(null);
  const headerRef = useRef<HTMLHeadElement | null>(null);
  const [accountMenuAnchor, setAccountMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [notificationMenuAnchor, setNotificationMenuAnchor] = React.useState<null | HTMLElement>(null);
  const editProfileModalRef = useRef<EditProfileModalRef>(null);

  const { user } = useAppSelector((state) => state.auth);
  const { notificationCounter } = useAppSelector((state) => state.notifications);

  useEffect(() => {
    if (!!accountMenuAnchor || !!notificationMenuAnchor) {
      headerRef.current?.classList.add('header-z');
    } else {
      setTimeout(() => {
        headerRef.current?.classList.remove('header-z');
      }, 200);
    }
  }, [accountMenuAnchor, notificationMenuAnchor]);

  const renderNavElement = (item: NavigationIconModel, index: number) => {
    return <HeaderItem item={item} key={index} />;
  };

  const logout = () => {
    setAccountMenuAnchor(null);

    confirmModalRef.current?.show(() => {
      dispatch(signOut());
    });
  };

  const openMenu = (e: React.MouseEvent<HTMLDivElement>, type: 'nitification' | 'account') => {
    switch (type) {
      case 'account':
        setNotificationMenuAnchor(null);
        setAccountMenuAnchor(e.currentTarget);
        break;
      case 'nitification':
        setAccountMenuAnchor(null);
        setNotificationMenuAnchor(e.currentTarget);
        break;
    }
  };

  return (
    <>
      <header ref={headerRef} className={cn('header')}>
        <nav className='header__nav'>{NAVDATA.map(renderNavElement)}</nav>
        <div className='header__right'>
          <div
            className={cn('header-item', {
              'header-item__active': notificationMenuAnchor,
            })}
            onClick={(e) => openMenu(e, 'nitification')}
          >
            <div className='header-item__inner'>
              {!!notificationCounter && <div className='header-item__inner-counter'>{notificationCounter}</div>}
              <NotificationsIcon className='header-item__icon' />
              <div className='header-item__label'>Notifications</div>
            </div>
          </div>
          <div
            className={cn('header-item', {
              'header-item__active': accountMenuAnchor,
            })}
            onClick={(e) => openMenu(e, 'account')}
          >
            <div className='header-item__inner'>
              <ProfileIcon className='header-item__icon' />
              <div className='header-item__label'>Account</div>
            </div>
          </div>
        </div>
      </header>
      <Menu
        anchorEl={accountMenuAnchor}
        open={!!accountMenuAnchor}
        onClose={() => setAccountMenuAnchor(null)}
        classes={{
          root: 'header__account',
          paper: 'header__account-paper',
          list: 'header__account-list',
        }}
      >
        <div className='header__account-block'>
          <div className='header__account-name-wrapper'>
            {user?.name && <h3 className='header__account-name'>{user.name}</h3>}
            {user?.email && <div className='header__account-username'>{user.email}</div>}
          </div>
          <div
            className='header__account-edit'
            onClick={() => {
              setAccountMenuAnchor(null);
              editProfileModalRef.current?.show();
            }}
          >
            <EditIcon /> Edit Profile
          </div>
          <UIButton
            className='header__account-logout'
            text='Log out'
            icon='logout'
            color='light-gray'
            onClick={logout}
          />
        </div>
      </Menu>
      <Menu
        anchorEl={notificationMenuAnchor}
        open={!!notificationMenuAnchor}
        onClose={() => setNotificationMenuAnchor(null)}
        classes={{
          root: 'header__notification-modal',
          paper: 'header__notification-modal-paper',
          list: 'header__notification-modal-list',
        }}
      >
        <Notifications />
      </Menu>
      <EditProfileModal ref={editProfileModalRef} />
      <ConfirmModal ref={confirmModalRef} title='Log out' submitBtnText='Confirm'>
        Do you really want to log out from your account now? <br /> You can sign in later when you need
      </ConfirmModal>
    </>
  );
};

export default Header;
