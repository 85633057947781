export const getFileShortName = (text = '', limit = 30): string => {
  if (text.length <= limit) return text;

  const extension = text.slice(-4) === 'jpeg' ? text.slice(-4) : text.slice(-3);

  return `${text.slice(0, limit - 8)}....${extension}`;
};

export const getFileExtension = (name: string) => {
  const arr = name.split('.');

  return arr[arr.length - 1];
};
