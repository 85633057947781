import React from 'react';
import './HeaderItem.sass';
import NavigationIconModel from 'app/models/NavigationIconModel';
import { Link, useLocation } from 'react-router-dom';
import cn from 'app/helpers/cn';

interface Props {
  item: NavigationIconModel;
}

const HeaderItem: React.FC<Props> = ({ item }) => {
  const { pathname } = useLocation();
  const isActive = item.to.replace('/', '') ? pathname.includes(item.to) : item.to === pathname;

  let Icon = isActive ? item.activeIcon : item.icon;

  return (
    <Link
      to={item.to}
      className={cn('header-item', {
        'header-item__active': isActive,
      })}
    >
      <div className='header-item__inner'>
        <Icon className='header-item__icon' />
        <div className='header-item__label'>{item.label}</div>
      </div>
    </Link>
  );
};

export default HeaderItem;
