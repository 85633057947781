import React from 'react';
import './UIInput.sass';
import { TextField } from '@mui/material';
import { UIInputProps } from './types';
import cn from 'app/helpers/cn';

const UIInput: React.ForwardRefRenderFunction<{}, UIInputProps> = ({ className = '', ...rest }, ref) => {
  return (
    <TextField
      {...rest}
      classes={{
        root: cn('ui-input', {
          'ui-input--with-icon': rest.InputProps?.endAdornment,
          [className]: className,
        }),
      }}
    />
  );
};

export default React.forwardRef(UIInput);
