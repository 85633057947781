import React, { useImperativeHandle, useRef, useState } from 'react';
import { schedulesService } from 'api';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { ReportsModalRef, ReportsModalProps } from './types';
import { useAppSelector } from 'app/hooks';
import UITabs from 'shared/ui/UITabs/UITabs';
import DailyReport from './components/DailyReport/DailyReport';
import DailyLogs from './components/DailyLogs/DailyLogs';
import EditDailyReportModal from 'shared/Modals/EditDailyReportModal/EditDailyReportModal';
import { EditDailyReportModalRef } from 'shared/Modals/EditDailyReportModal/types';
import { DailyReportModel } from 'app/models/DailyReportModel';
import './ReportsModal.sass';
import UIButton from 'shared/ui/UIButton/UIButton';

const ReportsModal: React.ForwardRefRenderFunction<ReportsModalRef, ReportsModalProps> = (_, ref) => {
  const { currentSchedule } = useAppSelector((state) => state.schedules);

  const [dailyReport, setDailyReport] = useState<DailyReportModel | null>(null);

  const [saveLoading, setSaveLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('daily-report');

  const editDailyReportModalRef = useRef<EditDailyReportModalRef>(null);

  const getDailyReport = async (unique: number) => {
    try {
      setLoading(true);

      const { data } = await schedulesService.getDailyReport({ unique });
      setDailyReport(data.data);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      show: (unique) => {
        setDailyReport(null);

        if (unique) {
          getDailyReport(unique);
        }

        setOpen(true);
      },
      hide: () => {
        setOpen(false);
      },
    }),
    []
  );

  const downloadDailyReport = async () => {
    if (!currentSchedule?.dailyReportId) return;
    setSaveLoading(true);

    schedulesService
      .getDailyReport({ unique: currentSchedule.dailyReportId, download: true })
      .then(({ data: pdfFile }) => {
        // @ts-ignore
        const href = URL.createObjectURL(pdfFile);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${currentSchedule.dailyReportId}-daily-report.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .finally(() => {
        setSaveLoading(false);
        setOpen(false);
      });
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'reports-modal',
        paper: 'reports-modal__paper',
      }}
      PaperProps={{
        sx: { maxWidth: '760px', width: '100%' },
      }}
    >
      <SubmitModalLayout
        title='Reports'
        onClose={() => setOpen(false)}
        onSubmit={downloadDailyReport}
        submitBtnText={dailyReport ? 'Download' : null}
        cancelBtnText={null}
        loading={saveLoading}
        rightComponent={
          dailyReport &&
          currentTab === 'daily-report' && (
            <UIButton
              text='Edit'
              color='secondary'
              type='button'
              onClick={() => editDailyReportModalRef.current?.show(dailyReport)}
            />
          )
        }
      >
        <UITabs
          replaceUrl={false}
          onChange={(_, value) => setCurrentTab(value)}
          tabs={[
            {
              tab: 'daily-report',
              label: 'Daily Report',
              component: <DailyReport data={dailyReport} loading={loading} />,
            },
            {
              tab: 'daily-logs',
              label: 'Daily Logs',
              component: <DailyLogs />,
            },
          ]}
        />
      </SubmitModalLayout>
      <EditDailyReportModal
        ref={editDailyReportModalRef}
        getData={() => getDailyReport(currentSchedule?.dailyReportId!)}
      />
    </UIModal>
  );
};

export default React.forwardRef(ReportsModal);
