import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-right.svg';
import React from 'react';
import Status from '../Status/Status';
import { UIAccordionProps } from './types';
import './UIAccordion.sass';

function UIAccordion<T>({ data, tabs, loading, rightCorner }: UIAccordionProps<T>) {
  if (loading) {
    return (
      <div className='ui-accordion__loader'>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className='ui-accordion'>
      {tabs.map((tab, index) => (
        <React.Fragment key={index}>
          <Accordion
            id={tab.name}
            disabled={tab.disabled}
            classes={{ root: 'ui-accordion__item' }}
            // defaultExpanded={tab.defaultExpanded}
            defaultExpanded={tab.summary.count?.count! > 0}
            elevation={0}
            sx={{
              '&.MuiPaper-root': {
                // border: 'none !important',
                borderRadius: '10px',
                margin: '16px 0',
                border: '1px solid #EDEFF0',
                '&::before': {
                  display: 'none',
                },
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowIcon className='ui-accordion__item--arrow' />}
              classes={{ root: 'ui-accordion__item--summary' }}
              sx={{
                flexDirection: 'row-reverse',
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(90deg)',
                },
                '& .MuiAccordionSummary-content': {
                  margin: '20px !important',
                },
              }}
            >
              <div className='ui-accordion__item--content'>
                <div className='ui-accordion__item--title'>
                  {tab.summary.title}
                  {tab.summary.count && (
                    <Status text={String(tab.summary.count.count)} color={tab.summary.count.color} />
                  )}
                </div>
                {tab.summary.rightContent && tab.summary.rightContent}
              </div>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                margin: '12px',
              }}
            >
              {tab.renderDetails?.(data) || tab.children}
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      ))}
    </div>
  );
}

export default UIAccordion;
