import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from 'shared/ui/BackButton/BackButton';
import SingleDetail from 'shared/ui/SingleDetail/SingleDetail';
import UITabs from 'shared/ui/UITabs/UITabs';
import { selectCompany } from 'store/slices/companies';
import './CompanyDetailsPage.sass';
import EmployeesDetails from './components/EmployeesDetails/EmployeesDetails';
import FilesDetails from './components/FilesDetails/FilesDetails';
import RateDetails from './components/RateDetails/RateDetails';

const CompanyDetailsPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { selectedCompany } = useAppSelector((state) => state.companies);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!id) return;
    dispatch(selectCompany({ unique: +id }));
  }, [id]);

  return (
    <div className='company-details'>
      <BackButton label="View Company's Details" />
      {selectedCompany && (
        <div className='company-details__details'>
          <SingleDetail
            title='Company Name'
            className='company-details__details--single-detail'
            icon={ProfileIcon}
            label={`${selectedCompany.name}`}
          />
          {selectedCompany?.address[0] && (
            <SingleDetail
              title='Address'
              className='company-details__details--single-detail'
              icon={LocationIcon}
              label={selectedCompany.address[0].description}
            />
          )}
          {selectedCompany.phone && (
            <SingleDetail
              title='Phone'
              className='company-details__details--single-detail'
              icon={PhoneIcon}
              label={selectedCompany.phone}
            />
          )}
          {selectedCompany.email && (
            <SingleDetail
              title='Email'
              className='company-details__details--single-detail'
              icon={MailIcon}
              label={selectedCompany.email}
            />
          )}
        </div>
      )}
      <UITabs
        tabs={[
          { tab: 'employees', label: 'Employees', component: <EmployeesDetails /> },
          { tab: 'files', label: 'Files', component: <FilesDetails /> },
          { tab: 'rates', label: 'Rates', component: <RateDetails /> },
        ]}
      />
    </div>
  );
};

export default CompanyDetailsPage;
