/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useRef } from 'react';

export default (func: Function, deps: any[]) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};
