import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from 'app/models/AuthModel';
import history from 'app/routes/history';
import { AuthState } from './types';

const initialState: AuthState = {
  authorized: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<{ token: string; user: UserModel }>) => {
      const { token, user } = action.payload;

      localStorage.setItem('token', token);
      if (user) localStorage.setItem('user', JSON.stringify(user));

      state.token = token;
      state.authorized = true;
      state.user = user;
    },
    signOut: (state) => {
      localStorage.removeItem('token');
      state.token = '';
      state.authorized = false;
      history.push('/login');
    },
    setUser: (state, action: PayloadAction<UserModel>) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.user = action.payload;
    },
  },
});

export const { signIn, signOut, setUser } = authSlice.actions;

export const authReducer = authSlice.reducer;
