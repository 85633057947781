import { FormControl, FormLabel, Stack, Switch } from '@mui/material';
import { payrollsService } from 'api';
import { AddPayrollBody } from 'api/payrolls/types';
import { useAppDispatch } from 'app/hooks';
import PayrollFillDataModel from 'app/models/PayrollFillDataModel';
import history from 'app/routes/history';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import FormDateInput from 'shared/Inputs/FormDateInput/FormDateInput';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import BackButton from 'shared/ui/BackButton/BackButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import UITabs from 'shared/ui/UITabs/UITabs';
import { getPayrolls } from 'store/slices/payrolls';
import './AddPayrollPage.sass';
import WorkersAndBills from './components/WorkersAndBills/WorkersAndBills';

interface Props {
  mode?: 'edit';
}

interface SubmitFillData {
  payrollStartDate: Date | null;
  payrollStartDate1: Date | null;
  payrollEndDate: Date | null;
  payrollPaymentDate: Date | null;
  payrollComment: string;
}

const AddPayrollPage: React.FC<Props> = ({ mode }) => {
  const dateFormat = 'DD/MM/YYYY';
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { control, getValues, handleSubmit, setValue, setError } = useForm<SubmitFillData>();
  const [billsList, setBillsList] = useState<PayrollFillDataModel[]>([]);
  const [billsListLoading, setBillsListLoading] = useState(false);
  const [paid, setPaid] = useState(false);

  useEffect(() => {
    if (mode === 'edit') {
      getDataToEdit();
    }
  }, [mode]);

  const getDataToEdit = async () => {
    if (!id) return;
    const { data } = await payrollsService.getPayroll(+id).then((res) => res.data);
    setValue(
      'payrollStartDate',
      dayjs(data.startDate, dateFormat).isValid() ? dayjs(data.startDate, dateFormat).toDate() : null
    );
    setValue(
      'payrollEndDate',
      dayjs(data.endDate, dateFormat).isValid() ? dayjs(data.endDate, dateFormat).toDate() : null
    );
    setValue(
      'payrollPaymentDate',
      dayjs(data.paymentDate, dateFormat).isValid() ? dayjs(data.paymentDate, dateFormat).toDate() : null
    );
    setValue('payrollComment', data.comment ?? '');
    setPaid(data.paid);
    setBillsList(data.bills);
  };

  const fillTableHandler: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const { payrollStartDate, payrollEndDate } = getValues();

    setError('payrollStartDate', { message: !payrollStartDate ? 'Please fill Start Date' : '' });
    setError('payrollEndDate', { message: !payrollEndDate ? 'Please fill End Date' : '' });

    if (!payrollStartDate || !payrollEndDate) return;

    try {
      setBillsListLoading(true);

      const { data } = await payrollsService
        .fillPayroll(dayjs(payrollStartDate).format('DD/MM/YYYY'), dayjs(payrollEndDate).format('DD/MM/YYYY'))
        .then((res) => res.data);

      setBillsList(data);
    } finally {
      setBillsListLoading(false);
    }
  };

  const onClearTableHandler = () => setBillsList([]);

  const onSubmitHandler: React.FormEventHandler<HTMLFormElement> = (event) => {
    handleSubmit(async (data) => {
      if (!billsList.length) return alert('Bills list is empty');

      try {
        const payrollData: AddPayrollBody = {
          startDate: dayjs(data.payrollStartDate).format('DD/MM/YYYY'),
          endDate: dayjs(data.payrollEndDate).format('DD/MM/YYYY'),
          paymentDate: dayjs(data.payrollPaymentDate).format('DD/MM/YYYY'),
          paid,
          comment: data.payrollComment ?? '',
          bills: billsList.map((el) => ({
            unique: el.unique,
            totalHours: el.totalHours,
            name: el.name,
            rate: el.rate,
            totalSum: el.totalSum,
          })),
        };

        if (mode === 'edit' && id) {
          await payrollsService.editPayroll({
            unique: +id,
            ...payrollData,
          });
        } else {
          await payrollsService.addPayroll(payrollData);
        }

        dispatch(getPayrolls({ force: true }));
        history.push('/payrolls');
      } finally {
      }
    })(event);
  };

  return (
    <div className='add-payroll-page'>
      <BackButton label={mode === 'edit' ? 'Edit Payroll' : 'Add Payroll'} />
      <div className='add-payroll-page__blocks'>
        <form className='add-payroll-page__block' onSubmit={fillTableHandler}>
          <Stack direction='row' spacing={2}>
            <FormControl>
              <FormLabel required>From</FormLabel>
              <FormDateInput name='payrollStartDate' control={control} />
            </FormControl>
            <FormControl>
              <FormLabel required>To</FormLabel>
              <FormDateInput name='payrollEndDate' control={control} />
            </FormControl>
          </Stack>
          <UIButton
            className='add-payroll-page__fill-the-table'
            color='primary'
            text='Fill the table'
            loading={billsListLoading}
          />
        </form>
        <div className='add-payroll-page__block'>
          <FormControl>
            <FormLabel required>Payment Date</FormLabel>
            <FormDateInput
              name='payrollPaymentDate'
              control={control}
              rules={{ required: 'Please fill Payment Date' }}
            />
          </FormControl>
          <div>
            <FormControl>
              <div className='add-payroll-page__paid'>
                Paid
                <Switch checked={paid} onChange={(_, checked) => setPaid(checked)} />
              </div>
            </FormControl>
          </div>
        </div>
        <div className='add-payroll-page__block'>
          <FormControl>
            <FormLabel>Comment</FormLabel>
            <FormTextInput multiline control={control} name='payrollComment' />
          </FormControl>
        </div>
      </div>
      <UITabs
        tabs={[
          {
            tab: 'workers-and-bills',
            label: 'Workers & Bills',
            component: (
              <WorkersAndBills
                data={billsList}
                loading={billsListLoading}
                onClear={onClearTableHandler}
                onSubmit={onSubmitHandler}
                setBillsList={setBillsList}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default AddPayrollPage;
