import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect } from 'react';
import UITabs from 'shared/ui/UITabs/UITabs';
import UITitle from 'shared/ui/UITitle/UITitle';
import {
  getEmployeesStatuses,
  getMaritalStatuses,
  getWebQualifications,
  getWebStates,
  getWorkerTypes,
} from 'store/slices/staff';
import Crew from './components/Crew/Crew';
import Employees from './components/Employees/Employees';
import './StaffPage.sass';

const StaffPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { employees } = useAppSelector((state) => state.staff);

  useEffect(() => {
    dispatch(getEmployeesStatuses());
    dispatch(getWorkerTypes());
    dispatch(getMaritalStatuses());
    dispatch(getWebStates());
    dispatch(getWebQualifications());
  }, [dispatch]);

  return (
    <div className='staff-page'>
      <UITitle title='Staff' />
      {employees && (
        <UITabs
          tabs={[
            { tab: 'employess', label: 'Employees', component: <Employees /> },
            { tab: 'crew', label: 'Crew', component: <Crew /> },
          ]}
        />
      )}
    </div>
  );
};

export default StaffPage;
