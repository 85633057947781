import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StaffService } from 'api';
import { GetCrewsBody, GetCrewsResponse, GetEmployeeFilesBody, GetEmployeesBody } from 'api/staff/types';
import { RootState } from 'store/store';
import { StaffState } from './types';
import { EmployeeDetailsModal } from 'app/models/StaffModel';

const initialState: StaffState = {
  employees: {
    data: [],
  },
  crews: {
    data: [],
  },
};

export const getEmployees = createAsyncThunk(
  'staff/getEmployees',
  async (payload: { force?: boolean; concat?: boolean; params?: GetEmployeesBody } | undefined, ThunkApi) => {
    const { employees } = (ThunkApi.getState() as RootState).staff as StaffState;
    if (employees.data.length && !payload?.force) return employees;

    ThunkApi.dispatch(setEmployeeFilter(payload?.params));
    const employeesResponse = await StaffService.getEmployees(payload?.params).then((res) => res.data);

    if (payload?.concat) {
      employeesResponse.data = [...employees.data, ...employeesResponse.data];
    }

    return employeesResponse;
  }
);

export const getEmployee = createAsyncThunk('staff/getEmployee', async (payload: number) => {
  return await StaffService.getEmployee(payload).then((res) => res.data.data);
});

export const getEmployeeFiles = createAsyncThunk(
  'staff/getEmployeeFiles',
  async (payload: { params?: GetEmployeeFilesBody }, ThunkApi) => {
    ThunkApi.dispatch(setEmployeeFilesFilter(payload.params));
    return await StaffService.getEmployeeFiles(payload.params).then((res) => res.data);
  }
);

export const getEmployeesStatuses = createAsyncThunk(
  'staff/getEmployeesStatuses',
  async (force: boolean | undefined, ThunkApi) => {
    const { statuses } = (ThunkApi.getState() as RootState).staff as StaffState;
    if (statuses?.length && !force) return statuses;

    return await StaffService.getEmployeeStatuses().then((res) => res.data.data);
  }
);

export const getWorkerTypes = createAsyncThunk('staff/getWorkerTypes', async (force: boolean | undefined, ThunkApi) => {
  const { workerTypes } = (ThunkApi.getState() as RootState).staff as StaffState;
  if (workerTypes?.length && !force) return workerTypes;

  return await StaffService.getWorkerTypes().then((res) => res.data.data);
});

export const getCrews = createAsyncThunk(
  'staff/getCrews',
  async (payload: { force?: boolean; concat?: boolean; params?: GetCrewsBody } | undefined, ThunkApi) => {
    const { crews } = (ThunkApi.getState() as RootState).staff as StaffState;
    if (crews?.data.length && !payload?.force) return crews;

    const crewsResponse = await StaffService.getCrews(payload?.params).then((res) => res.data);

    if (payload?.concat) {
      crewsResponse.data = [...(crews?.data ?? []), ...crewsResponse.data];
    }

    return crewsResponse;
  }
);

export const getCrew = createAsyncThunk('staff/getCrew', async (payload: number, ThunkApi) => {
  return await StaffService.getCrew(payload).then((response) => response.data.data);
});

export const getMaritalStatuses = createAsyncThunk(
  'staff/getMaritalStatuses',
  async (force: boolean | undefined, ThunkApi) => {
    const { maritalStatuses } = (ThunkApi.getState() as RootState).staff as StaffState;
    if (maritalStatuses?.length && !force) return maritalStatuses;

    return await StaffService.getMaritalStatuses().then((res) => res.data.data);
  }
);

export const getWebStates = createAsyncThunk('staff/getWebStates', async (force: boolean | undefined, ThunkApi) => {
  const { webStates } = (ThunkApi.getState() as RootState).staff as StaffState;
  if (webStates?.length && !force) return webStates;

  return await StaffService.getWebStates().then((res) => res.data.data);
});

export const getWebQualifications = createAsyncThunk(
  'staff/getWebQualifications',
  async (force: boolean | undefined, ThunkApi) => {
    const { webQualifications } = (ThunkApi.getState() as RootState).staff as StaffState;
    if (webQualifications?.length && !force) return webQualifications;

    return await StaffService.getWebQualifications().then((res) => res.data.data);
  }
);

export const getWebLicenseTypes = createAsyncThunk(
  'staff/getWebLicenseTypes',
  async (force: boolean | undefined, ThunkApi) => {
    const { webLicenseTypes } = (ThunkApi.getState() as RootState).staff as StaffState;
    if (webLicenseTypes?.length && !force) return webLicenseTypes;

    return await StaffService.getWebLicenseTypes().then((res) => res.data.data);
  }
);

export const getWebLicenseStatuses = createAsyncThunk(
  'staff/getWebLicenseStatuses',
  async (force: boolean | undefined, ThunkApi) => {
    const { webLicenseTypes } = (ThunkApi.getState() as RootState).staff as StaffState;
    if (webLicenseTypes?.length && !force) return webLicenseTypes;

    return await StaffService.getWebLicenseStatuses().then((res) => res.data.data);
  }
);

export const getLicense = createAsyncThunk('staff/getLicense', async (payload: number) => {
  return await StaffService.getLicense(payload).then((response) => response.data.data);
});

export const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    setEmployeeFilesFilter: (state, action: PayloadAction<GetEmployeeFilesBody | undefined>) => {
      state.currentEmployeeFilesFilters = action.payload;
    },
    setEmployeeFilter: (state, action: PayloadAction<GetEmployeesBody | undefined>) => {
      state.employeesFilters = action.payload;
    },
    setCurrentEmployee: (state, action: PayloadAction<EmployeeDetailsModal | undefined>) => {
      state.currentEmployee = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.pending, (state) => {
        state.employeesLoading = true;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.employeesLoading = false;
        state.employees = action.payload;
      })
      .addCase(getEmployee.pending, (state) => {
        state.currentEmployeeLoading = true;
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.currentEmployeeLoading = false;
        state.currentEmployee = action.payload;
      })
      .addCase(getEmployeeFiles.pending, (state) => {
        state.currentEmployeeFilesLoading = true;
      })
      .addCase(getEmployeeFiles.fulfilled, (state, action) => {
        state.currentEmployeeFilesLoading = false;
        state.currentEmployeeFiles = action.payload;
      })
      .addCase(getEmployeesStatuses.fulfilled, (state, action) => {
        state.statuses = action.payload.filter((i) => !(i.id === 'needApproval' || i.id === 'needAttention'));
      })
      .addCase(getWorkerTypes.fulfilled, (state, action) => {
        state.workerTypes = action.payload;
      })
      .addCase(getCrews.pending, (state) => {
        state.crewsLoading = true;
      })
      .addCase(getCrews.fulfilled, (state, action) => {
        state.crewsLoading = false;
        state.crews = action.payload as GetCrewsResponse;
      })
      .addCase(getCrew.fulfilled, (state, action) => {
        state.currentCrew = action.payload;
      })
      .addCase(getMaritalStatuses.fulfilled, (state, action) => {
        state.maritalStatuses = action.payload;
      })
      .addCase(getWebStates.fulfilled, (state, action) => {
        state.webStates = action.payload;
      })
      .addCase(getWebQualifications.fulfilled, (state, action) => {
        state.webQualifications = action.payload;
      })
      .addCase(getWebLicenseTypes.fulfilled, (state, action) => {
        state.webLicenseTypes = action.payload;
      })
      .addCase(getWebLicenseStatuses.fulfilled, (state, action) => {
        state.webLicenseStatuses = action.payload;
      })
      .addCase(getLicense.fulfilled, (state, action) => {
        state.currentLicense = action.payload;
      });
  },
});

export const { setEmployeeFilesFilter, setEmployeeFilter, setCurrentEmployee } = staffSlice.actions;
export const staffReducer = staffSlice.reducer;
