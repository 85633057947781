import { IStatus } from 'shared/ui/Status/types';

export const colorizeStageSchedule = (status: string): { color: IStatus; text: string } => {
  const data: { color: IStatus; text: string } = {
    color: 'primary',
    text: '',
  };

  switch (status) {
    case 'accepted':
      data.color = 'primary';
      data.text = 'accepted';
      break;
    case 'approved':
      data.color = 'black';
      data.text = 'approved';
      break;
    case 'closed':
      data.color = 'danger';
      data.text = 'closed';
      break;
    case 'created':
      data.color = 'blue';
      data.text = 'created';
      break;
    case 'filled':
      data.color = 'purple';
      data.text = 'filled';
      break;
    case 'inProgress':
      data.color = 'warning';
      data.text = 'in progress';
      break;
    case 'sent':
      data.color = 'pink';
      data.text = 'sent';
      break;
    case 'sentForApproval':
      data.color = 'warning';
      data.text = 'waiting for approval';
      break;
    case 'needRevision':
      data.color = 'blue';
      data.text = 'need revision';
      break;
  }

  return data;
};
