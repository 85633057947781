import cn from 'app/helpers/cn';
import React from 'react';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import './SubmitModalLayout.sass';
import { SubmitModalProps } from './types';

const SubmitModalLayout: React.FC<SubmitModalProps> = ({
  title,
  onClose,
  onSubmit,
  children,
  cancelBtnText = 'Cancel',
  submitBtnText = 'Submit',
  loading,
  leftComponent,
  rightComponent,
  clearFilter,
  onScrollAtBottom,
}) => {
  const onCalcelHandler = () => {
    onClose?.();
  };

  return (
    <div className='submit-modal'>
      {onClose && <UIActionButton onClick={onClose} type='close' classes={{ root: 'submit-modal__close' }} />}
      <div className='submit-modal__title'>{title}</div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit?.(e);
        }}
        className='submit-modal__form'
      >
        <div className='submit-modal__content'>
          <div
            className='submit-modal__content-with-scroll scroll'
            onScroll={(event) => {
              const container = event.currentTarget;

              if (container.scrollTop + container.clientHeight >= container.scrollHeight && onScrollAtBottom) {
                onScrollAtBottom();
              }
            }}
          >
            {children}
          </div>
        </div>
        <div
          className={cn('submit-modal__footer', {
            'submit-modal__footer--with-left-component': leftComponent,
          })}
        >
          {leftComponent}
          <div className='submit-modal__btns'>
            {clearFilter && <UIButton text='Clear Filter' color='secondary' type='reset' onClick={clearFilter} />}
            {!clearFilter && cancelBtnText !== null && (
              <UIButton text={cancelBtnText} color='secondary' type='reset' onClick={onCalcelHandler} />
            )}
            {rightComponent}
            {submitBtnText !== null && <UIButton text={submitBtnText} type='submit' loading={loading} />}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SubmitModalLayout;
