import cn from 'app/helpers/cn';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as HandWatch } from 'assets/icons/hand-watch.svg';
import React from 'react';
import UITooltip from '../UITooltip/UITooltip';
import { UIActionButtonProps } from './types';
import './UIActionButton.sass';

const UIActionButton: React.FC<UIActionButtonProps> = ({ type, onClick, tooltip, classes }) => {
  const renderIcon = () => {
    let Icon = EditIcon;
    switch (type) {
      case 'edit':
        Icon = EditIcon;
        break;
      case 'delete':
        Icon = TrashIcon;
        break;
      case 'close':
        Icon = CloseIcon;
        break;
      case 'download':
        Icon = DownloadIcon;
        break;
      case 'exit-work':
        Icon = HandWatch;
        break;
      default:
        break;
    }
    return <Icon className='ui-action-button__icon' />;
  };

  return (
    <UITooltip title='' {...tooltip}>
      <button
        onClick={onClick}
        className={cn('ui-action-button', {
          [classes?.root ?? '']: classes?.root,
          [`ui-action-button--${type}`]: type,
        })}
      >
        {renderIcon()}
      </button>
    </UITooltip>
  );
};

export default UIActionButton;
