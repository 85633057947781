import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { projectsService } from 'api';
import {
  GetProjectFilesBody,
  GetProjectsBody,
  GetProjectSchedulesBody,
  GetProjectsResponse,
  GetSupervisorsParams,
} from 'api/projects/types';
import { RootState } from 'store/store';
import { ProjectsState } from './types';

const initialState: ProjectsState = {
  projects: {
    data: [],
  },
  supervisors: [],
};

export const getProjects = createAsyncThunk(
  'projects/getProjects',
  async (payload: { params?: GetProjectsBody; force?: boolean } | undefined, ThunkApi) => {
    const { projects } = (ThunkApi.getState() as RootState).projects as ProjectsState;
    if (projects?.data?.length && !payload?.force) return projects;

    return await projectsService.getProjects(payload?.params).then((response) => response.data);
  }
);

export const getProject = createAsyncThunk('projects/getProject', async (payload: number, ThunkApi) => {
  return await projectsService.getProject(payload).then((response) => response.data.data);
});

export const getProjectFiles = createAsyncThunk(
  'projects/getProjectFiles',
  async (payload: { params?: GetProjectFilesBody }, ThunkApi) => {
    ThunkApi.dispatch(setProjectFilesFilter(payload.params));
    return await projectsService.getProjectFiles(payload.params).then((response) => response.data);
  }
);

export const getProjectSchedules = createAsyncThunk(
  'projects/getProjectSchedules',
  async (payload: { params?: GetProjectSchedulesBody } | undefined, ThunkApi) => {
    return await projectsService.getProjectSchedules(payload?.params).then((response) => response.data);
  }
);

export const getProjectStatuses = createAsyncThunk('projects/getProjectStatuses', async (payload, ThunkApi) => {
  const { statuses } = (ThunkApi.getState() as RootState).projects as ProjectsState;
  if (statuses?.length) return statuses;

  return await projectsService.getProjectStatuses().then((response) => response.data.data);
});

export const getPaymentOptions = createAsyncThunk('projects/getPaymentOptions', async (payload, ThunkApi) => {
  const { paymentOptions } = (ThunkApi.getState() as RootState).projects as ProjectsState;
  if (paymentOptions?.length) return paymentOptions;

  return await projectsService.getPaymentOptions().then((response) => response.data.data);
});

export const getSupervisors = createAsyncThunk(
  'projects/getSupervisors',
  async (payload: { force?: boolean; params?: GetSupervisorsParams } | undefined, ThunkApi) => {
    const { supervisors } = (ThunkApi.getState() as RootState).projects as ProjectsState;
    if (supervisors?.length && !payload?.force) return supervisors;

    return await projectsService.getSupervisors(payload?.params).then((response) => response.data.data);
  }
);

export const projectsSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setProjectFilesFilter: (state: ProjectsState, payload: PayloadAction<GetProjectFilesBody | undefined>) => {
      state.currentProjectFilesFilter = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProject.pending, (state) => {
        state.currentProject = undefined;
        state.projectLoading = true;
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.projectLoading = false;
        state.currentProject = action.payload;
      })
      .addCase(getProjectSchedules.pending, (state) => {
        state.currentProjectSchedules = undefined;
        state.currentProjectSchedulesLoading = true;
      })
      .addCase(getProjectSchedules.fulfilled, (state, action) => {
        state.currentProjectSchedules = action.payload;
        state.currentProjectSchedulesLoading = false;
      })
      .addCase(getProjectFiles.pending, (state) => {
        state.currentProjectFiles = undefined;
        state.currentProjectFilesLoading = true;
      })
      .addCase(getProjectFiles.fulfilled, (state, action) => {
        state.currentProjectFilesLoading = false;
        state.currentProjectFiles = action.payload;
      })
      .addCase(getProjects.pending, (state) => {
        state.projectsLoading = true;
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.projectsLoading = false;
        state.projects = action.payload as GetProjectsResponse;
      })
      .addCase(getProjectStatuses.fulfilled, (state, action) => {
        state.statuses = action.payload;
      })
      .addCase(getPaymentOptions.fulfilled, (state, action) => {
        state.paymentOptions = action.payload;
      })
      .addCase(getSupervisors.pending, (state) => {
        state.supervisors = [];
        state.supervisorsLoading = true;
      })
      .addCase(getSupervisors.fulfilled, (state, action) => {
        state.supervisors = action.payload;
        state.supervisorsLoading = false;
      });
  },
});

export const { setProjectFilesFilter } = projectsSlice.actions;

export const projectsReducer = projectsSlice.reducer;
