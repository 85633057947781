export const formatPhone = (phoneNumberString: string | number) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const getCleanupPhone = (phone: string): string => {
  if (!phone.length) return '';

  return phone.replace(/[-() +]/g, '');
};
