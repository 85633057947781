import NavigationIconModel from 'app/models/NavigationIconModel';
import { ReactComponent as BriefCaseFilledIcon } from 'assets/icons/briefcase-filled.svg';
import { ReactComponent as BriefCaseIcon } from 'assets/icons/briefcase.svg';
import { ReactComponent as CalendarFilledIcon } from 'assets/icons/calendar-filled.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as FolderFilledIcon } from 'assets/icons/folder-open-filled.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-open.svg';
import { ReactComponent as PeopleFilledIcon } from 'assets/icons/people-filled.svg';
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg';

const NAVDATA: NavigationIconModel[] = [
  {
    to: '/companies',
    icon: BriefCaseIcon,
    activeIcon: BriefCaseFilledIcon,
    label: 'Companies',
  },
  {
    to: '/projects',
    icon: FolderIcon,
    activeIcon: FolderFilledIcon,
    label: 'Projects',
  },
  {
    to: '/staff',
    icon: PeopleIcon,
    activeIcon: PeopleFilledIcon,
    label: 'Staff',
  },
  {
    to: '/schedules',
    icon: CalendarIcon,
    activeIcon: CalendarFilledIcon,
    label: 'Schedules',
  },
  // TODO: Backlog
  // {
  //   to: '/payrolls',
  //   icon: WalletIcon,
  //   activeIcon: WalletFilledIcon,
  //   label: 'Payrolls',
  // },
];

export default NAVDATA;
