import { FormControl, FormLabel, Stack } from '@mui/material';
import { GetSchedulesBody } from 'api/schedules/types';
import { formatDate } from 'app/helpers/formatDate';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useFilters from 'app/hooks/useFilters';
import { CompanyModel } from 'app/models/CompanyModel';
import { ProjectModel } from 'app/models/ProjectModel';
import dayjs from 'dayjs';
import _ from 'lodash';
import { colorizeStageSchedule } from 'pages/SchedulesPage/helpers';
import ShedulesFilterParams from 'pages/SchedulesPage/types';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormDateInput from 'shared/Inputs/FormDateInput/FormDateInput';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import SubmitModalLayout from 'shared/Modals/SubmitModalLayout/SubmitModalLayout';
import Status from 'shared/ui/Status/Status';
import { IStatus } from 'shared/ui/Status/types';
import UICheckbox from 'shared/ui/UICheckbox/UICheckbox';
import UIModal from 'shared/ui/UIModal/UIModal';
import { getSchedulesActions, getScheduleStages } from 'store/slices/schedules';
import './SchedulesFilterModal.sass';
import { SchedulesFilterModalProps, SchedulesFilterModalRef } from './types';
import FormCompaniesSelect from 'shared/Inputs/Filters/FormCompaniesSelect';
import { getFCompanies, getFProjects } from 'store/slices/filters';
import FormProjectsSelect from 'shared/Inputs/Filters/FormProjectsSelect';

const dayjsFormat = 'DD/MM/YYYY';

const initialFilterParams: ShedulesFilterParams = {
  projects: [],
  companies: [],
  stages: [],
  from: dayjs(new Date()).format(dayjsFormat),
  to: dayjs(new Date()).format(dayjsFormat),
};

interface SubmitData {
  stages?: { label: string; value: string; color?: IStatus }[];
  companies?: CompanyModel[];
  startDate?: string;
  endDate?: string;
  projects: ProjectModel[];
}

const SchedulesFilterModal: React.ForwardRefRenderFunction<SchedulesFilterModalRef, SchedulesFilterModalProps> = (
  { limit },
  ref
) => {
  const { control, handleSubmit, setValue, reset, getValues } = useForm<SubmitData>();
  const { projects, companies } = useAppSelector((state) => state.filters);
  const { stages: statusesData } = useAppSelector((state) => state.schedules);

  const [open, setOpen] = useState(false);
  const [statuses, setStatuses] = useState<{ label: string; value: string; color?: IStatus }[]>([]);

  const dispatch = useAppDispatch();

  const { replaceUrl, getNonEmptyFilters, resetFilters, resetAndOnChangeFilter, onChangeFilter } = useFilters({
    initialFilterParams,
    limit,
    initialCallback: (params) => {
      dispatch(getSchedulesActions({ params }));
      replaceUrl(params);
    },
    replaceState: {
      deps: [companies.data.length, statuses.length, projects.data.length],
      companies: (value) => {
        let valueToSet: CompanyModel[] = [];

        if (typeof value === 'object') {
          valueToSet = value
            ?.map((el) => companies.data?.find((com) => !!el && +el === com.unique))
            .filter((el) => !!el) as CompanyModel[];
        } else {
          const company = companies.data.find((el) => el.unique === +value);
          if (company) valueToSet.push(company);
        }

        setValue('companies', valueToSet);
      },
      projects: (value) => {
        let valueToSet: ProjectModel[] = [];

        if (typeof value === 'object') {
          valueToSet = value
            ?.map((el) => projects.data?.find((com) => !!el && +el === com.unique))
            .filter((el) => !!el) as ProjectModel[];
        } else {
          const project = projects.data.find((el) => el.unique === +value);
          if (project) valueToSet.push(project);
        }

        setValue('projects', valueToSet);
      },
      stages: (value) => {
        const valueToSet =
          typeof value === 'object'
            ? value?.map((el) => statuses?.find((status) => el === status.value)).filter((el) => !!el) ?? []
            : [statuses.find((el) => el.value === value)];
        setValue('stages', valueToSet as typeof statuses);
      },
      from: (value) => {
        setValue(
          'startDate',
          dayjs(value as string, dayjsFormat).isValid() ? `${dayjs(formatDate({ date: value as string }))}` : ''
        );
      },
      to: (value) => {
        setValue(
          'endDate',
          dayjs(value as string, dayjsFormat).isValid() ? `${dayjs(formatDate({ date: value as string }))}` : ''
        );
      },
    },
  });

  useEffect(() => {
    dispatch(getScheduleStages({ force: true }));
  }, []);

  useEffect(() => {
    setStatuses(
      statusesData?.map((s) => ({
        label: colorizeStageSchedule(s.id).text,
        value: s.id,
        color: colorizeStageSchedule(s.id).color,
      })) ?? []
    );
  }, [statusesData]);

  useImperativeHandle(ref, () => ({
    show: () => {
      dispatch(getFCompanies({ reset: true }));
      dispatch(getFProjects({ reset: true }));

      setOpen(true);
    },
    hide: () => setOpen(false),
    refreshPage: () => {
      dispatch(getSchedulesActions({ params: { ...getNonEmptyFilters() } }));
    },
    resetAndOnChangeFilter: (params: ShedulesFilterParams) => {
      const filters = onChangeFilter(params);
      replaceUrl(filters);

      dispatch(getSchedulesActions({ params: getNonEmptyFilters(filters) }));
    },
    resetFilters: () => resetAppliedFilter(),
  }));

  const applyFilters: SubmitHandler<SubmitData> = (data) => {
    const submitData: GetSchedulesBody = {
      projects: data.projects?.map((p) => p.unique),
      stages: data.stages?.map((el) => el.value),
      companies: data.companies?.map((c) => +c.unique),
      from: data.startDate ? dayjs(data.startDate).format(dayjsFormat) : '',
      to: data.endDate ? dayjs(data.endDate).format(dayjsFormat) : '',
    };

    const filters = resetAndOnChangeFilter(_.omitBy(submitData, _.isEmpty));
    replaceUrl(filters);

    dispatch(getSchedulesActions({ params: getNonEmptyFilters(filters) }));
    setOpen(false);
  };

  const resetAppliedFilter = () => {
    const filters = resetFilters();
    replaceUrl(filters);
    reset({ companies: [], stages: [], projects: [], startDate: '', endDate: '' });

    dispatch(getSchedulesActions({ params: getNonEmptyFilters(filters) }));
    setOpen(false);
  };

  const handleChangeStartDate = (date: Date | null) => {
    if (date === null) {
      setValue('startDate', dayjs(new Date()).format('DD MMM, YYYY'));
    } else {
      const endDate = getValues('endDate');

      if (dayjs(date).isAfter(endDate)) {
        setValue('endDate', dayjs(date).format('DD MMM, YYYY'));
      }
    }
  };

  const handleChangeEndDate = (date: Date | null) => {
    if (date === null) {
      setValue('endDate', dayjs(new Date()).format('DD MMM, YYYY'));
    } else {
      const startDate = getValues('startDate');

      if (dayjs(date).isBefore(startDate)) {
        setValue('startDate', dayjs(date).format('DD MMM, YYYY'));
      }
    }
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'filter-modal',
        paper: 'filter-modal__paper',
      }}
    >
      <SubmitModalLayout
        title='Filter'
        onClose={() => setOpen(false)}
        submitBtnText='Apply'
        clearFilter={resetAppliedFilter}
        onSubmit={handleSubmit(applyFilters)}
      >
        <div className='filter-modal__inputs'>
          <Stack direction='row' spacing={2}>
            <FormControl className='filter-modal__row'>
              <FormLabel>Start Date</FormLabel>
              <FormDateInput name='startDate' control={control} onChange={handleChangeStartDate} />
            </FormControl>
            <FormControl className='filter-modal__row'>
              <FormLabel>End Date</FormLabel>
              <FormDateInput name='endDate' control={control} onChange={handleChangeEndDate} />
            </FormControl>
          </Stack>
          {statuses && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Status</FormLabel>
              <FormSelectInput
                control={control}
                name='stages'
                allCheckbox
                isOptionEqualToValue={(option, value) => option.value === value.value}
                multiple
                options={statuses}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} style={{ paddingLeft: 7 }}>
                      <UICheckbox style={{ marginRight: 8 }} checked={selected} />
                      {option.color ? <Status text={option.label} color={option.color} /> : option.label}
                    </li>
                  );
                }}
              />
            </FormControl>
          )}

          {companies.data && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Company</FormLabel>
              <FormCompaniesSelect control={control} name='companies' multiple />
            </FormControl>
          )}

          {projects.data && (
            <FormControl className='filter-modal__row'>
              <FormLabel>Project</FormLabel>
              <FormProjectsSelect control={control} name='projects' multiple />
            </FormControl>
          )}
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(SchedulesFilterModal);
