import cn from 'app/helpers/cn';
import { NotificationModel } from 'app/models/Notifications';
import React from 'react';
import UIButton from 'shared/ui/UIButton/UIButton';

interface Props {
  notification?: NotificationModel;
}

interface DataType {
  color: 'purple' | 'yellow' | 'danger' | 'black' | 'primary';
  href?: string;
  btnText?: string;
}

const NotificationsItem: React.FC<Props> = ({ notification }) => {
  const splitDate = notification?.dateTime.split(' ');
  const time = `${splitDate?.[1]} ${splitDate?.[2]}`;

  const data: DataType = {
    color: 'black',
  };

  switch (notification?.typeName) {
    case 'License update':
      data.color = 'purple';
      break;
    case 'License approve':
      data.color = 'yellow';
      data.href = `/licenses/${notification?.owner}`;
      data.btnText = 'Confirm';
      break;
    case 'Accident report':
      data.color = 'danger';
      data.href = `/schedules/${notification?.owner}`;
      data.btnText = 'View Report';
      break;
    case 'New manager message':
    case 'New worker message':
      data.color = 'primary';
      data.href = `/schedules/${notification?.owner}/chat`;
      data.btnText = 'Open Chat ';
      break;
  }

  return (
    <div className='notifications__item'>
      <div className={cn('notifications__item-bullet', data.color)} />
      <div className='notifications__item-content'>
        <div className='notifications__item-title'>{notification?.title}</div>
        {notification?.body && (
          <div className='notifications__item-description' dangerouslySetInnerHTML={{ __html: notification.body }} />
        )}
        {!!data.href?.length && (
          <UIButton to={data.href} target='_blank' text={data.btnText} className='notifications__item-btn' />
        )}
      </div>
      <div className='notifications__item-time'>{time}</div>
    </div>
  );
};

export default NotificationsItem;
