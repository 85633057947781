import { FormControl, FormLabel } from '@mui/material';
import { useAppDispatch } from 'app/hooks';
import { EmployeesModel } from 'app/models/StaffModel';
import React, { useImperativeHandle, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { AddMemberModalProps, AddMemberModalRef } from './types';
import FormEmployeesWithoutCrewSelect from '../../Inputs/Filters/FormEmployeesWithoutCrewSelect';
import { getFEmployeesWithoutCrew } from 'store/slices/filters';
import './AddMemberModal.sass';

interface SubmitData {
  employees: EmployeesModel[];
}

const AddMemberModal: React.ForwardRefRenderFunction<AddMemberModalRef, AddMemberModalProps> = (
  { onSubmit, loading = false, title = 'Add Supervisor' },
  ref
) => {
  const dispatch = useAppDispatch();
  const { control, setValue, handleSubmit } = useForm<SubmitData>();

  const [open, setOpen] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      show: (data) => {
        if (data?.length) {
          setValue('employees', data);
        }

        dispatch(getFEmployeesWithoutCrew({ reset: true }));
        setOpen(true);
      },
      hide: () => {
        setOpen(false);
      },
    }),
    []
  );

  const submit: SubmitHandler<SubmitData> = ({ employees }) => {
    onSubmit(employees);
  };

  return (
    <UIModal open={open} onClose={() => setOpen(false)}>
      <SubmitModalLayout title={title} onClose={() => setOpen(false)} onSubmit={handleSubmit(submit)} loading={loading}>
        <div className='add-supervisor-modal__inputs'>
          <FormControl className='add-crew-modal__row'>
            <FormLabel>Employees</FormLabel>
            <FormEmployeesWithoutCrewSelect name='employees' control={control} multiple allCheckbox />
          </FormControl>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(AddMemberModal);
