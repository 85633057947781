import { Box, Stack } from '@mui/material';
import cn from 'app/helpers/cn';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useDidUpdateEffect from 'app/hooks/useDidUpdateEffect';
import { EmployeesModel } from 'app/models/StaffModel';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import _ from 'lodash';
import { getLicenseColor } from 'pages/LicenseDetailsPage/helpers';
import { colorizeStatusEmployees } from 'pages/StaffPage/components/Employees/helpers';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import SubmitModalLayout from 'shared/Modals/SubmitModalLayout/SubmitModalLayout';
import { ModalBaseRef } from 'shared/Modals/types';
import Status from 'shared/ui/Status/Status';
import UIButton from 'shared/ui/UIButton/UIButton';
import UICheckbox from 'shared/ui/UICheckbox/UICheckbox';
import UIModal from 'shared/ui/UIModal/UIModal';
import UITable from 'shared/ui/UITable/UITable';
import { ScheduleWorker } from '../../types';
import { WorkersFilterModalRef } from './components/WorkersFilterModal/types';
import WorkersFilterModal from './components/WorkersFilterModal/WorkersFilterModal';
import './AddWorkerModal.sass';
import { FILTERS_LIMIT } from '../../../../../store/slices/filters/data';
import { getFEmployeesForSchedule } from '../../../../../store/slices/filters';
import dayjs from 'dayjs';

interface Props {
  onChange: (data: ScheduleWorker[]) => void;
  workers: ScheduleWorker[];
  scheduleDate: Date;
}

interface SubmitData {
  search?: string;
}

const AddWorkerModal: React.ForwardRefRenderFunction<ModalBaseRef, Props> = (
  { onChange, workers, scheduleDate },
  ref
) => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit } = useForm<SubmitData>();

  const { employeesForSchedule } = useAppSelector((state) => state.filters);
  const { webSpicialities } = useAppSelector((state) => state.utils);
  const [open, setOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [selected, setSelected] = useState<EmployeesModel[]>([]);
  const [filteredData, setFilteredData] = useState(employeesForSchedule.data ?? []);

  const [page, setPage] = useState(1);

  const workersFilterModalRef = useRef<WorkersFilterModalRef>(null);

  useImperativeHandle(ref, () => ({
    show: () => setOpen(true),
    hide: () => setOpen(false),
  }));

  useEffect(() => {
    dispatch(getFEmployeesForSchedule({ reset: true, params: { date: dayjs(scheduleDate).format('DD/MM/YYYY') } }));
  }, [scheduleDate]);

  useEffect(() => {
    setSelected(employeesForSchedule.data?.filter((el) => workers.some((worker) => worker.unique === el.unique)) ?? []);
  }, [workers, employeesForSchedule.data]);

  // useDidUpdateEffect(() => {
  //   setFilteredData((prev) => {
  //     let clone = _.cloneDeep(prev);
  //     clone = clone?.filter(
  //       (el) =>
  //         !!el.fullName &&
  //         el.fullName.toLowerCase().includes(searchString.toLowerCase()) &&
  //         el.typeId !== 'clientSupervisor' &&
  //         el.typeId !== 'supervisor'
  //     );
  //     if (!searchString) return employeesForSchedule.data ?? [];
  //     return clone;
  //   });
  // }, [searchString]);

  useDidUpdateEffect(() => {
    setFilteredData(() => {
      if (!searchString) {
        return employeesForSchedule.data ?? [];
      }

      return (employeesForSchedule.data ?? []).filter(
        (el) =>
          !!el.fullName &&
          el.fullName.toLowerCase().includes(searchString.toLowerCase()) &&
          el.typeId !== 'clientSupervisor' &&
          el.typeId !== 'supervisor'
      );
    });
  }, [searchString]);

  useEffect(() => {
    setFilteredData(
      (employeesForSchedule.data ?? []).filter((el) => el.typeId !== 'clientSupervisor' && el.typeId !== 'supervisor')
    );
  }, [employeesForSchedule.data]);

  const onSubmitHandler = () => {
    onChange(
      selected.map((el) => ({
        chatAccess: false,
        name: el.fullName,
        specialities: renderSpiecialities(el.specialities),
        startTime: '',
        status: el.status,
        unique: el.unique,
        licenses: el.licenses,
      }))
    );
    setOpen(false);
  };

  const renderSpiecialities = (uniques: number[]): string => {
    return webSpicialities
      .filter((speciality) => uniques.includes(speciality.unique))
      .map((s) => s.name)
      .join(', ');
  };

  const submit: SubmitHandler<SubmitData> = ({ search }) => {
    setSearchString(search ?? '');
  };

  const getMoreEmployees = () => {
    if (page + 1 <= (employeesForSchedule.totalPages ?? 1) && !employeesForSchedule.loading) {
      workersFilterModalRef.current?.onChange({
        page: page + 1,
        pageSize: FILTERS_LIMIT,
        type: 'worker',
        date: dayjs(scheduleDate).format('DD/MM/YYYY'),
      });
      setPage((prev) => prev + 1);
    }
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '1209px',
        },
      }}
    >
      <SubmitModalLayout
        title='Add Worker'
        onClose={() => setOpen(false)}
        onSubmit={onSubmitHandler}
        onScrollAtBottom={getMoreEmployees}
      >
        <Box>
          <Stack direction='row' spacing={2}>
            <FormTextInput
              style={{ width: '100%' }}
              control={control}
              name='search'
              placeholder='Search by name'
              inputProps={{
                onChange: (e) => {
                  setSearchString(e.currentTarget.value);
                },
              }}
              InputProps={{
                endAdornment: (
                  <button className='find-button' type='submit' onClick={handleSubmit(submit)}>
                    <SearchIcon />
                  </button>
                ),
                sx: { background: 'white' },
              }}
            />
            <UIButton
              type='button'
              color='white'
              icon='filter'
              text='Filter'
              onClick={() => workersFilterModalRef.current?.show()}
            />
          </Stack>
          <UITable
            data={filteredData}
            className='add-worker__table'
            classes={{
              headerRow: 'add-worker__table--header-row',
            }}
            loading={employeesForSchedule.loading}
            headers={[
              {
                renderCol: () => (
                  <UICheckbox
                    checked={filteredData
                      ?.filter((i) => i.statusId === 'active' && !i?.scheduled)
                      .every((el) => selected.some((item) => item.unique === el.unique))}
                    onChange={(_event, checked) => {
                      setSelected(
                        checked ? filteredData.filter((item) => item.statusId === 'active' && !item?.scheduled) : []
                      );
                    }}
                  />
                ),
                noWrap: true,
              },
              { label: 'Full Name' },
              { label: 'Licenses' },
              { label: 'Status' },
              { label: 'Qualification' },
              { label: 'Rate ($ per hour)' },
            ]}
            columns={[
              {
                renderCol: (i) => (
                  <UICheckbox
                    checked={selected.some((el) => el.unique === i.unique)}
                    disabled={i.statusId !== 'active' || !!i?.scheduled}
                    onChange={(_event, checked) =>
                      setSelected((prev) => {
                        const clone = _.cloneDeep(prev);
                        if (checked) {
                          clone.push(i);
                        } else {
                          clone.splice(
                            clone.findIndex((el) => el.unique === i.unique),
                            1
                          );
                        }
                        return _.uniq(clone);
                      })
                    }
                  />
                ),
              },
              {
                renderCol: (i) => {
                  return (
                    <div
                      className={cn('add-worker__table-status', {
                        'add-worker__table-status--not-active': i.statusId !== 'active' || !!i.scheduled,
                      })}
                    >
                      <Link to={`/employees/${i.unique}`} className='add-worker__table-link'>
                        {i.fullName}
                      </Link>{' '}
                    </div>
                  );
                },
              },
              {
                renderCol: (i) => (
                  <Stack spacing={1} flexWrap='wrap' direction='row'>
                    {i.licenses.map((el, index) => (
                      <Status key={index} text={el.type.shortName} color={getLicenseColor(el)} />
                    ))}
                  </Stack>
                ),
              },
              {
                renderCol: (i) => {
                  let { text, color } = colorizeStatusEmployees(i.statusId);

                  if (i.scheduled) {
                    text = 'scheduled';
                    color = 'black';
                  }

                  return <Status text={text} color={color} />;
                },
              },
              { columnName: 'qualification' },
              { renderCol: (i) => !!i.paymentRate && <span>${i.paymentRate}</span> },
            ]}
          />
        </Box>
      </SubmitModalLayout>
      <WorkersFilterModal ref={workersFilterModalRef} scheduleDate={scheduleDate} />
    </UIModal>
  );
};

export default React.forwardRef(AddWorkerModal);
