import React, { useImperativeHandle, useRef, useState } from 'react';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { DailyLogModalProps, DailyLogModalRef } from './types';
import { schedulesService } from 'api';
import { DailyLogDetailedModel } from 'app/models/SchedulesModel';
import { ReactComponent as TickCircleIcon } from 'assets/icons/primary/tick-circle.svg';
import { encodeBase64 } from 'app/helpers/toBase64';
import PreviewImageModal from '../PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from '../PreviewImageModal/types';
import { CircularProgress, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import EditDailyLogModal from '../EditDailyLogModal/EditDailyLogModal';
import { EditDailyLogModalModalRef } from '../EditDailyLogModal/types';
import './DailyLogModal.sass';

const DailyLogModal: React.ForwardRefRenderFunction<DailyLogModalRef, DailyLogModalProps> = (_, ref) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [dailyLog, setDailyLog] = useState<DailyLogDetailedModel | null>(null);

  const previewImageModalRef = useRef<PreviewImageModalRef>(null);
  const editDailyLogModalRef = useRef<EditDailyLogModalModalRef>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: (unique) => {
        setOpen(true);
        getDailyLog(unique);
      },
      hide: () => {
        setOpen(false);
      },
    }),
    []
  );

  const getDailyLog = async (unique: number) => {
    try {
      setLoading(true);

      const response = await schedulesService.getDailyLog(unique);
      setDailyLog(response.data.data);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setOpen(false);
    setDailyLog(null);
    setLoading(false);
  };

  return (
    <UIModal
      open={open}
      onClose={onClose}
      classes={{
        root: 'reports-modal',
        paper: 'reports-modal__paper',
      }}
      PaperProps={{
        sx: { maxWidth: '588px', width: '100%' },
      }}
    >
      <SubmitModalLayout
        title={dailyLog?.name ?? ''}
        onClose={onClose}
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();

          dailyLog && editDailyLogModalRef.current?.show(dailyLog);
        }}
        submitBtnText='Edit'
      >
        <>
          {loading ? (
            <Stack justifyContent='center' alignItems='center' height='100px'>
              <CircularProgress />
            </Stack>
          ) : (
            <>
              <Stack direction='row' spacing='8px' alignItems='center' className='daily-log__reported'>
                <p className='daily-log__reported-by'>Reported by:</p>
                <Link to={`/employees/${dailyLog?.writtenBy}`} target='_blank' className='daily-log__reported-name'>
                  {dailyLog?.writtenByName}
                </Link>
                <p className='daily-log__reported-date'>
                  {dayjs(dailyLog?.writtenAt, 'DD/MM/YYYY HH:MM').format('HH:MM A')}
                </p>
              </Stack>
              <div className='daily-report__card'>
                <div className='daily-report__card-title'>
                  <TickCircleIcon className='daily-report__card-icon' />
                  What has been done
                </div>
                <p className='daily-report__card-description'>{dailyLog?.workDone}</p>
              </div>
              {dailyLog?.image && (
                <div className='daily-report__attachments'>
                  <h3 className='daily-report__attachments-title'>Attachments</h3>
                  <div className='daily-report__attachments-wrapper'>
                    <img
                      className='daily-report__attachments-image'
                      src={encodeBase64(dailyLog?.image, 'jpg')}
                      alt='1'
                      onClick={() =>
                        previewImageModalRef.current?.show({
                          file: {
                            name: `attachment-${dailyLog.unique}`,
                            unique: dailyLog.imageUnique,
                            extension: 'jpg',
                          },
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </>
          )}

          <EditDailyLogModal ref={editDailyLogModalRef} getData={() => getDailyLog(dailyLog?.unique!)} />
          <PreviewImageModal ref={previewImageModalRef} />
        </>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(DailyLogModal);
