export const toBase64 = (file: any, withoutPrefix?: boolean): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!file) reject();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(withoutPrefix ? String(reader.result).split('base64,')[1] : (reader.result as string));
    reader.onerror = (error) => reject(error);
  });

export const encodeBase64 = (base64: string, type: string) => {
  if (!type) type = 'png';
  if (!base64?.length) return '';

  return `data:image/${type};base64,${base64}`;
};
