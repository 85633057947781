import React, { useEffect, useRef, useState } from 'react';
import './SchedulesDetails.sass';
import UITable from 'shared/ui/UITable/UITable';
import history from 'app/routes/history';
import { ReactComponent as PlaceholderCalendarIcon } from 'assets/icons/table/calendar.svg';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { formatDate } from 'app/helpers/formatDate';
import { ReactComponent as RedInfoCircleIcon } from 'assets/icons/red-info-circle.svg';
import { ReactComponent as ArrowRightCircleIcon } from 'assets/icons/arrow-right-circle.svg';
import { getShortText } from 'app/helpers/getShortText';
import ReportsModal from 'shared/Modals/ReportsModal/ReportsModal';
import { ReportsModalRef } from 'shared/Modals/ReportsModal/types';
import { Stack } from '@mui/material';
import ReportOnAccidentModal from 'shared/Modals/ReportOnAccidentModal/ReportOnAccidentModal';
import { ReportOnAccidentModalRef } from 'shared/Modals/ReportOnAccidentModal/types';
import ProjectSchedulesFilterModal from './components/ProjectSchedulesFilterModal/ProjectSchedulesFilterModal';
import { ProjectSchedulesFilterModalRef } from './components/ProjectSchedulesFilterModal/types';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import Status from 'shared/ui/Status/Status';
import { getScheduleStages } from 'store/slices/schedules';
import { getSupervisors } from 'store/slices/projects';
import { colorizeStageSchedule } from 'pages/SchedulesPage/helpers';

const SchedulesDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { currentProjectSchedules, currentProjectSchedulesLoading, currentProject } = useAppSelector(
    (state) => state.projects
  );

  const [page, setPage] = useState(1);

  const reportsModalRef = useRef<ReportsModalRef>(null);
  const reportOnAccidentModalRef = useRef<ReportOnAccidentModalRef>(null);
  const projectSchedulesFilterModalRef = useRef<ProjectSchedulesFilterModalRef>(null);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    setPage(params.page ? +params.page : 1);
  }, [location.search]);

  useEffect(() => {
    dispatch(getScheduleStages({ force: true }));
    dispatch(getSupervisors());
  }, [dispatch]);

  return (
    <>
      <UITable
        onRowClick={(i) => history.push(`/schedules/${i.unique}`)}
        title={{
          title: 'List of Schedules',
          button: {
            text: 'Filter',
            icon: 'filter',
            color: 'light-gray',
            onClick: () => projectSchedulesFilterModalRef.current?.show(),
          },
        }}
        data={currentProjectSchedules?.data}
        loading={currentProjectSchedulesLoading}
        totalPages={currentProjectSchedules?.totalPages}
        headers={[
          { label: '#' },
          { label: 'Date' },
          { label: 'Start Time' },
          { label: 'Reports' },
          { label: 'Status' },
          { label: 'Supervisor' },
          { label: 'Accidents' },
          { label: 'Total Workers' },
          { label: 'Total Hours' },
        ]}
        columns={[
          { renderCol: (_, idx) => idx + 1 },
          { renderCol: (i) => formatDate({ date: i.date, parse: 'M/D/YY' }) },
          { columnName: 'startTime' },
          {
            renderCol: (i) =>
              i.dailyReport && (
                <Stack direction='row' gap='10px' alignItems='center'>
                  <div>Report #{i.dailyReport}</div>
                  <ArrowRightCircleIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      reportsModalRef.current?.show(i.dailyReport);
                    }}
                  />
                </Stack>
              ),
          },
          { renderCol: (i) => <Status {...colorizeStageSchedule(i.stage.id)} /> },
          {
            renderCol: (i) =>
              i.scheduleSupervisors?.[0]?.nameEmployee
                ? getShortText(i.scheduleSupervisors?.[0]?.nameEmployee, 10)
                : '',
          },
          {
            renderCol: (i) =>
              i.accident &&
              i.accidentReport.length && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    reportOnAccidentModalRef.current?.show(i.accidentReport[0]);
                  }}
                >
                  <RedInfoCircleIcon />
                </div>
              ),
          },
          { renderCol: (i) => i.totalWorkers ?? 0 },
          { renderCol: (i) => i.totalHours ?? 0 },
        ]}
        placeholder={{
          icon: <PlaceholderCalendarIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of schedules is empty. It will be displayed here.',
        }}
        pagination={{
          count: currentProjectSchedules?.totalPages ?? 0,
          page: page,
          onChange: (_, value) => {
            setPage(value);
            projectSchedulesFilterModalRef.current?.onChange({ page: value });
          },
        }}
      />
      <ReportsModal ref={reportsModalRef} />
      <ReportOnAccidentModal ref={reportOnAccidentModalRef} />
      <ProjectSchedulesFilterModal
        ref={projectSchedulesFilterModalRef}
        limit={10}
        projectUnique={currentProject?.unique!}
      />
    </>
  );
};

export default SchedulesDetails;
