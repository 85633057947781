import { FormControl, FormLabel, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormPhoneInput from 'shared/Inputs/FormPhoneInput/FormPhoneInput';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import './EditEmployeeModal.sass';
import { EditEmployeeModalProps, EditEmployeeModalRef } from './types';

import { StaffService, utilsService } from 'api';
import { FILE_TYPE_IMAGE } from 'app/constants/FILE_CONFIG';
import { formatPhone } from 'app/helpers/formatPhone';
import { getFileExtension } from 'app/helpers/getFileShortName';
import { toBase64 } from 'app/helpers/toBase64';
import { validator } from 'app/helpers/validator';
import {
  EmployeeStatusesModel,
  MaritalStatus,
  WebQualificationsModel,
  WebState,
  WorkerTypesModel,
} from 'app/models/StaffModel';
import WebSpeciality from 'app/models/WebSpeciality';
import { ReactComponent as UploadPlaceholder } from 'assets/icons/photo-upload.svg';
import { colorizeStatusEmployees } from 'pages/StaffPage/components/Employees/helpers';
import Status from 'shared/ui/Status/Status';
import UICheckbox from 'shared/ui/UICheckbox/UICheckbox';
import UIInputFile from 'shared/ui/UIFileInput/UIInputFile';

interface SubmitData {
  firstName: string;
  lastName: string;
  phone: string;
  emergencyContact: string;
  type: WorkerTypesModel;
  status: EmployeeStatusesModel;
  paymentRate: string;
  email: string;
  snn: string;
  tin: string;
  maritalStatus: MaritalStatus | null;
  qualification: WebQualificationsModel | null;
  dependents: string;
  specialities: WebSpeciality[];
  address: {
    city: string;
    state: WebState | null;
    zip: string;
    street: string;
    apt: string;
    house: string | number;
  };
}

const EditEmployeeModal: React.ForwardRefRenderFunction<EditEmployeeModalRef, EditEmployeeModalProps> = (
  { getData },
  ref
) => {
  const { currentEmployee, workerTypes, statuses, maritalStatuses, webStates, webQualifications } = useAppSelector(
    (state) => state.staff
  );
  const { webSpicialities } = useAppSelector((state) => state.utils);

  const dispatch = useAppDispatch();
  const { control, handleSubmit, reset, setValue, setError, clearErrors } = useForm<SubmitData>();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [photo, setPhoto] = useState<File[]>([]);
  const [previewPhoto, setPreviewPhoto] = useState('');

  useImperativeHandle(
    ref,
    () => ({
      show: () => setOpen(true),
      hide: () => setOpen(false),
    }),
    []
  );

  useEffect(() => {
    if (!photo.length) return;

    setPreviewPhoto(URL.createObjectURL(photo[0]));
  }, [photo]);

  useEffect(() => {
    if (!currentEmployee) return reset();

    const {
      firstName,
      lastName,
      phone,
      emergencyContact,
      typeId,
      statusId,
      email,
      ssn,
      tin,
      maritalStatusId,
      dependents,
      qualification,
      specialities,
      address,
      paymentRate,
      unique,
    } = currentEmployee;
    setPreviewPhoto('');

    getFile(unique);

    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('phone', formatPhone(phone) ? `${formatPhone(phone)}` : '');
    setValue('emergencyContact', formatPhone(emergencyContact) ? `${formatPhone(emergencyContact)}` : '');
    setValue('type', workerTypes?.find((w) => w.id === typeId)!);
    setValue('status', statuses?.find((s) => s.id === statusId)!);
    setValue('paymentRate', `${paymentRate}`);
    setValue('email', email);
    setValue('snn', ssn);
    setValue('tin', tin);
    setValue('maritalStatus', maritalStatuses?.find((m) => m.id === maritalStatusId) ?? null);
    setValue('dependents', `${dependents}`);
    setValue('qualification', webQualifications?.find((w) => w.name === qualification) ?? null);
    setValue('specialities', specialities);
    setValue('address', {
      ...address,
      state: webStates?.find((w) => w.name === address.state) ?? null,
    });
  }, [currentEmployee, maritalStatuses, reset, setValue, statuses, webSpicialities, workerTypes]);

  const formatMask = (str: string) => {
    return str.replace(/\D/g, '');
  };

  const editEmployee: SubmitHandler<SubmitData> = async (data) => {
    if (!currentEmployee) return;

    try {
      setLoading(true);

      if (!!photo.length) {
        await toBase64(photo[0]).then(async (result: string) => {
          await StaffService.postProfilePhoto({
            unique: currentEmployee.unique,
            extension: getFileExtension(photo[0].name),
            file: result.split('base64,')[1],
          });
        });
      }

      await StaffService.editEmployee({
        unique: currentEmployee.unique,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: +formatMask(data.phone),
        emergencyContact: +formatMask(data.emergencyContact),
        status: data.status?.id,
        paymentRate: +data.paymentRate,
        email: data.email,
        tin: formatMask(data.tin),
        ssn: formatMask(data.snn),
        maritalStatus: data.maritalStatus?.id!,
        dependents: +data.dependents,
        qualification: data.qualification?.name ?? '',
        specialities: data.specialities?.map((s) => s.unique) ?? [],
        address: {
          ...data.address,
          state: data.address.state?.id ?? '',
          apartment: data.address.apt,
          house: +data.address.house,
        },
      });

      reset();
      setOpen(false);
      getData && getData();
    } finally {
      setLoading(false);
    }
  };

  const getFile = async (unique: number) => {
    const res = await utilsService.getWebFile(unique).then((res) => res.data);
    setPreviewPhoto(await toBase64(res));
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'add-supervisor-modal',
        paper: 'add-supervisor-modal__paper',
      }}
    >
      {currentEmployee && (
        <SubmitModalLayout
          title='Edit'
          onClose={() => setOpen(false)}
          onSubmit={handleSubmit(editEmployee)}
          loading={loading}
          submitBtnText='Save'
        >
          <div className='add-supervisor-modal__inputs'>
            <label className='add-project-modal__row add-project-modal__upload'>
              <UIInputFile
                accept={FILE_TYPE_IMAGE}
                className='add-project-modal__upload-input'
                onChange={(files) => setPhoto([files[0]])}
              />

              {previewPhoto.length > 10 ? (
                <img src={previewPhoto} className='add-project-modal__photo' alt='' />
              ) : (
                <UploadPlaceholder className='add-project-modal__upload-image' />
              )}
              <div>
                <div className='add-project-modal__upload-title'>Change Photo</div>
                <p className='add-project-modal__upload-subtitle'>
                  You can use the following formats: <br /> .jpg, .jpeg, .png, .svg
                </p>
              </div>
            </label>
            <Stack direction='row' spacing={2}>
              <FormControl className='add-project-modal__row'>
                <FormLabel required>First Name</FormLabel>
                <FormTextInput name='firstName' control={control} rules={{ required: 'Required field' }} />
              </FormControl>
              <FormControl className='add-project-modal__row'>
                <FormLabel required>Last Name</FormLabel>
                <FormTextInput name='lastName' control={control} rules={{ required: 'Required field' }} />
              </FormControl>
            </Stack>
            <FormControl className='add-project-modal__row'>
              <FormLabel required>Phone</FormLabel>
              <FormPhoneInput
                ignoreDefaultPattern={true}
                name='phone'
                control={control}
                rules={{ required: 'Required field' }}
              />
            </FormControl>
            <FormControl className='add-project-modal__row'>
              <FormLabel required>Emergency Contact</FormLabel>
              <FormPhoneInput name='emergencyContact' control={control} rules={{ required: 'Required field' }} />
            </FormControl>
            <FormControl className='add-project-modal__row'>
              <FormLabel>Type</FormLabel>
              <FormSelectInput
                control={control}
                name='type'
                options={workerTypes ?? []}
                getOptionLabel={(w) => w.name}
              />
            </FormControl>
            <Stack direction='row' spacing={2}>
              <FormControl className='add-project-modal__row'>
                <FormLabel>Status</FormLabel>
                <FormSelectInput
                  control={control}
                  name='status'
                  options={statuses ?? []}
                  getOptionLabel={(w) => colorizeStatusEmployees(w.id).text}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} style={{ paddingLeft: 7 }} key={option.id} data-value={option.id}>
                        <Status {...colorizeStatusEmployees(option.id)} />
                      </li>
                    );
                  }}
                  disableClearable
                />
              </FormControl>
              <FormControl className='add-project-modal__row'>
                <FormLabel>Payment Rate ($ per hour)</FormLabel>
                <FormTextInput type='number' name='paymentRate' control={control} />
              </FormControl>
            </Stack>
            <FormControl className='add-project-modal__row'>
              <FormLabel>Email</FormLabel>
              <FormTextInput
                name='email'
                control={control}
                rules={{
                  pattern: {
                    value: validator.email,
                    message: 'Invalid Email',
                  },
                }}
              />
            </FormControl>

            <h3 className='add-project-modal__row add-project-modal__subtitle'>Additional Info</h3>

            <Stack direction='row' spacing={2}>
              <FormControl className='add-project-modal__row'>
                <FormLabel>SSN</FormLabel>
                <FormPhoneInput mask='999-99-9999' ignoreDefaultPattern name='snn' control={control} />
              </FormControl>
              <FormControl className='add-project-modal__row'>
                <FormLabel>TIN</FormLabel>
                <FormPhoneInput mask='99-99999999' ignoreDefaultPattern name='tin' control={control} />
              </FormControl>
            </Stack>
            <Stack direction='row' spacing={2}>
              <FormControl className='add-project-modal__row'>
                <FormLabel>Marital Status</FormLabel>
                <FormSelectInput
                  control={control}
                  name='maritalStatus'
                  options={maritalStatuses ?? []}
                  getOptionLabel={(w) => w.name}
                />
              </FormControl>
              <FormControl className='add-project-modal__row'>
                <FormLabel>Dependents</FormLabel>
                <FormTextInput
                  type='number'
                  name='dependents'
                  control={control}
                  rules={{
                    min: {
                      value: 0,
                      message: 'Minimum 0',
                    },
                    max: {
                      value: 15,
                      message: 'Maximum 15',
                    },
                  }}
                />
              </FormControl>
            </Stack>
            <FormControl className='add-project-modal__row'>
              <FormLabel>Qualification</FormLabel>
              <FormSelectInput
                control={control}
                name='qualification'
                options={webQualifications ?? []}
                getOptionLabel={(w) => w.name}
              />
            </FormControl>
            <FormControl className='add-project-modal__row'>
              <FormLabel>Specialities</FormLabel>
              <FormSelectInput
                control={control}
                name='specialities'
                options={webSpicialities ?? []}
                getOptionLabel={(w) => w.name}
                multiple
                isOptionEqualToValue={(option, value) => option.unique === value.unique}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} style={{ paddingLeft: 7 }} key={option.unique} data-value={option.unique}>
                      <UICheckbox style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  );
                }}
              />
            </FormControl>

            <h3 className='add-project-modal__row add-project-modal__subtitle'>Address</h3>

            <Stack direction='row' spacing={2}>
              <FormControl className='add-project-modal__row'>
                <FormLabel>Street</FormLabel>
                <FormTextInput name='address.street' control={control} />
              </FormControl>
              <FormControl className='add-project-modal__row'>
                <FormLabel>Appartment</FormLabel>
                <FormTextInput name='address.apt' control={control} />
              </FormControl>
            </Stack>

            <Stack direction='row' spacing={2}>
              <FormControl className='add-project-modal__row'>
                <FormLabel>City</FormLabel>
                <FormTextInput name='address.city' control={control} />
              </FormControl>
              <FormControl className='add-project-modal__row'>
                <FormLabel>State</FormLabel>
                <FormSelectInput
                  control={control}
                  name='address.state'
                  options={webStates ?? []}
                  getOptionLabel={(w) => w.name}
                />
              </FormControl>
            </Stack>

            <Stack direction='row' spacing={2}>
              <FormControl className='add-project-modal__row'>
                <FormLabel>House</FormLabel>
                <FormTextInput type='number' name='address.house' control={control} />
              </FormControl>
              <FormControl className='add-project-modal__row'>
                <FormLabel>Zip</FormLabel>
                <FormTextInput type='number' name='address.zip' control={control} />
              </FormControl>
            </Stack>
          </div>
        </SubmitModalLayout>
      )}
    </UIModal>
  );
};

export default React.forwardRef(EditEmployeeModal);
