import { FormControl, FormLabel, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import _ from 'lodash';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { EmployeeInviteModalProps, EmployeeInviteModalRef } from './types';
import { StaffService } from 'api';
import { CompanyModel } from 'app/models/CompanyModel';
import { WorkerTypesModel } from 'app/models/StaffModel';
import FormPhoneInput from 'shared/Inputs/FormPhoneInput/FormPhoneInput';
import { getCleanupPhone } from 'app/helpers/formatPhone';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import FormCompaniesSelect from '../../Inputs/Filters/FormCompaniesSelect';
import { getFCompanies } from 'store/slices/filters';
import './EmployeeInviteModal.sass';

interface SubmitData {
  type?: WorkerTypesModel;
  company?: CompanyModel;
  phones: { value: string }[];
  [key: string]: any;
}

const defaultValues: SubmitData = {
  company: undefined,
  type: undefined,
  phones: [
    {
      value: '',
    },
  ],
};

const EmployeeInviteModal: React.ForwardRefRenderFunction<EmployeeInviteModalRef, EmployeeInviteModalProps> = (
  { getData },
  ref
) => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, watch, reset, setError } = useForm<SubmitData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'phones',
  });
  const type: WorkerTypesModel | undefined = watch('type');

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { workerTypes } = useAppSelector((state) => state.staff);
  const { companies } = useAppSelector((state) => state.companies);

  useEffect(() => {
    dispatch(getFCompanies({ reset: true }));
  }, [dispatch]);

  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        reset(defaultValues);
        setOpen(true);
      },
      hide: () => setOpen(false),
    }),
    []
  );

  const onSubmitInvite: SubmitHandler<SubmitData> = async (submitData) => {
    try {
      setLoading(true);

      const data = {
        type: submitData?.type?.id!,
        uniqueC: submitData?.company?.unique!,
        phones: submitData.phones?.map((phone) => getCleanupPhone(phone.value)),
      };

      if (data.type === 'supervisor') _.omit(data, ['company']);

      await StaffService.inviteEmployee(data);
      getData && (await getData());

      reset(defaultValues);

      setOpen(false);
    } catch (e) {
      const { phoneIndexes, error } = e.response.data;

      if (phoneIndexes.length && error.msg) {
        phoneIndexes.forEach((idx: number) => {
          setError(`phones[${idx}].value`, { message: error.msg });
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'add-supervisor-modal',
        paper: 'add-supervisor-modal__paper',
      }}
    >
      <SubmitModalLayout
        title='Invite'
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit(onSubmitInvite)}
        loading={loading}
        submitBtnText='Send'
      >
        <div className='add-supervisor-modal__inputs'>
          {workerTypes && (
            <FormControl className='add-supervisor-modal__row'>
              <FormLabel required>Type</FormLabel>
              <FormSelectInput
                control={control}
                name='type'
                options={workerTypes}
                getOptionLabel={(o) => o.name}
                rules={{ required: 'Required field' }}
              />
            </FormControl>
          )}
          {companies.data && type?.id === 'clientSupervisor' && (
            <FormControl className='add-supervisor-modal__row'>
              <FormLabel required>Company</FormLabel>
              <FormCompaniesSelect control={control} name='company' rules={{ required: 'Required field' }} />
            </FormControl>
          )}
          {fields &&
            fields.map((field, index) => (
              <FormControl className='add-supervisor-modal__row' key={field.id}>
                <FormLabel required>
                  {index + 1}
                  {nth(index + 1)} Person’s phone number
                </FormLabel>
                <Stack direction='row' alignItems='center' spacing='1.2rem'>
                  <FormPhoneInput
                    control={control}
                    name={`phones[${index}].value`}
                    rules={{
                      required: 'Required field',
                      validate: (value) => {
                        console.log('phone value: ', value);
                        if (!value.replace(/\D/g, '').length) {
                          return 'Required field';
                        }
                      },
                    }}
                    isArrayField
                    placeholder='+1 (   )    -'
                    nameKey='phones'
                    position={index}
                    style={{ width: '100%' }}
                  />
                  {index > 0 && (
                    <div className='add-phone__delete' onClick={() => remove(index)}>
                      <CloseIcon />
                    </div>
                  )}
                </Stack>
              </FormControl>
            ))}
          <div className='add-phone' onClick={() => append({ value: '' })}>
            <PlusIcon className='add-phone__icon' />
            Add another person
          </div>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(EmployeeInviteModal);
