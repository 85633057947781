import axios from 'axios';
import { signOut } from 'store/slices/auth';
import { store } from 'store/store';

const getBaseUrl = () => {
  return 'https://devapi.sooperwise.com';
  // return 'https://api.sooperwise.com';
  switch (window.location.hostname) {
    case 'app.sooperwise.com':
      return 'https://api.sooperwise.com';

    case 'localhost':
    case 'testapp.sooperwise.com':
      // return 'https://api.sooperwise.com';
      return 'https://testapi.sooperwise.com';
  }
};

const instance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const setInterceptors = (customStore: typeof store) => {
  instance.interceptors.request.use(
    (config) => {
      const { token } = customStore.getState().auth;
      if (token && config.headers && !config.auth?.username) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => Promise.resolve(error)
  );

  instance.interceptors.response.use(
    (config) => {
      return Promise.resolve(config);
    },
    (error) => {
      const { dispatch } = customStore;

      const status = error.status ?? error.response?.status;
      if (status === 401) dispatch(signOut());

      return Promise.reject(error);
    }
  );
};

export default instance;
