import { Stack } from '@mui/material';
import { StaffService } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useFilters from 'app/hooks/useFilters';
import history from 'app/routes/history';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as PlaceholderProfileIcon } from 'assets/icons/table/profile-2user.svg';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import AddCrewModal from 'shared/Modals/AddCrewModal/AddCrewModal';
import { AddCrewModalRef } from 'shared/Modals/AddCrewModal/types';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import { ModalBaseRef } from 'shared/Modals/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import { getCrews } from 'store/slices/staff';

import './Crew.sass';

const limit = 12;

const initialFilterParams = {
  page: 1,
  search: '',
};

interface SubmitData {
  search: string;
}

const Crew: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { control, handleSubmit, setValue } = useForm<SubmitData>();
  const { crews, crewsLoading } = useAppSelector((state) => state.staff);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const addCrewModalRef = useRef<AddCrewModalRef>(null);
  const confirmModalRef = useRef<ModalBaseRef>(null);

  useEffect(() => {
    const { search, tab } = qs.parse(location.search, { ignoreQueryPrefix: true });
    setValue('search', (search as string) ?? '');

    if (tab === 'crew') {
      dispatch(getCrews({ params: { ...getNonEmptyFilters(), pageSize: limit } }));
    }
  }, [location, setValue]);

  const { replaceUrl, onChangeFilter, getNonEmptyFilters } = useFilters({
    initialFilterParams,
    limit,
  });

  const getData = () => {
    dispatch(getCrews({ force: true, params: { ...getNonEmptyFilters(), pageSize: limit } }));
  };

  const deleteCrew = (id: number) => {
    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);

        await StaffService.deleteCrew(id);
        getData();
      } finally {
        setLoading(false);
        confirmModalRef.current?.hide();
      }
    });
  };

  const changePage = (value: number) => {
    const filters = onChangeFilter({ page: value });
    replaceUrl(filters);

    dispatch(getCrews({ force: true, params: { ...getNonEmptyFilters(filters), pageSize: limit } }));
    setPage(value);
  };

  const submit: SubmitHandler<SubmitData> = ({ search }) => {
    const filters = onChangeFilter({ search, page: 1 });
    replaceUrl(filters);
    setPage(1);

    dispatch(getCrews({ force: true, params: { ...getNonEmptyFilters(filters), pageSize: limit } }));
  };

  return (
    <>
      <UITable
        onRowClick={(elem) => {
          history.push(`/crews/${elem.unique}`);
        }}
        className='crews__table'
        totalPages={crews?.totalPages}
        title={{
          title: 'List of Crews',
          button: {
            text: 'Add crew',
            type: 'button',
            icon: 'plus',
            onClick: () => addCrewModalRef.current?.show(),
          },
          children: (
            <FormTextInput
              control={control}
              name='search'
              placeholder='Search by name'
              InputProps={{
                endAdornment: (
                  <button className='find-button' type='submit' onClick={handleSubmit(submit)}>
                    <SearchIcon />
                  </button>
                ),
                sx: { background: 'white', width: '304px' },
              }}
            />
          ),
        }}
        data={crews?.data}
        loading={crewsLoading}
        headers={[{ label: 'Name' }, { label: 'Specialities' }, { label: 'Amount of People' }, { label: 'Actions' }]}
        columns={[
          { renderCol: ({ name }) => <div className='crews__name'>{name}</div> },
          { renderCol: ({ specialities }) => <div className='crews__specialities'>{specialities}</div> },
          { columnName: 'numberOfEmployees' },
          {
            renderCol: (i) => {
              return (
                <Stack direction='row' spacing={2}>
                  <UIActionButton type='edit' onClick={() => addCrewModalRef.current?.show(i)} />
                  <UIActionButton type='delete' onClick={() => deleteCrew(i.unique)} />
                </Stack>
              );
            },
          },
        ]}
        pagination={{
          count: crews?.totalPages ?? 1,
          page,
          onChange: (_, value) => changePage(value),
        }}
        placeholder={{
          icon: <PlaceholderProfileIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of crews is empty. It will be displayed here.',
        }}
      />
      <AddCrewModal ref={addCrewModalRef} getData={getData} />
      <ConfirmModal ref={confirmModalRef} title='Delete' loading={loading}>
        Do you really want to delete this crew? You will not be able to undo this.
      </ConfirmModal>
    </>
  );
};

export default Crew;
