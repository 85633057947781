import { io, Socket } from 'socket.io-client';
import { notify } from 'app/helpers/notify';
import { MessageModel } from 'app/models/ChatModel';
import { store } from 'store';
import { setChatHistory, setChtHistoryLoading, setCurrentChatId, setNewMessage } from 'store/slices/chats';
import { incrementNotificationCounter } from '../store/slices/notifications';

const getSocketUrl = () => {
  return 'https://testsocket.sooperwise.com/';
};

class SocketSingleton {
  private static instance: SocketSingleton;
  private readonly socket: Socket;
  loggedIn: boolean = false;

  private constructor() {
    this.socket = io(getSocketUrl(), {
      transports: ['websocket'],
      reconnection: true,
      autoConnect: true,
    });
  }

  public static getInstance(): SocketSingleton {
    if (!SocketSingleton.instance) {
      SocketSingleton.instance = new SocketSingleton();
    }

    return SocketSingleton.instance;
  }

  public login(data: { token: string }): Promise<boolean> {
    return new Promise((resolve) => {
      this.socket.emit('log_in', data);

      this.socket.on('log_in', (data: Record<any, any>) => {
        this.loggedIn = !!data;
        resolve(this.loggedIn);
      });
    });
  }

  public enableNotification() {
    this.socket.on('notification_alert', (data) => {
      if (data.type === 'newFileInMessage' || data.type === 'newManagerMessage') {
        const chatId = store.getState().chats.currentChatId;

        if (chatId) {
          this.getChatHistory({ unique: chatId });
        }
      } else {
        store.dispatch(incrementNotificationCounter());

        notify('Please check notifications!', { type: 'info', position: 'top-right' });
      }
    });
  }

  public getChatHistory(data: { unique: number }) {
    this.socket.emit('get_history', data);
  }

  public onMessage(unique: number) {
    this.socket.on('message', (message: MessageModel) => {
      if (message.own) {
        store.dispatch(setNewMessage(message));
      }

      this.getChatHistory({ unique });
    });
  }

  public onHistory() {
    store.dispatch(setChtHistoryLoading(true));
    this.socket.on('history', (response: MessageModel[]) => {
      store.dispatch(setChatHistory(response));
      store.dispatch(setChtHistoryLoading(false));
    });
  }

  public onDeleteMessage(unique: number) {
    this.socket.on('delete_message', (response: { success: boolean }) => {
      if (response.success) {
        this.getChatHistory({ unique });
      }
    });
  }

  public onEditMessage(unique: number) {
    this.socket.on('edit_message', () => {
      this.getChatHistory({ unique });
    });
  }

  public joinRoom(data: { unique: number }) {
    this.socket.emit('join_room', data);

    this.socket.on('join_room', (response: { chat_id: number; action: string; user_id: number[] }) => {
      if (response.chat_id) {
        this.getChatHistory({ unique: data.unique });
        store.dispatch(setCurrentChatId(response.chat_id));
      }

      this.onHistory();
      this.onMessage(data.unique);
      this.onDeleteMessage(data.unique);
      this.onEditMessage(data.unique);
    });
  }

  public exitRoom(data: { unique: number }) {
    this.socket.emit('exit_room', data);

    this.socket.off('join_room');
    this.socket.off('history');
    this.socket.off('message');
    this.socket.off('delete_message');
    this.socket.off('edit_message');
    this.socket.off('newFileInMessage');
  }

  public sendMessage(data: { scheduleId: number; text: string; replyTo?: number }) {
    this.socket.emit('message', data);
  }

  public editMessage(data: { unique: number; scheduleId: number; text: string }) {
    this.socket.emit('edit_message', data);
  }

  public deleteMessage(data: { scheduleId: number; unique: number; hasFile: boolean }) {
    this.socket.emit('delete_message', data);
  }
}

export default SocketSingleton;
