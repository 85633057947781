import { FormControl, FormLabel, Stack } from '@mui/material';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import UIModal from 'shared/ui/UIModal/UIModal';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { StaffService } from 'api';
import { encodeBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LicenseModel, WebLicenceTypesModel, webLicenseStatusesModel } from 'app/models/StaffModel';
import dayjs from 'dayjs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import FormDateInput from 'shared/Inputs/FormDateInput/FormDateInput';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import { getEmployee } from 'store/slices/staff';
import { AddLicenseModalProps, AddLicenseModalRef } from './types';
import UploadFiles from './components/UploadFiles';
import { UploadFileModel } from './components/UploadFiles/types';

interface SubmitData {
  unique: number;
  number: string;
  type: WebLicenceTypesModel | null;
  expirationDate: Date | null;
  issuedBy: string;
  statusId: webLicenseStatusesModel | null;
}

const AddLicenseModal: React.ForwardRefRenderFunction<AddLicenseModalRef, AddLicenseModalProps> = ({}, ref) => {
  const dispatch = useAppDispatch();
  const { id } = useParams() as { id: string };

  const { control, handleSubmit, setValue, reset } = useForm<SubmitData>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const { webLicenseTypes, webLicenseStatuses } = useAppSelector((state) => state.staff);
  const [currentLicense, setCurrentLicense] = useState<LicenseModel | null>(null);

  const [files, setFiles] = useState<UploadFileModel[]>([]);
  const [deleteFilesId, setDeleteFilesId] = useState<number[]>([]);

  useImperativeHandle(
    ref,
    () => ({
      show: (data) => {
        setCurrentLicense(null);
        clearFields();

        if (data) {
          if (data.licenseImages.length) {
            setFiles(
              data.licenseImages.map(({ base64, extension, unique }) => ({
                preview: encodeBase64(base64, extension),
                extension,
                base64,
                unique,
              }))
            );
          }
          setCurrentLicense(data);
        }

        setUpdate(data ? true : false);
        setOpen(true);
      },
      hide: () => {
        setCurrentLicense(null);
        setOpen(false);
      },
    }),
    []
  );

  useEffect(() => {
    if (!currentLicense) return clearFields();
    const { unique, expirationDate, type, number, issuedBy, status } = currentLicense;

    setValue('unique', unique);
    setValue('expirationDate', dayjs(expirationDate, 'DD/MM/YYYY').toDate());
    setValue('type', webLicenseTypes?.find((w) => w.id === type.id) ?? null);
    setValue('statusId', webLicenseStatuses?.find((w) => w.name === status) ?? null);
    setValue('number', number);
    setValue('issuedBy', issuedBy);
  }, [currentLicense, reset, setValue, webLicenseStatuses, webLicenseTypes]);

  const addLicense: SubmitHandler<SubmitData> = async (data) => {
    if (!files.length) return alert('Please, choose License image');

    try {
      setLoading(true);

      const license = await StaffService.addLicense({
        unique: +id,
        number: data.number,
        type: data.type?.id!,
        issuedBy: data.issuedBy,
        statusId: data.statusId?.id!,
        expirationDate: dayjs(data.expirationDate).format('DD/MM/YYYY'),
      });

      if (files.length) {
        await StaffService.addLicenseFile({
          uniqueE: license.data.unique,
          files: files.map(({ extension, base64 }) => ({
            file: base64.split('base64,')[1],
            extension: extension,
          })),
        });
      }

      setOpen(false);
      dispatch(getEmployee(+id));
      clearFields();
    } finally {
      setLoading(false);
    }
  };

  const editLicense: SubmitHandler<SubmitData> = async (data) => {
    if (!currentLicense) return;

    try {
      setLoading(true);

      await StaffService.editLicense({
        unique: currentLicense.unique,
        number: data.number,
        type: data.type?.id!,
        issuedBy: data.issuedBy,
        statusId: data.statusId?.id!,
        expirationDate: dayjs(data.expirationDate).format('DD/MM/YYYY'),
      });

      if (deleteFilesId.length) {
        await Promise.all(
          deleteFilesId.map(async (unique) => {
            return await StaffService.deleteLicenseFile(unique);
          })
        );
      }

      const newFiles = files.filter((f) => f.isFileUpdated);

      if (newFiles.length) {
        await StaffService.addLicenseFile({
          uniqueE: currentLicense.unique,
          files: newFiles.map(({ extension, base64 }) => ({
            file: base64.split('base64,')[1],
            extension: extension,
          })),
        });
      }

      setOpen(false);
      dispatch(getEmployee(+id));
    } finally {
      setLoading(false);
    }
  };

  const clearFields = () => {
    reset();
    reset({ number: '', type: null, expirationDate: null, issuedBy: '', statusId: null });
    setFiles([]);
    setDeleteFilesId([]);
  };

  return (
    <UIModal
      open={open}
      onClose={() => setOpen(false)}
      classes={{
        root: 'add-crew-modal',
        paper: 'add-crew-modal__paper',
      }}
    >
      <SubmitModalLayout
        onSubmit={update ? handleSubmit(editLicense) : handleSubmit(addLicense)}
        title={update ? 'Edit License' : 'Add License'}
        onClose={() => setOpen(false)}
        submitBtnText={update ? 'Edit' : 'Save'}
        loading={loading}
      >
        <div className='add-crew-modal__inputs'>
          <UploadFiles
            files={files}
            setFiles={setFiles}
            deleteFilesId={deleteFilesId}
            setDeleteFilesId={setDeleteFilesId}
          />
          <FormControl className='add-crew-modal__row'>
            <FormLabel required>Number</FormLabel>
            <FormTextInput name='number' control={control} rules={{ required: 'Required field' }} />
          </FormControl>
          <Stack direction='row' spacing={2}>
            <FormControl className='add-crew-modal__row'>
              <FormLabel required>Type</FormLabel>
              <FormSelectInput
                name='type'
                control={control}
                options={webLicenseTypes?.filter((w) => !!w.name) ?? []}
                getOptionLabel={(option) => option.shortName}
                rules={{
                  required: 'Required field',
                }}
              />
            </FormControl>
            <FormControl className='add-crew-modal__row'>
              <FormLabel required>Expiration Date</FormLabel>
              <FormDateInput
                value={null}
                name='expirationDate'
                control={control}
                rules={{ required: 'Required field' }}
              />
            </FormControl>
          </Stack>
          <Stack direction='row' spacing={2}>
            <FormControl className='add-crew-modal__row'>
              <FormLabel required>Issued by</FormLabel>
              <FormTextInput name='issuedBy' control={control} rules={{ required: 'Required field' }} />
            </FormControl>
            <FormControl className='add-crew-modal__row'>
              <FormLabel required>Status</FormLabel>
              <FormSelectInput
                name='statusId'
                control={control}
                options={webLicenseStatuses ?? []}
                getOptionLabel={(option) => option.name}
                rules={{
                  required: 'Required field',
                }}
              />
            </FormControl>
          </Stack>
        </div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(AddLicenseModal);
