import { Stack, Switch } from '@mui/material';
import { getDateFromTime } from 'app/helpers/formatDate';
import { getShortText } from 'app/helpers/getShortText';
import { getLicenseColor } from 'pages/LicenseDetailsPage/helpers';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ModalBaseRef } from 'shared/Modals/types';
import Status from 'shared/ui/Status/Status';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UIButton from 'shared/ui/UIButton/UIButton';
import UITable from 'shared/ui/UITable/UITable';
import UITimePicker from 'shared/ui/UITimePicker/UITimePicker';
import { ScheduleWorker } from '../../types';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import './WorkersTable.sass';

interface Props {
  data: ScheduleWorker[];
  removeHandler: (i: number, removeCrew: boolean) => void;
  onChangeChatAccess: (
    type: 'chat' | 'foreman',
    worker: ScheduleWorker & { chatAccess?: boolean },
    checked: boolean
  ) => void;
  showCrewsModal?: () => void;
  showWorkersModal?: () => void;
  onChangeWorkerTime: (index: number, value: Date | null) => void;
  startTime: { time: Date; isValid: boolean };
}

const WorkersTable: React.FC<Props> = ({
  data,
  removeHandler,
  onChangeChatAccess,
  showCrewsModal,
  showWorkersModal,
  onChangeWorkerTime,
  startTime,
}) => {
  const [checkbox] = useState([
    { value: 'one', label: 'Delete selected worker only' },
    { value: 'crew', label: 'Delete the whole crew' },
  ]);

  const confirmModalRef = useRef<ModalBaseRef>(null);

  const deleteWorker = (index: number) => {
    confirmModalRef.current?.show((data: typeof checkbox[0]) => {
      removeHandler(index, data.value === 'crew');

      confirmModalRef.current?.hide();
    });
  };

  return (
    <>
      <UITable
        className='workers-table'
        withTabs
        data={data}
        headers={[
          { label: 'Full Name' },
          { label: 'Licenses' },
          { label: 'Foreman' },
          { label: 'Start Time' },
          { label: 'Chat' },
          { label: 'Status' },
          { label: 'Actions' },
        ]}
        columns={[
          {
            renderCol: (i) => (
              <div>
                <Link className='workers-table__link workers-table__fullname' to={`/employees/${i.unique}`}>
                  {i.name}
                </Link>
                {!!i?.specialities?.length && (
                  <p className='workers-table__specialities'>{getShortText(i.specialities, 63)}</p>
                )}
              </div>
            ),
          },
          {
            renderCol: (i) => (
              <div className='workers-table__licenses'>
                {i?.licenses?.map((el, index) => (
                  <Status key={index} text={el.type.shortName} color={getLicenseColor(el)} />
                ))}
              </div>
            ),
          },
          {
            renderCol: (i) => (
              <Switch
                defaultChecked={i.isForeman}
                onChange={(_event, checked) => onChangeChatAccess('foreman', i, checked)}
              />
            ),
          },
          {
            renderCol: (i, index) => {
              const date = getDateFromTime(i.startTime);

              return (
                <div className='workers-table__time'>
                  <UITimePicker value={date ?? new Date()} onChange={(date) => onChangeWorkerTime(index, date)} />
                </div>
              );
            },
            noWrap: true,
          },
          {
            renderCol: (i) => (
              <Switch
                defaultChecked={i.chatAccess}
                onChange={(_event, checked) => onChangeChatAccess('chat', i, checked)}
              />
            ),
          },
          { renderCol: (i) => <Status text={i.status} color='primary' /> },
          {
            renderCol: (_, index) => (
              <Stack direction='row' spacing={2}>
                <UIActionButton type='delete' onClick={() => deleteWorker(index)} />
              </Stack>
            ),
          },
        ]}
        title={{
          title: 'List of workers',
          button: {
            text: 'Add worker',
            icon: 'plus',
            square: true,
            onClick: showWorkersModal,
            disabled: !startTime.isValid,
          },
          children: (
            <UIButton
              text='Add worker from crew'
              color='white'
              square
              onClick={showCrewsModal}
              disabled={!startTime.isValid}
            />
          ),
        }}
      />
      <ConfirmDeleteModal
        data={checkbox}
        ref={confirmModalRef}
        title='Delete'
        text='Do you want to delete selected worker only or the whole crew? Please, select one option'
        submitBtnText='Confirm'
      />
    </>
  );
};

export default WorkersTable;
