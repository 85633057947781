import { Stack } from '@mui/material';
import { companiesService } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useFilters from 'app/hooks/useFilters';
import { CompanyModel } from 'app/models/CompanyModel';
import history from 'app/routes/history';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AddCompanyModal from 'shared/Modals/AddCompanyModal/AddCompanyModal';
import { AddCompanyModalRef } from 'shared/Modals/AddCompanyModal/types';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import { ModalBaseRef } from 'shared/Modals/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import UITitle from 'shared/ui/UITitle/UITitle';
import { getCompanies } from 'store/slices/companies';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as PlaceholderBriefcaseIcon } from 'assets/icons/table/briefcase.svg';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';

const limit = 12;

const initialFilterParams = {
  page: 1,
  search: '',
};

interface SubmitData {
  search: string;
}

const CompaniesPage: React.FC = () => {
  const { handleSubmit, control } = useForm<SubmitData>();
  const { companies, companiesLoading } = useAppSelector((state) => state.companies);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(1);

  const addCompanyModalRef = useRef<AddCompanyModalRef>(null);
  const configmModalRef = useRef<ModalBaseRef>(null);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const { replaceUrl, onChangeFilter, getNonEmptyFilters } = useFilters({
    initialFilterParams,
    limit,
    initialCallback: (params) => dispatch(getCompanies({ force: true, params: { ...params, pageSize: limit } })),
  });

  useEffect(() => {
    const page = qs.parse(location.search, { ignoreQueryPrefix: true }).page;

    setPage(page ? +page : 1);
  }, [location.search]);

  const deleteCompanyHandler = (company: CompanyModel) => {
    configmModalRef.current?.show(async () => {
      try {
        setDeleteLoading(true);
        await companiesService.deleteCompany(company.unique);
        getData();
        configmModalRef.current?.hide();
      } finally {
        setDeleteLoading(false);
      }
    });
  };

  const getData = () => {
    dispatch(getCompanies({ force: true, params: { ...getNonEmptyFilters(), pageSize: limit } }));
  };

  const changePage = (value: number) => {
    const filters = onChangeFilter({ page: value });
    replaceUrl(filters);
    dispatch(getCompanies({ force: true, params: { ...getNonEmptyFilters(filters), pageSize: limit } }));
    setPage(value);
  };

  const submit: SubmitHandler<SubmitData> = ({ search }) => {
    const filters = onChangeFilter({ page: 1, search });
    replaceUrl(filters);
    dispatch(getCompanies({ force: true, params: { ...getNonEmptyFilters(filters), pageSize: limit } }));
  };

  return (
    <div className='companies-page'>
      <UITitle
        title='Companies'
        button={{
          text: 'Add company',
          color: 'primary',
          icon: 'plus',
          onClick: () => addCompanyModalRef.current?.show(),
        }}
      >
        <FormTextInput
          control={control}
          name='search'
          placeholder='Search by Name'
          InputProps={{
            endAdornment: (
              <button className='find-button' type='submit' onClick={handleSubmit(submit)}>
                <SearchIcon />
              </button>
            ),
            sx: { background: 'white', width: '304px' },
          }}
        />
      </UITitle>
      <UITable
        totalPages={companies.totalPages}
        loading={companiesLoading}
        data={companies.data}
        onRowClick={(elem) => {
          history.push(`/companies/${elem.unique}`);
        }}
        headers={[
          { label: 'Name' },
          { label: 'Address' },
          { label: 'Phone' },
          { label: 'Email' },
          { label: 'Actions' },
        ]}
        columns={[
          { columnName: 'name' },
          { renderCol: (i) => i.address?.[0]?.description && i.address[0].description },
          { columnName: 'phone' },
          { columnName: 'email' },
          {
            renderCol: (company) => {
              return (
                <Stack direction='row' spacing={2}>
                  <UIActionButton type='edit' onClick={() => addCompanyModalRef.current?.show({ data: company })} />
                  <UIActionButton
                    type='delete'
                    onClick={() => {
                      deleteCompanyHandler(company as CompanyModel);
                    }}
                  />
                </Stack>
              );
            },
          },
        ]}
        pagination={{
          count: companies.totalPages ?? 1,
          page,
          onChange: (_, value) => changePage(value),
        }}
        placeholder={{
          icon: <PlaceholderBriefcaseIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of companies is empty. Add your first company now.',
        }}
      />
      <AddCompanyModal ref={addCompanyModalRef} getData={getData} />
      <ConfirmModal loading={deleteLoading} ref={configmModalRef} title='Delete'>
        Do you really want to delete this company? You will not be able to undo this.
      </ConfirmModal>
    </div>
  );
};

export default CompaniesPage;
