import instance from 'api/instance';
import { EditProfile, GetToken } from './types';

export const auth: GetToken = (username, password) => {
  return instance.get('/exec?action=web_auth', {
    auth: {
      username,
      password,
    },
  });
};

export const editProfile: EditProfile = (body) => {
  return instance.post('/exec?action=edit_profile', body);
};
