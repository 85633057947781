import instance from 'api/instance';
import {
  AddProject,
  AddProjectFile,
  AddProjectFileData,
  AddSupervisors,
  DeleteProject,
  DeleteProjectFile,
  DeleteSupervisor,
  EditProject,
  EditProjectFileData,
  GetPaymentOptions,
  GetProject,
  GetProjectFiles,
  GetProjectSchedules,
  GetProjects,
  GetProjectsStatuses,
  GetSupervisors,
} from './types';

export const getProjectStatuses: GetProjectsStatuses = () => {
  return instance.get('/exec?action=get_projectStatuses');
};

export const getPaymentOptions: GetPaymentOptions = () => {
  return instance.get('/exec?action=get_paymentOptions');
};

export const getProjects: GetProjects = (body) => {
  return instance.post('/exec?action=get_projects', body);
};

export const getProject: GetProject = (id: number) => {
  return instance.post('/exec?action=get_project', {
    unique: id,
  });
};

export const addProject: AddProject = (body) => {
  return instance.post('/exec?action=add_project', body);
};

export const editProject: EditProject = (body) => {
  return instance.post('/exec?action=edit_project', body);
};

export const deleteProject: DeleteProject = (unique: number) => {
  return instance.post('/exec?action=delete_project', {
    unique,
  });
};

export const getProjectSchedules: GetProjectSchedules = (body) => {
  return instance.post('/exec?action=get_projects_schedules', body);
};

export const getProjectFiles: GetProjectFiles = (body) => {
  return instance.post('/exec?action=getProject_files', body);
};

export const addFileData: AddProjectFileData = (body) => {
  return instance.post('/exec?action=add_projectFileData', body);
};

export const addFile: AddProjectFile = (body) => {
  return instance.post('/exec?action=post_projectFile', body);
};

export const deleteFile: DeleteProjectFile = (unique) => {
  return instance.post('/exec?action=delete_projectFile', {
    unique,
  });
};

export const getSupervisors: GetSupervisors = (data) => {
  return instance.post('/exec?action=get_supervisors', data);
};

export const addSupervisors: AddSupervisors = (body) => {
  return instance.post('/exec?action=add_supervisors', body);
};

export const deleteSupervisor: DeleteSupervisor = (unique) => {
  return instance.post('/exec?action=delete_supervisor', {
    unique,
  });
};

export const editFileData: EditProjectFileData = (body) => {
  return instance.post('/exec?action=edit_projectFileData', body);
};
