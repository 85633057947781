import { Controller, Path, PathValue } from 'react-hook-form';
import { UIDatePickerProps } from 'shared/ui/UIDatePicker/types';
import UIDatePicker from 'shared/ui/UIDatePicker/UIDatePicker';
import BaseFormInputProps from '../types';

function FormDateInput<T>({
  name,
  control,
  rules,
  onChange: onDateChange,
  defaultValue,
  ...rest
}: BaseFormInputProps<T> & UIDatePickerProps & { defaultValue?: PathValue<T, Path<T>> }) {
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, formState: { errors } }) => {
        let error = (errors[name]?.message as unknown as string) ?? '';
        //@ts-ignore
        if (errors[name]?.type === 'validate') {
          error = 'Invalid Format';
        }
        return (
          <UIDatePicker
            {...rest}
            onBlur={onBlur}
            onChange={(date, formatedDate) => {
              onChange(date);
              onDateChange?.(date, formatedDate);
            }}
            value={(value as Date) ?? null}
            invalid={!!error}
            helperText={error}
          />
        );
      }}
    />
  );
}

export default FormDateInput;
