import { formatDate } from 'app/helpers/formatDate';
import { useAppSelector } from 'app/hooks';
import UITable from 'shared/ui/UITable/UITable';

import { ReactComponent as PlaceholderCalendarIcon } from 'assets/icons/table/calendar.svg';

import './AttendanceDetails.sass';

const AttendanceDetails = () => {
  const { currentProject } = useAppSelector((state) => state.projects);

  return (
    <UITable
      className='attendance-details__table'
      data={currentProject?.attendance}
      headers={[
        { label: '#' },
        { label: 'Address' },
        { label: 'Date' },
        { label: 'Company' },
        { label: 'Total Workers' },
        { label: 'Total Hours' },
      ]}
      columns={[
        { renderCol: (_, index) => index + 1 },
        { renderCol: ({ address }) => <div className='attendance-details__table-td--address'>{address}</div> },
        {
          renderCol: ({ date }) => formatDate({ date, parse: date.length === 8 ? 'MM/DD/YY' : 'M/D/YY' }),
        },
        { columnName: 'company' },
        { columnName: 'totalWorkers' },
        { columnName: 'totalHours' },
      ]}
      placeholder={{
        icon: <PlaceholderCalendarIcon />,
        title: 'Sorry, it is empty here!',
        subtitle: 'The list of attendace is empty. It will be displayed here.',
      }}
    />
  );
};

export default AttendanceDetails;
