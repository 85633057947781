import { FormControl, FormLabel, Stack } from '@mui/material';
import { projectsService, utilsService } from 'api';
import { downloadBlobFile } from 'app/helpers/downloadBlobFile';
import { getFileExtension } from 'app/helpers/getFileShortName';
import { toBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import useFilters from 'app/hooks/useFilters';
import { ProjectFileModel } from 'app/models/ProjectModel';
import { ReactComponent as PlaceholderFolderIcon } from 'assets/icons/table/folder-open.svg';
import qs from 'qs';
import { FC, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import FormSelectInput from 'shared/Inputs/FormSelectInput/FormSelectInput';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import AddFileModal from 'shared/Modals/AddFileModal/AddFileModal';
import { AddFileModalRef } from 'shared/Modals/AddFileModal/types';
import ConfirmModal from 'shared/Modals/ConfirmModal/ConfirmModal';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';
import { ModalBaseRef } from 'shared/Modals/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import { getProjectFiles } from 'store/slices/projects';
import { FileType } from 'store/slices/utils/types';

interface SubmitData {
  name: string;
  type: FileType;
}

const limit = 10;
const FilesDetails: FC = () => {
  const { id } = useParams() as { id: string };
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { currentProject, currentProjectFilesFilter, currentProjectFilesLoading, currentProjectFiles } = useAppSelector(
    (state) => state.projects
  );
  const { fileTypes } = useAppSelector((state) => state.utils);
  const { control, handleSubmit, reset, setValue } = useForm<SubmitData>();

  const addFilesModalRef = useRef<AddFileModalRef>(null);
  const confirmModalRef = useRef<ModalBaseRef>(null);
  const previewImageModalRef = useRef<PreviewImageModalRef>(null);

  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const initialFilterParams = {
    page: +(qs.parse(location.search, { ignoreQueryPrefix: true }).page ?? 1),
  };

  const { replaceUrl, onChangeFilter } = useFilters({
    initialFilterParams,
    limit,
    initialCallback: (params) => dispatch(getProjectFiles({ params: { ...params, pageSize: limit, unique: +id } })),
  });

  const onSubmitHandler: SubmitHandler<SubmitData> = async (data) => {
    if (!addFilesModalRef.current) return;

    const { files } = addFilesModalRef.current.handleSubmit();
    if (!currentProject || !files.length) return addFilesModalRef.current.validate();

    try {
      setSubmitLoading(true);

      const file = files[0];
      const addFileData = {
        unique: +id,
        name: data.name ?? file.name,
        type: data.type.id,
      };

      const { unique } = await projectsService.addFileData(addFileData).then((res) => res.data);

      await toBase64(files[0]).then(async (result: string) => {
        return await projectsService.addFile({
          unique: unique,
          extension: getFileExtension(file.name),
          file: result.split('base64,')[1],
          attachmentName: file.name,
        });
      });

      reset();
      addFilesModalRef.current.hide();
      addFilesModalRef.current.clearFiles();

      dispatch(getProjectFiles({ params: currentProjectFilesFilter }));
    } finally {
      setSubmitLoading(false);
    }
  };

  const onSubmitUpdateHandler: SubmitHandler<SubmitData> = async (data) => {
    if (!addFilesModalRef.current) return;
    const { data: fileData, files } = addFilesModalRef.current.handleSubmit();
    if (!currentProject || !files.length || !fileData) return addFilesModalRef.current.validate();

    const editFileData = {
      unique: fileData.unique,
      name: data.name ?? files[0].name,
      type: data.type.id,
    };

    try {
      setLoading(true);

      const { unique } = await projectsService.editFileData(editFileData).then((res) => res.data);

      if (fileData.isFileUpdated) {
        await toBase64(files[0]).then(async (result: string) => {
          await projectsService.addFile({
            unique: unique,
            extension: getFileExtension(files[0].name),
            file: result.split('base64,')[1],
            attachmentName: files[0].name,
          });
        });
      }

      addFilesModalRef.current.hide();
      await dispatch(getProjectFiles({ params: currentProjectFilesFilter }));
      reset();
      addFilesModalRef.current.clearFiles();
    } finally {
      setLoading(false);
    }
  };

  const deleteFile = (unique: number) => {
    confirmModalRef.current?.show(async () => {
      try {
        setLoading(true);

        await projectsService.deleteFile(unique);
        confirmModalRef.current?.hide();

        await dispatch(getProjectFiles({ params: currentProjectFilesFilter }));
      } finally {
        setLoading(false);
      }
    });
  };

  const onAddFileHandler = () => {
    setIsUpdate(false);
    addFilesModalRef.current?.clearFiles();
    reset();

    addFilesModalRef.current?.show();
  };

  const onUpdateClickHandler = (file: ProjectFileModel) => {
    if (!currentProject) return;
    addFilesModalRef.current?.show(file);
    setIsUpdate(true);
    setValue('name', file.name);
    setValue('type', fileTypes.find((el) => el.id == file.type)!);
  };

  const previewFileOrDownload = (item: ProjectFileModel, type?: 'download' | 'preview') => {
    if (type === 'preview') {
      previewImageModalRef.current?.show({ file: { name: item.name, unique: item.unique, extension: item.extension } });
    } else {
      utilsService.getWebFile(item.unique).then((res) => {
        downloadBlobFile(res, item.name, item.extension, true);
      });
    }
  };

  const changePage = (value: number) => {
    const filters = onChangeFilter({ page: value });
    replaceUrl(filters);
    dispatch(getProjectFiles({ params: { ...filters, pageSize: limit, unique: +id } }));
  };

  return (
    <>
      <UITable
        onRowClick={(i) => previewFileOrDownload(i, 'preview')}
        title={{
          title: 'List of Files',
          button: {
            text: 'Add File',
            icon: 'plus',
            onClick: () => onAddFileHandler(),
          },
        }}
        data={currentProjectFiles?.files}
        loading={currentProjectFilesLoading}
        totalPages={currentProjectFiles?.totalPages}
        headers={[{ label: 'Name' }, { label: 'Type' }, { label: 'Actions' }]}
        columns={[
          { columnName: 'name' },
          { renderCol: (i) => fileTypes.find((f) => f.id === i.type)?.name ?? '' },
          {
            renderCol: (file) => {
              return (
                <Stack direction='row' spacing={2}>
                  <UIActionButton type='download' onClick={() => previewFileOrDownload(file, 'download')} />
                  <UIActionButton type='edit' onClick={() => onUpdateClickHandler(file)} />
                  <UIActionButton type='delete' onClick={() => deleteFile(file.unique)} />
                </Stack>
              );
            },
          },
        ]}
        placeholder={{
          icon: <PlaceholderFolderIcon />,
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of files is empty. You can add your first file now.',
        }}
        pagination={{
          count: currentProjectFiles?.totalPages ?? 1,
          page: currentProjectFilesFilter?.page ?? 1,
          onChange: (_, value) => changePage(value),
        }}
      />
      <ConfirmModal ref={confirmModalRef} title='Delete' submitBtnText='Confirm' loading={loading}>
        Do you really want to delete this file? You will not be able to undo this.
      </ConfirmModal>
      <PreviewImageModal ref={previewImageModalRef} />
      <AddFileModal
        ref={addFilesModalRef}
        onSubmit={isUpdate ? handleSubmit(onSubmitUpdateHandler) : handleSubmit(onSubmitHandler)}
        loading={submitLoading}
        maxSize={5}
      >
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <FormTextInput name='name' control={control} />
          </FormControl>
          <FormControl>
            <FormLabel required>Type</FormLabel>
            <FormSelectInput
              name='type'
              control={control}
              options={fileTypes}
              getOptionLabel={(option) => option.name}
              rules={{
                required: 'Required field',
              }}
            />
          </FormControl>
        </Stack>
      </AddFileModal>
    </>
  );
};

export default FilesDetails;
