import React, { useImperativeHandle, useState } from 'react';
import './ConfirmModal.sass';
import SubmitModalLayout from '../SubmitModalLayout/SubmitModalLayout';
import { SubmitModalProps } from '../SubmitModalLayout/types';
import UIModal from 'shared/ui/UIModal/UIModal';
import { ModalBaseRef } from '../types';

interface Props extends Omit<SubmitModalProps, 'children'> {
  children?: React.ReactNode;
}

const ConfirmModal: React.ForwardRefRenderFunction<ModalBaseRef, Props> = (
  { children, onSubmit, title, ...rest },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [submitCallback, setSubmitCallback] = useState<Function>(() => () => {});
  const [modalTitle, setModalTitle] = useState<string>();
  const [modalText, setModalText] = useState<string>();

  useImperativeHandle(
    ref,
    () => ({
      show: (callback, options) => {
        if (callback) {
          setSubmitCallback(() => callback);
        }
        if (options?.text) {
          setModalText(options.text);
        }
        if (options?.title) {
          setModalTitle(options.title);
        }
        setOpen(true);
      },
      hide: () => {
        setModalTitle(undefined);
        setModalText(undefined);
        setOpen(false);
        setSubmitCallback(() => () => {});
      },
    }),
    []
  );

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(event);
    }
    if (submitCallback) {
      await submitCallback();
    }
  };

  return (
    <UIModal open={open} classes={{ root: 'confirm-modal' }} onBackdropClick={() => setOpen(false)}>
      <SubmitModalLayout
        {...rest}
        title={modalTitle ?? title}
        onSubmit={onSubmitHandler}
        onClose={() => setOpen(false)}
      >
        <div className='confirm-modal__text'>{modalText ?? children}</div>
      </SubmitModalLayout>
    </UIModal>
  );
};

export default React.forwardRef(ConfirmModal);
