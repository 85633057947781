import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { companiesService, projectsService, schedulesService, StaffService } from 'api';
import { GetCompaniesResponse } from 'api/companies/types';
import { FiltersState } from './types';
import { FILTERS_LIMIT } from './data';
import { RootState } from 'store';
import { uniqBy } from 'lodash';
import { GetProjectsResponse } from 'api/projects/types';
import { GetCrewsResponse, GetEmployeesResponse } from 'api/staff/types';
import { GetEmployeesForScheduleBody, GetEmployeesForScheduleResponse } from '../../../api/schedules/types';

const initialState: FiltersState = {
  companies: {
    data: [],
  },
  projects: {
    data: [],
  },
  employeesWithoutCrew: {
    data: [],
  },
  crews: {
    data: [],
  },
  employeesForSchedule: {
    data: [],
  },
};

export const getFCompanies = createAsyncThunk<
  GetCompaniesResponse,
  { reset?: boolean; callback?: () => void } | undefined,
  { state: RootState }
>('filters/getCompanies', async (payload, { getState, dispatch }) => {
  if (payload?.reset) dispatch(setFCompaniesPage(1));

  const { companies } = getState().filters;

  const page = companies.page ?? 1;

  if (companies.page && companies.totalPages && companies.page > companies.totalPages) {
    return Promise.reject();
  }

  const companiesResponse = await companiesService
    .getCompanies({ page, pageSize: FILTERS_LIMIT })
    .then((res) => res.data);
  companiesResponse.data = [...companies.data, ...companiesResponse.data];

  dispatch(setFCompaniesPage(page + 1));

  return companiesResponse;
});

export const getFProjects = createAsyncThunk<
  GetProjectsResponse,
  { reset?: boolean; callback?: () => void } | undefined,
  { state: RootState }
>('filters/getProjects', async (payload, { getState, dispatch }) => {
  if (payload?.reset) dispatch(setFProjectsPage(1));

  const { projects } = getState().filters;

  const page = projects.page ?? 1;

  if (projects.page && projects.totalPages && projects.page > projects.totalPages) {
    return Promise.reject();
  }

  const projectsResponse = await projectsService.getProjects({ page, pageSize: FILTERS_LIMIT }).then((res) => res.data);
  projectsResponse.data = [...projects.data, ...projectsResponse.data];

  dispatch(setFProjectsPage(page + 1));

  return projectsResponse;
});

export const getFEmployeesWithoutCrew = createAsyncThunk<
  GetEmployeesResponse,
  { reset?: boolean; callback?: () => void } | undefined,
  { state: RootState }
>('filters/getFEmployeesWithoutCrew', async (payload, { getState, dispatch }) => {
  if (payload?.reset) dispatch(setFEmployeesWithoutCrewPage(1));

  const { employeesWithoutCrew } = getState().filters;

  const page = employeesWithoutCrew.page ?? 1;

  if (
    employeesWithoutCrew.page &&
    employeesWithoutCrew.totalPages &&
    employeesWithoutCrew.page > employeesWithoutCrew.totalPages
  ) {
    return Promise.reject();
  }

  const employeesResponse = await StaffService.getEmployees({
    page,
    pageSize: FILTERS_LIMIT,
    withoutCrew: true,
    type: 'worker',
    statuses: ['active'],
  }).then((res) => res.data);
  employeesResponse.data = [...employeesWithoutCrew.data, ...employeesResponse.data];

  dispatch(setFEmployeesWithoutCrewPage(page + 1));

  return employeesResponse;
});

export const getFCrews = createAsyncThunk<
  GetCrewsResponse,
  { reset?: boolean; callback?: () => void } | undefined,
  { state: RootState }
>('filters/getFCrews', async (payload, { getState, dispatch }) => {
  if (payload?.reset) dispatch(setFCrewsPage(1));

  const { crews } = getState().filters;

  const page = crews.page ?? 1;

  if (crews.page && crews.totalPages && crews.page > crews.totalPages) {
    return Promise.reject();
  }

  const crewsResponse = await StaffService.getCrews({ page, pageSize: FILTERS_LIMIT }).then((res) => res.data);
  crewsResponse.data = [...crews.data, ...crewsResponse.data];

  dispatch(setFCrewsPage(page + 1));

  return crewsResponse;
});

export const getFEmployeesForSchedule = createAsyncThunk<
  GetEmployeesForScheduleResponse,
  { reset?: boolean; params: GetEmployeesForScheduleBody; callback?: () => void },
  { state: RootState }
>('filters/getFEmployeesForSchedule', async (payload, { getState, dispatch }) => {
  if (payload?.reset) dispatch(setFEmployeesForSchedulePage(1));

  const { employeesForSchedule } = getState().filters;

  const page = employeesForSchedule.page ?? 1;

  if (
    employeesForSchedule.page &&
    employeesForSchedule.totalPages &&
    employeesForSchedule.page > employeesForSchedule.totalPages
  ) {
    return Promise.reject();
  }

  const employeesResponse = await schedulesService
    .getEmployeesForSchedule({ ...payload?.params, page, pageSize: FILTERS_LIMIT })
    .then((res) => res.data);

  if (!payload.reset) {
    employeesResponse.data = [...employeesForSchedule.data, ...employeesResponse.data];
  }

  dispatch(setFEmployeesForSchedulePage(page + 1));

  return employeesResponse;
});

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFCompaniesPage: (state, action: PayloadAction<number>) => {
      state.companies.page = action.payload;
    },
    setFProjectsPage: (state, action: PayloadAction<number>) => {
      state.projects.page = action.payload;
    },
    setFEmployeesWithoutCrewPage: (state, action: PayloadAction<number>) => {
      state.employeesWithoutCrew.page = action.payload;
    },
    setFCrewsPage: (state, action: PayloadAction<number>) => {
      state.crews.page = action.payload;
    },
    setFEmployeesForSchedulePage: (state, action: PayloadAction<number>) => {
      state.employeesForSchedule.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFCompanies.pending, (state) => {
        state.companies.loading = true;
      })
      .addCase(getFCompanies.fulfilled, (state, action) => {
        const response = action.payload as GetCompaniesResponse;

        state.companies.data = uniqBy(response.data, 'unique');
        state.companies.totalPages = response.totalPages;
        state.companies.nextPage = response.nextPage;
        state.companies.loading = false;
      })
      .addCase(getFProjects.pending, (state) => {
        state.projects.loading = true;
      })
      .addCase(getFProjects.fulfilled, (state, action) => {
        const response = action.payload as GetProjectsResponse;

        state.projects.data = uniqBy(response.data, 'unique');
        state.projects.totalPages = response.totalPages;
        state.projects.nextPage = response.nextPage;
        state.projects.loading = false;
      })
      .addCase(getFEmployeesWithoutCrew.pending, (state) => {
        state.projects.loading = true;
      })
      .addCase(getFEmployeesWithoutCrew.fulfilled, (state, action) => {
        const response = action.payload as GetEmployeesResponse;

        state.employeesWithoutCrew.data = uniqBy(response.data, 'unique');
        state.employeesWithoutCrew.totalPages = response.totalPages;
        state.employeesWithoutCrew.nextPage = response.nextPage;
        state.employeesWithoutCrew.loading = false;
      })
      .addCase(getFCrews.pending, (state) => {
        state.crews.loading = true;
      })
      .addCase(getFCrews.fulfilled, (state, action) => {
        const response = action.payload as GetCrewsResponse;

        state.crews.data = uniqBy(response.data, 'unique');
        state.crews.totalPages = response.totalPages;
        state.crews.nextPage = response.nextPage;
        state.crews.loading = false;
      })
      .addCase(getFEmployeesForSchedule.pending, (state) => {
        state.employeesForSchedule.loading = true;
      })
      .addCase(getFEmployeesForSchedule.fulfilled, (state, action) => {
        const response = action.payload as GetEmployeesForScheduleResponse;

        // console.log(response.data);

        state.employeesForSchedule.data = response.data;
        state.employeesForSchedule.totalPages = response.totalPages;
        state.employeesForSchedule.nextPage = response.nextPage;
        state.employeesForSchedule.loading = false;
      });
  },
});

export const {
  setFCompaniesPage,
  setFEmployeesForSchedulePage,
  setFProjectsPage,
  setFCrewsPage,
  setFEmployeesWithoutCrewPage,
} = filtersSlice.actions;

export const filtersReducer = filtersSlice.reducer;
