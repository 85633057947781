import DATE_FORMAT from 'app/constants/DATE_FORMAT';
import { LicenseModel } from 'app/models/StaffModel';
import dayjs from 'dayjs';
import { StatusProps } from 'shared/ui/Status/types';

export const getLicenseColor = (i: Pick<LicenseModel, 'expirationDate'>) => {
  let color: StatusProps['color'] = 'primary';
  if (!i.expirationDate) return color;
  if (dayjs(i.expirationDate, DATE_FORMAT).isValid()) {
    const diff = dayjs(i.expirationDate, DATE_FORMAT).diff(new Date(), 'days');
    if (diff < 0) {
      color = 'danger';
    } else if (diff >= 15) {
      color = 'primary';
    } else {
      color = 'warning';
    }
  }
  return color;
};
