import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BackButton from 'shared/ui/BackButton/BackButton';
import SingleDetail from 'shared/ui/SingleDetail/SingleDetail';

import { ReactComponent as BriefcaseIcon } from 'assets/icons/primary/briefcase.svg';
import { ReactComponent as People2Icon } from 'assets/icons/primary/profile-2user.svg';

import UITabs from 'shared/ui/UITabs/UITabs';
import { getCrew } from 'store/slices/staff';
import Members from './components/Members/Members';
import './CrewDetailsPage.sass';

const CrewDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams() as { id: string };

  const { currentCrew } = useAppSelector((state) => state.staff);

  useEffect(() => {
    dispatch(getCrew(+id));
  }, [id, dispatch]);

  if (!currentCrew) return <></>;

  return (
    <div className='crew-details-page'>
      <BackButton label='View Project’s Details' />
      <div className='crew-details-page__details'>
        <SingleDetail
          title='Crew name'
          className='project-details-page__details--single-detail'
          icon={BriefcaseIcon}
          label={currentCrew.name}
        />
        <SingleDetail
          title='# of people'
          className='project-details-page__details--single-detail'
          icon={People2Icon}
          label={`${currentCrew.numberOfEmployees} people`}
        />
      </div>
      <UITabs tabs={[{ label: 'Members', tab: 'members', component: <Members /> }]} />
    </div>
  );
};

export default CrewDetailsPage;
