import { FormControl, FormLabel } from '@mui/material';
import { authService } from 'api';
import { useAppDispatch } from 'app/hooks';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormTextInput from 'shared/Inputs/FormTextInput/FormTextInput';
import UIButton from 'shared/ui/UIButton/UIButton';
import { signIn } from 'store/slices/auth';
import { getFileTypesAction, getLanguages, getWebSpecialitiesAction } from 'store/slices/utils';
import './LoginPage.sass';

interface LoginData {
  login: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const { control, handleSubmit } = useForm<LoginData>();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<LoginData> = async (data) => {
    try {
      setLoading(true);
      const { authToken, data: user } = await authService.auth(data.login, data.password).then((res) => res.data);
      if (!authToken) return Promise.reject();

      dispatch(signIn({ token: authToken, user }));
      dispatch(getWebSpecialitiesAction());
      dispatch(getFileTypesAction());
      dispatch(getLanguages());
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='login-page'>
      <div className='login-page__wrapper'>
        <div className='login-page__title'>Authorization</div>
        <form className='login-page__content' onSubmit={handleSubmit(onSubmit)}>
          <FormControl className='add-company-modal__row'>
            <FormLabel required>Login</FormLabel>
            <FormTextInput control={control} name='login' rules={{ required: 'Required field' }} />
          </FormControl>
          <FormControl className='add-company-modal__row'>
            <FormLabel required>Password</FormLabel>
            <FormTextInput control={control} name='password' type='password' rules={{ required: 'Required field' }} />
          </FormControl>
          <div className='login-page__btns'>
            <UIButton text='Sign in' type='submit' loading={loading} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
