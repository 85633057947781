import React from 'react';
import './DateDevider.sass';

interface Props {
  date: string;
}

const DateDivider: React.FC<Props> = ({ date }) => {
  return (
    <div className='date-divider'>
      <div className='date-divider__date'>{date}</div>
    </div>
  );
};

export default DateDivider;
